import CommonModal from "../../../../../component/CommonModal";
import React, {useState} from "react";
import SoldForm from "./soldForm";
import {message} from "antd";
import {doAddSaleProduct} from "./api";

export default function AddSold(props) {
    const baseFormRef = React.createRef();
    const specFormRef = React.createRef();
    const detailFormRef = React.createRef();
    const moreFormRef = React.createRef();
    const [addSubmitLoading, setAddSubmitLoading] = useState(false);

    function add() {
        if (!addSubmitLoading) {
            baseFormRef.current.validateFields().then(baseValues => {
                specFormRef.current.validateFields().then(specValues => {
                    detailFormRef.current.validateFields().then(detailValues => {
                        moreFormRef.current.validateFields().then(moreValues => {
                            let commoditySkuDTOList = [];
                            if (specValues.productNumberList !== undefined) {
                                for (let i = 0; i < specValues.productNumberList.length; i++) {
                                    let commoditySku = {};
                                    commoditySku.skuNumber = specValues.productNumberList[i];
                                    commoditySku.salePrice = specValues.salePriceMap.get(specValues.productNumberList[i]);
                                    commoditySku.procurePrice = specValues.procurePriceMap.get(specValues.productNumberList[i]);
                                    commoditySku.underlinedPrice = specValues.underlinedPriceMap.get(specValues.productNumberList[i]);
                                    commoditySkuDTOList.push(commoditySku);
                                }
                            }
                            setAddSubmitLoading(true);
                            let shopCategoryIds = [];
                            if (baseValues.shopCategoryIds !== undefined && baseValues.shopCategoryIds.length > 0) {
                                for (let i = 0; i < baseValues.shopCategoryIds.length; i++) {
                                    shopCategoryIds.push(baseValues.shopCategoryIds[i]["value"]);
                                }
                            }
                            doAddSaleProduct({
                                title: baseValues.title,
                                shopCategoryIds: shopCategoryIds !== [] ? shopCategoryIds : null,
                                freightTemplateId: baseValues.freightTemplateId !== undefined ? baseValues.freightTemplateId : null,
                                status: baseValues.status !== undefined ? baseValues.status : null,
                                orderNumber: baseValues.orderNumber !== undefined ? baseValues.orderNumber : null,
                                imagesUrl: baseValues.imagesUrl !== undefined ? baseValues.imagesUrl : null,
                                videoUrl: baseValues.videoUrl !== undefined ? baseValues.videoUrl : null,
                                productNumberList: specValues.productNumberList !== undefined ? specValues.productNumberList : null,
                                commoditySkuDTOList: commoditySkuDTOList,
                                details: detailValues.details !== undefined ? detailValues.details : null,
                                type: moreValues.type !== undefined ? moreValues.type : null,
                                oneCount: moreValues.oneCount !== undefined ? moreValues.oneCount : null,
                                sellingPoint: moreValues.sellingPoint !== undefined ? moreValues.sellingPoint : null,
                                commitments: moreValues.commitments !== undefined ? moreValues.commitments : null,
                                label: moreValues.label !== undefined ? moreValues.label : null,
                            }, (data) => {
                                setAddSubmitLoading(false);
                                props.setOpenAddModel(false);
                                props.init();
                            }, () => {
                                setAddSubmitLoading(false);
                            })
                        }).catch(e => {
                            console.log(e);
                            message.error("必填项未填！");
                            setAddSubmitLoading(false);
                        })
                    }).catch(e => {
                        console.log(e);
                        message.error("必填项未填！");
                        setAddSubmitLoading(false);
                    })
                }).catch(e => {
                    console.log(e);
                    message.error("必填项未填！")
                    setAddSubmitLoading(false);
                })
            }).catch(e => {
                console.log(e);
                message.error("必填项未填！")
                setAddSubmitLoading(false);
            })
        }
    }

    function handleCancel() {
        props.setOpenAddModel(false);
    }

    return (
        <CommonModal
            title="上架商品"
            open={props.open}
            onOk={() => add()}
            onCancel={() => handleCancel()}
            destroyOnClose={true}
            width={1050}
            confirmLoading={addSubmitLoading}
            form={<SoldForm baseFormRef={baseFormRef}
                            specFormRef={specFormRef}
                            detailFormRef={detailFormRef}
                            moreFormRef={moreFormRef}
                            categoryList={props.categoryList}/>}/>
    )
}