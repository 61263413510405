import React from "react";
import CommonModal from "../../../../../component/CommonModal";
import AddInboundLineForm from "./addInboundLineForm";

export default function InboundLine(props) {
    const addFormRef = React.createRef();


    function add() {
        addFormRef.current.validateFields().then(values => {
            props.handleAdd(values);
            props.setOpenAddModel(false);
        }).catch(e => {
            console.log(e);
        })
    }

    function handleCancel() {
        props.setOpenAddModel(false);
    }

    return (
        <CommonModal
            title="入库信息"
            open={props.open}
            onOk={() => add()}
            onCancel={() => handleCancel()}
            destroyOnClose={true}
            width={700}
            form={<AddInboundLineForm
                formRef={addFormRef}/>}/>
    )
}