import React, {useState} from "react";
import {doUpdate} from "./api";
import CommonModal from "../../../../../component/CommonModal";
import ShopCategoryForm from "./shopCategoryForm";

export default function EditShopCategory(props) {
    const editFormRef = React.createRef();
    const [parentId, setParentId] = useState(null);

    function update() {
        editFormRef.current.validateFields().then(values => {
            doUpdate({
                id: values.id,
                image: (values.imageFiles !== undefined && values.imageFiles !== null && values.imageFiles.length) > 0
                    ? values.imageFiles[0].response : null,
                parentId: parentId,
                name: values.name,
                orderNumber: values.orderNumber,
                remarks: values.remarks
            }, (data) => {
                props.setOpenEditModel(false);
                setParentId(null);
                props.init();
            })
        }).catch(e => {
            console.log(e)
        })

    }

    function handleCancel() {
        props.setOpenEditModel(false);
        setParentId(null);
    }

    return (
        <CommonModal
            title="编辑类别"
            open={props.open}
            onOk={() => update()}
            onCancel={() => handleCancel()}
            destroyOnClose={true}
            form={<ShopCategoryForm formRef={editFormRef}
                                    categoryList={props.categoryList}
                                    category={props.category}
                                    setParentId={setParentId}/>}/>
    )
}