import {Dropdown} from "antd";
import styles from "./head.module.css"
import defaultHeaderImage from "../../image/header.jpeg"
import {useState} from "react";
import {loginOut} from './api';
import {useNavigate} from "react-router-dom";
import Constant from "../../util/constant"

export default function Avatar() {
    const navigate = useNavigate();
    const [deaderImage] = useState(defaultHeaderImage);
    let IS_SYSTEM_ADMIN = localStorage.getItem(Constant.USERTYPE);
    let items;
    if (IS_SYSTEM_ADMIN !== undefined && (IS_SYSTEM_ADMIN === Constant.IS_SYSTEM_ADMIN)) {
        items = [
            {
                label: '后台设置',
                title: '后台设置',
                key: '1',
                onClick: () => navigate("/home/admin")
            },
            {
                label: '个人设置',
                title: '个人设置',
                key: '2',
                onClick: () => navigate("/home/userSetting")
            },
            {
                label: '退出登录',
                title: '退出登录',
                key: '3',
                onClick: () => loginOut(() => {
                    navigate("/login");
                }, null)
            },
        ];
    } else {
        items = [
            {
                label: '个人设置',
                title: '个人设置',
                key: '1',
                onClick: () => navigate("/home/userSetting")
            },
            {
                label: '退出登录',
                title: '退出登录',
                key: '2',
                onClick: () => loginOut(() => {
                    navigate("/login");
                }, null)
            },
        ];
    }

    return (
        <Dropdown menu={{items}} placement="bottomLeft" arrow trigger={['click']}>
            <img className={styles.head_sculpture} src={deaderImage} alt="头像"/>
        </Dropdown>
    )
}