import CommonModal from "../../../../component/CommonModal";
import React from "react";
import RoleUserInfoForm from "./roleUserInfoForm";

export default function RoleUserInfoModel(props) {
    function onOk() {
        props.setOpenUserInfoModel(false);
        props.init();
    }

    function handleCancel() {
        props.setOpenUserInfoModel(false);
    }

    return (
        <CommonModal
            title="角色成员"
            width={1000}
            open={props.open}
            onOk={() => onOk()}
            onCancel={() => handleCancel()}
            destroyOnClose={true}
            form={<RoleUserInfoForm roleInfo={props.roleInfo}/>}/>
    )
}