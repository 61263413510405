import React, {useState} from "react";
import {doAdd} from "./api";
import CommonModal from "../../../../../component/CommonModal";
import CategoryForm from "./categoryForm";

export default function AddCategory(props) {
    const addFormRef = React.createRef();
    const [addSubmitLoading, setAddSubmitLoading] = useState(false);
    const [parentId, setParentId] = useState(null);

    function add() {
        if (!addSubmitLoading) {
            addFormRef.current.validateFields().then(values => {
                setAddSubmitLoading(true)
                doAdd({
                    name: values.name,
                    parentId: parentId,
                    remarks: values.remarks
                }, (data) => {
                    setAddSubmitLoading(false);
                    props.setOpenAddModel(false);
                    setParentId(null);
                    props.init();
                }, () => {
                    setAddSubmitLoading(false);
                })
            }).catch(e => {
                console.log(e);
            })
        }
    }

    function handleCancel() {
        props.setOpenAddModel(false);
        setParentId(null);
    }

    return (
        <CommonModal
            title="新增类别"
            open={props.open}
            onOk={() => add()}
            onCancel={() => handleCancel()}
            destroyOnClose={true}
            confirmLoading={addSubmitLoading}
            form={<CategoryForm formRef={addFormRef}
                                categoryList={props.categoryList}
                                setParentId={setParentId}/>}/>
    )
}