import {ExclamationCircleOutlined} from '@ant-design/icons';
import React from "react";
import HttpUtil from "../../../../../util/httpUtil";

/**
 * 获取用户手册
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doList = function (params, successFun, errorFun) {
    HttpUtil.doGet(
        "/shop/base/manual/list",
        params,
        successFun, errorFun);
}
/**
 * 删除用户手册手册
 * @param record
 * @param successFun
 * @param errorFun
 */
export const doDel = function (record, successFun, errorFun) {
    HttpUtil.doDelete(<ExclamationCircleOutlined/>, "删除用户手册",
        "确认删除" + record.title + "信息？",
        "/shop/base/manual/delete/" + record.id, null, successFun, errorFun);
}
/**
 * 新增用户手册
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doAdd = function (params, successFun, errorFun) {
    HttpUtil.doPost("/shop/base/manual/add",
        params, successFun, errorFun);
}
/**
 * 更新用户手册
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doUpdate = function (params, successFun, errorFun) {
    HttpUtil.doPut("/shop/base/manual/update",
        params, successFun, errorFun);
}
