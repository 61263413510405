import styles from './login.module.css';
import {useState} from 'react';
import {Button, Form, Input} from 'antd';
import {UserOutlined, LockOutlined} from '@ant-design/icons';
import Constant from "../../util/constant";
import {doLogin} from './api';
import {useNavigate} from "react-router-dom";

export default function Login() {
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);
    const navigate = useNavigate();

    function onSubmit() {
        doLogin(username, password, (data) => {
            localStorage.setItem(Constant.USERNAME, data.resData.USERNAME)
            localStorage.setItem(Constant.USERTYPE, data.resData.USERTYPE)
            localStorage.setItem(Constant.AUTHORIZATION, data.resData.AUTHORIZATION)
            navigate("/home/data");
        })
    }

    return (
        <div>
            <div className={styles.header}></div>
            <div className={styles.content}>
                <Form onFinish={() => onSubmit()}>
                    <Form.Item
                        rules={[
                            {required: true, message: '请输入账户!'},
                        ]}
                        name="usernameItem">
                        <Input
                            size="large"
                            prefix={<UserOutlined className="site-form-item-icon"/>}
                            placeholder="Username"
                            name="username"
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {required: true, message: '请输入密码!'},
                        ]}
                        name="passwordItem">
                        <Input.Password
                            size="large"
                            type="password"
                            name="password"
                            prefix={<LockOutlined className="site-form-item-icon"/>}
                            placeholder="Password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            size="large"
                            type="primary"
                            htmlType="submit"
                            className={styles['login-button']}>
                            登陆
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}