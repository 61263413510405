import React, {useState} from "react";
import {doAdd} from "./api";
import CommonModal from "../../../../../component/CommonModal";
import AgreementForm from "./agreementForm";

export default function AddAgreement(props) {
    const addFormRef = React.createRef();
    const [addSubmitLoading, setAddSubmitLoading] = useState(false);


    function add() {
        if (!addSubmitLoading) {
            if (!addSubmitLoading) {
                addFormRef.current.validateFields().then(values => {
                    setAddSubmitLoading(true)
                    doAdd({
                        title: values.title,
                        type: values.type,
                        content: values.content,
                        remarks: values.remarks
                    }, (data) => {
                        setAddSubmitLoading(false);
                        props.setOpenAddModel(false);
                        props.init();
                    }, () => {
                        setAddSubmitLoading(false);
                    })
                }).catch(e => {
                    console.log(e);
                })
            }
        }
    }

    function handleCancel() {
        props.setOpenAddModel(false);
    }

    return (
        <CommonModal
            title="新增协议"
            width={1000}
            open={props.open}
            onOk={() => add()}
            onCancel={() => handleCancel()}
            destroyOnClose={true}
            confirmLoading={addSubmitLoading}
            form={<AgreementForm formRef={addFormRef}/>}/>
    )
}