import React from "react";
import {Form, Input, InputNumber} from "antd";

const {TextArea} = Input;
export default function BalanceForm(props) {
    const layout = {
        labelCol: {span: 4},
        wrapperCol: {span: 20},
    };
    return (
        <div>
            <Form
                ref={props.formRef} {...layout}>
                <Form.Item
                    label="用户ID"
                    name="userId"
                    hidden="true">
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="信用额度"
                    name="balance"
                    rules={[{required: true, message: '请输入信用额度'}]}>
                    <InputNumber min="0.00" step="0.01" style={{"width": "350px"}}/>
                </Form.Item>
                <Form.Item
                    label="用户欠款"
                    name="arrears"
                    rules={[{required: true, message: '请输入用户欠款'}]}>
                    <InputNumber min="0.00" step="0.01" style={{"width": "350px"}} readOnly={true}
                                 disabled={true}/>
                </Form.Item>
                <Form.Item
                    label="用户还款"
                    name="repayment">
                    <InputNumber min="0.00" step="0.01" style={{"width": "350px"}}/>
                </Form.Item>
                <Form.Item
                    label="还款备注"
                    name="remarks">
                    <TextArea style={{"width": "350px"}}/>
                </Form.Item>
            </Form>
        </div>
    )
}