import React from "react";
import CommonModal from "../../../../component/CommonModal";
import EditUserInfoForm from "./editUserInfoForm";
import {updateUserInfo} from "../../../head/api";

export default function EditUserInfo(props){
    const editFormRef = React.createRef();
    if (props.userInfo != null && props.open) {
        setTimeout(() => {
            editFormRef.current.setFieldsValue(props.userInfo);
        }, 100);
    }
    function update() {
        editFormRef.current.validateFields().then(values => {
            updateUserInfo({
                id: values.id,
                realName: values.realName
            }, (data) => {
                clearInterval(editFormRef.current);
                props.setOpenEditModel(false);
                props.init();
            })
        }).catch(e => {
            console.log(e)
        })

    }
    function handleCancel() {
        props.setOpenEditModel(false);
    }

    return (
        <CommonModal
            title="编辑用户信息"
            open={props.open}
            onOk={() => update()}
            onCancel={() => handleCancel()}
            destroyOnClose={true}
            form={<EditUserInfoForm formRef={editFormRef}/>}/>
    )
}