import React from "react";
import CommonModal from "../../../../../component/CommonModal";
import OutboundInfoForm from "./outboundInfoForm";

export default function ViewOutboundInfo(props) {
    const viewFormRef = React.createRef();
    if (props.outboundInfo != null && props.open) {
        setTimeout(() => {
            viewFormRef.current.setFieldsValue(props.outboundInfo);
        }, 100);
    }

    function handleCancel() {
        props.setOpenViewModel(false);
    }

    return (
        <CommonModal
            title="出库信息"
            open={props.open}
            onOk={() => handleCancel()}
            onCancel={() => handleCancel()}
            destroyOnClose={true}
            width={800}
            form={<OutboundInfoForm formRef={viewFormRef}
                                   view={true}/>}/>
    )
}