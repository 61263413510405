import React, {useEffect, useState} from "react";
import Constant from "../../../../../util/constant";
import {Button, Form, Image, Space} from "antd";
import {doList, disable, doDel, enable, doTreeList} from "./api";
import CommonTable from "../../../../../component/CommonTable";
import AddShopCategory from "./addShopCategory";
import EditShopCategory from "./editShopCategory";
import Util from "../../../../../util/params";

export default function ShopCategory() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(Constant.CURRENT_PAGE);
    const [pageSize, setPageSize] = useState(Constant.PAGE_SIZE);

    const [categoryList, setCategoryList] = useState(null);
    const [category, setCategory] = useState(null);
    const [openAddModel, setOpenAddModel] = useState(false);
    const [openEditModel, setOpenEditModel] = useState(false);
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 50,
        },
        {
            title: 'ParentId',
            dataIndex: 'parentId',
            key: 'parentId',
            width: 50,
        },
        {
            title: '父类目',
            dataIndex: 'parentName',
            key: 'parentName',
        },
        {
            title: '编码',
            dataIndex: 'number',
            key: 'number',
        },
        {
            title: '类目',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '类目图片',
            dataIndex: 'image',
            key: 'image',
            render: image => {
                if (!Util.isEmpty(image)) {
                    return (
                        <Image
                            width={25}
                            height={20}
                            src={image}/>
                    )
                }
            }
        },
        {
            title: '序号',
            dataIndex: 'orderNumber',
            key: 'orderNumber',
        },
        {
            title: '状态',
            dataIndex: 'statusText',
            key: 'statusText',
        },
        {
            title: '描述',
            dataIndex: 'remarks',
            key: 'remarks',
        },
        {
            title: '创建时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: 170,
        },
        {
            title: '创建人',
            dataIndex: 'creator',
            key: 'creator',
        },
        {
            title: '最近修改时间',
            dataIndex: 'gmtUpdate',
            key: 'gmtUpdate',
            width: 170,
        },
        {
            title: '最近修改人',
            dataIndex: 'modifier',
            key: 'modifier',
        },
        {
            title: '操作',
            key: 'action',
            fixed: 'right',
            width: 120,
            render: (text, record) => (
                <Space size="small">
                    <a onClick={() => edit(record)}>编辑</a>
                    <a onClick={() => {
                        if (record.status === 0) {
                            disable(record, () => init())
                        } else {
                            enable(record, () => init())
                        }
                    }} style={{
                        'color': '#eea236'
                    }}>{record.status === 0 ? "禁用" : "启用"}</a>
                    <a style={{'color': '#d43f3a'}} onClick={() => doDel(record, () => {
                        init()
                    })}>删除</a>
                </Space>
            ),
        },
    ];
    columns.splice(0, 3)
    useEffect(() => {
        init();
    }, []);// eslint-disable-line
    function init() {
        doList({}, (data) => {
            setData(data.resData);
            setLoading(false);
            setTotal(data.resData);
        }, () => {
            setData(null);
            setLoading(false);
        })
    }

    function add() {
        initCategory();
        setOpenAddModel(true);
    }

    function edit(record) {
        initCategory();
        setCategory(record);
        setTimeout(() => {
            setOpenEditModel(true);
        }, 100);
    }

    function initCategory() {
        doTreeList({status: 0}, (data) => {
            setCategoryList(data.resData);
        }, () => {
            setCategoryList(null);
        });
    }

    function resetPage(currentPage, pageSize) {
        setCurrentPage(currentPage);
        setPageSize(pageSize);
    }


    return (
        <div>
            <Form layout='inline'>
                <Form.Item>
                    <Button type="primary" onClick={() => add()}>新增类目</Button>
                </Form.Item>
            </Form>
            <CommonTable
                columns={columns}
                rowKey={columns => columns.id}
                data={data}
                width={1700}
                loading={loading}
                total={total}
                closePagination={true}
                currentPage={currentPage}
                pageSize={pageSize}
                initData={(currentPage, setCurrentPage) => init(currentPage, setCurrentPage)}
                resetPage={(currentPage, pageSize) => resetPage(currentPage, pageSize)}/>
            <AddShopCategory open={openAddModel}
                             setOpenAddModel={setOpenAddModel}
                             categoryList={categoryList}
                             init={init}/>
            <EditShopCategory open={openEditModel}
                          setOpenEditModel={setOpenEditModel}
                          categoryList={categoryList}
                          category={category}
                          init={init}/>
        </div>
    )
}