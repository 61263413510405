import React, {useState} from "react";
import {doAdd} from "./api";
import CommonModal from "../../../../../component/CommonModal";
import FreightForm from "./freightForm";
import {message} from "antd";

export default function AddFreight(props) {
    const addFormRef = React.createRef();
    const [addSubmitLoading, setAddSubmitLoading] = useState(false);
    const [type, setType] = useState(2);
    const [regions, setRegions] = useState([])
    const [first, setFirst] = useState(1);
    const [firstFee, setFirstFee] = useState(0.00);
    const [additional, setAdditional] = useState(1);
    const [additionalFee, setAdditionalFee] = useState(0.00);


    function add() {
        if (!addSubmitLoading) {
            if(type!==2){
                if (regions === null || regions.length === 0) {
                    message.error("请选择配送区域")
                } else {
                    let billingRule = {};
                    billingRule.first = first;
                    billingRule.firstFee = firstFee;
                    billingRule.additional = additional;
                    billingRule.additionalFee = additionalFee;
                    billingRule.regions = regions;
                    addFormRef.current.validateFields().then(values => {
                        setAddSubmitLoading(true)
                        doAdd({
                            name: values.name,
                            type: values.type,
                            billingRule: billingRule,
                            remarks: values.remarks
                        }, (data) => {
                            setAddSubmitLoading(false);
                            clearInterval(addFormRef.current);
                            props.setOpenAddModel(false);
                            props.init();
                        }, () => {
                            setAddSubmitLoading(false);
                        })
                    }).catch(e => {
                        console.log(e);
                    })
                }
            }else{
                let billingRule = {};
                billingRule.first = 0;
                billingRule.firstFee = 0;
                billingRule.additional = 0;
                billingRule.additionalFee = 0;
                billingRule.regions = null;
                addFormRef.current.validateFields().then(values => {
                    setAddSubmitLoading(true)
                    doAdd({
                        name: values.name,
                        type: values.type,
                        billingRule: billingRule,
                        remarks: values.remarks
                    }, (data) => {
                        setAddSubmitLoading(false);
                        clearInterval(addFormRef.current);
                        props.setOpenAddModel(false);
                        props.init();
                    }, () => {
                        setAddSubmitLoading(false);
                    })
                }).catch(e => {
                    console.log(e);
                })
            }
        }
    }

    function handleCancel() {
        props.setOpenAddModel(false);
        setType(2)
        setRegions([])
        setFirst(1)
        setFirstFee(0.00);
        setAdditional(1);
        setAdditionalFee(0.00);
    }

    return (
        <CommonModal
            title="新增模版信息"
            width={800}
            open={props.open}
            onOk={() => add()}
            onCancel={() => handleCancel()}
            destroyOnClose={true}
            confirmLoading={addSubmitLoading}
            form={<FreightForm formRef={addFormRef}
                               first={first}
                               setFirst={setFirst}
                               firstFee={firstFee}
                               setFirstFee={setFirstFee}
                               additional={additional}
                               setAdditional={setAdditional}
                               additionalFee={additionalFee}
                               setAdditionalFee={setAdditionalFee}
                               setRegions={setRegions}
                               freight={props.freight}
                               type={type}
                               setType={setType}
            />}/>
    )
}