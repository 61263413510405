import React, {useState} from "react";
import {doAdd} from "./api";
import VideoForm from "./videoForm";
import CommonModal from "../../../../component/CommonModal";

export default function AddVideo(props) {
    const addFormRef = React.createRef();
    const [addSubmitLoading, setAddSubmitLoading] = useState(false);
    const [timeStart, setTimeStart] = useState(null);
    const [timeEnd, setTimeEnd] = useState(null);

    function add() {
        if (!addSubmitLoading) {
            addFormRef.current.validateFields().then(values => {
                setAddSubmitLoading(true)
                doAdd({
                    title: values.title,
                    videoUrl: values.videoUrl,
                    type: values.type,
                    time: timeStart + "~" + timeEnd,
                    remarks: values.remarks
                }, (data) => {
                    setAddSubmitLoading(false);
                    clearInterval(addFormRef.current);
                    props.setOpenAddModel(false);
                    props.init();
                }, () => {
                    setAddSubmitLoading(false);
                })
            }).catch(e => {
                console.log(e);
            })
        }
    }

    function handleCancel() {
        props.setOpenAddModel(false);
    }

    return (
        <CommonModal
            title="新增视频"
            open={props.open}
            onOk={() => add()}
            onCancel={() => handleCancel()}
            destroyOnClose={true}
            confirmLoading={addSubmitLoading}
            form={<VideoForm formRef={addFormRef}
                             typeList={props.typeList}
                             setTimeStart={setTimeStart}
                             setTimeEnd={setTimeEnd}/>}/>
    )
}