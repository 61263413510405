import React from "react";
import type {TabsProps} from 'antd';
import {Tabs} from "antd";
import Barcode from "./barcode/barcode";
import BarcodeTemplate from "./template/barcodeTemplate";

export default function BarcodeTab() {
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: '商品条形码',
            children: <Barcode/>,
        },
        {
            key: '2',
            label: '模版设置',
            children: <BarcodeTemplate/>,
        }
    ];

    return (
        <Tabs defaultActiveKey="1" items={items}/>
    )
}