import HttpUtil from "../../../../util/httpUtil";

/**
 * 获取用户收货地址
 * @param params
 * @param successFun
 * @param errorFun
 */
export const listHarvestAddress = function (params, successFun, errorFun) {
    HttpUtil.doGet("/user/harvestAddress/list",
        params, successFun, errorFun);
}
export const doExportArrears = function (params, successFun, errorFun) {
    HttpUtil.doExport("/user/exportArrears",
        params, "欠款信息", successFun, errorFun);
}
export const doExportRepayment = function (params, successFun, errorFun) {
    HttpUtil.doExport("/user/exportRepayment/" + params.userId,
        params, "还款记录", successFun, errorFun);
}