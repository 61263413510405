import React, {useEffect, useState} from "react";
import {doLogisticsCompanyList} from "./api";
import {Tooltip, Form, Input, Select} from "antd";
import {QuestionCircleOutlined} from "@ant-design/icons";

const {TextArea} = Input;
export default function EditLogisticsForm(props) {
    const [logisticsCompanyList, setLogisticsCompanyList] = useState(null);
    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };
    useEffect(() => {
        initLogisticsCompany();
    }, []);// eslint-disable-line
    function initLogisticsCompany() {
        doLogisticsCompanyList({
            status: 0,
        }, (data) => {
            setLogisticsCompanyList(data.resData.result);
        }, () => {
            setLogisticsCompanyList(null);
        })
    }

    return (
        <div>
            <Form
                ref={props.formRef} {...layout}>
                <Form.Item
                    label="订单ID"
                    name="orderId"
                    hidden="true">
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="快递公司"
                    name="logisticsCompanyId"
                    rules={[{required: true, message: '请选择快递公司'}]}>
                    <Select placeholder="请选择快递公司"
                            optionFilterProp="label"
                            showSearch
                            allowClear>
                        {
                            logisticsCompanyList != null ?
                                logisticsCompanyList.map(item => {
                                    return (<Select.Option key={item.id}
                                                           value={item.id}
                                                           label={item.name}>{item.name}</Select.Option>)
                                }) : null
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    label="快递编号"
                    name="logisticsNumber"
                    rules={[{required: true, message: '请输入快递编号'}]}>
                    <Input placeholder="请输入快递编号，多个包裹编号按英文逗号分割" addonAfter={
                        <Tooltip title="多个包裹快递编号按英文逗号(,)分割">
                            <QuestionCircleOutlined/>
                        </Tooltip>
                    }/>
                </Form.Item>
                <Form.Item
                    label="备注"
                    name="remarks">
                    <TextArea/>
                </Form.Item>
            </Form>
        </div>
    )
}