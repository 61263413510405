import {Col, Form, Input, Row} from "antd";
import React from "react";

const {TextArea} = Input;

export default function InboundInfoForm(props) {
    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };
    return (
        <div>
            <Form
                ref={props.formRef} {...layout}>
                <Row gutter={[16, 0]}>
                    <Col span={12}>
                        <Form.Item
                            label="编码"
                            name="number">
                            <Input disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="商品"
                            name="productName">
                            <Input disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 0]}>
                    <Col span={12}>
                        <Form.Item
                            label="颜色"
                            name="productColor">
                            <Input disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="大小"
                            name="productSize">
                            <Input disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 0]}>
                    <Col span={12}>
                        <Form.Item
                            label="规格"
                            name="productSpec">
                            <Input disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="SKU码"
                            name="productNumber">
                            <Input
                                disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 0]}>
                    <Col span={12}>
                        <Form.Item
                            label="仓库"
                            name="warehouseName">
                            <Input disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="仓位"
                            name="positionName">
                            <Input disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 0]}>
                    <Col span={12}>
                        <Form.Item
                            label="入库数量"
                            name="quantity">
                            <Input disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="单据时间"
                            name="gmtCreate">
                            <Input disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 0]}>
                    <Col span={12}>
                        <Form.Item
                            label="操作人"
                            name="creator">
                            <Input disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="描述"
                            name="remarks">
                            <TextArea
                                disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}