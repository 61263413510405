import HttpUtil from "../../../../util/httpUtil";
import {ExclamationCircleOutlined} from '@ant-design/icons';

/**
 * 获取用户列表
 * @param params
 * @param successFun
 * @param errorFun
 */
export const listUserInfo = function (params, successFun, errorFun) {
    HttpUtil.doGet(
        "/user/list",
        params,
        successFun, errorFun);
}
/**
 * 获取客户信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const listCustomerInfo = function (params, successFun, errorFun) {
    HttpUtil.doGet(
        "/user/listCustomer",
        params,
        successFun, errorFun);
}
/**
 * 获取用户类型
 * @param successFun
 * @param errorFun
 */
export const listUserType = function (successFun, errorFun) {
    HttpUtil.doGet(
        "/user/listUserType",
        null,
        successFun, errorFun);
}
/**
 * 获取用户状态
 * @param successFun
 * @param errorFun
 */
export const listUserStatus = function (successFun, errorFun) {
    HttpUtil.doGet(
        "/user/listUserStatus",
        null,
        successFun, errorFun);
}
/**
 * 新增账户信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const addUserInfo = function (params, successFun, errorFun) {
    HttpUtil.doPost("/user/add",
        params, successFun, errorFun);
}
/**
 * 修改账户信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const updateUserInfo = function (params, successFun, errorFun) {
    HttpUtil.doPut("/user/update",
        params, successFun, errorFun);
}
/**
 * 重置密码
 * @param record
 * @param successFun
 * @param errorFun
 */
export const resetPassword = function (record, successFun, errorFun) {
    HttpUtil.doPutConfirm(<ExclamationCircleOutlined/>, "重置密码",
        "确认重置" + record.username + "密码？默认密码neo@123456",
        "/user/resetPassword/" + record.id, null, successFun, errorFun);
}
/**
 * 禁用用户
 * @param record
 * @param successFun
 * @param errorFun
 */
export const disable = function (record, successFun, errorFun) {
    HttpUtil.doPutConfirm(<ExclamationCircleOutlined/>, "禁用用户", "确认禁用" + record.username + "用户？",
        "/user/disable/" + record.id, null, successFun, errorFun);
}
/**
 * 启用用户
 * @param record
 * @param successFun
 * @param errorFun
 */
export const enable = function (record, successFun, errorFun) {
    HttpUtil.doPutConfirm(<ExclamationCircleOutlined/>, "启用用户", "确认启用" + record.username + "用户？",
        "/user/enable/" + record.id, null, successFun, errorFun);
}
/**
 * 湖州地区可访问
 * @param record
 * @param successFun
 * @param errorFun
 */
export const addressable = function (record, successFun, errorFun) {
    HttpUtil.doPutConfirm(<ExclamationCircleOutlined/>, "设置访问", "确认设置" + record.username + "湖州地区可访问？",
        "/user/addressable/" + record.id, null, successFun, errorFun);
}
/**
 * 湖州地区不可访问
 * @param record
 * @param successFun
 * @param errorFun
 */
export const notAddressable = function (record, successFun, errorFun) {
    HttpUtil.doPutConfirm(<ExclamationCircleOutlined/>, "设置访问", "确认设置" + record.username + "湖州地区不可访问？",
        "/user/notAddressable/" + record.id, null, successFun, errorFun);
}