import React, {useState} from 'react';
import {Button, Steps, theme} from 'antd';
import BaseForm from "./baseForm";
import SpecForm from "./specForm";
import DetailForm from "./detailsForm";
import MoreForm from "./moreFrom";

export default function SoldForm(props) {
    const steps = [
        {
            step: 1,
            key: 1,
            title: '基本信息',
            content: <BaseForm formRef={props.baseFormRef}
                               categoryList={props.categoryList}
                               shopCategoryIds={props.shopCategoryIds}
                               view={props.view}/>,
        },
        {
            step: 2,
            key: 2,
            title: '规格/库存',
            content: <SpecForm formRef={props.specFormRef}
                               view={props.view}
                               skuInfo={props.skuInfo}/>,
        },
        {
            step: 3,
            key: 3,
            title: '商品详情',
            content: <DetailForm formRef={props.detailFormRef}
                                 view={props.view}
                                 details={props.details}/>,
        },
        {
            step: 4,
            key: 4,
            title: '更多设置',
            content: <MoreForm formRef={props.moreFormRef}
                               view={props.view}/>,
        },
    ];
    const {token} = theme.useToken();
    const [current, setCurrent] = useState(0);

    const next = () => {
        if (current === 0) {
            props.baseFormRef.current.validateFields().then(values => {
                setCurrent(current + 1);
            }).catch(e => {
                console.log(e);
            })
        } else if (current === 1) {
            props.specFormRef.current.validateFields().then(values => {
                setCurrent(current + 1);
            }).catch(e => {
                console.log(e);
            })
        } else if (current === 2) {
            props.detailFormRef.current.validateFields().then(values => {
                setCurrent(current + 1);
            }).catch(e => {
                console.log(e);
            })
        }
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const items = steps.map((item) => ({
            key: item.key,
            title: item.title
        })
    );
    return (
        <div>
            <Steps current={current} items={items} onChange={(value) => {
                setCurrent(value);
            }}/>
            {
                steps.map((item) => (
                    <div key={item.key}
                         style={{
                             minHeight: '300px',
                             padding: '10px',
                             color: token.colorTextTertiary,
                             borderRadius: token.borderRadiusSM,
                             border: `1px dashed ${token.colorBorder}`,
                             marginTop: 16,
                             display: item.step !== current + 1 ? "none" : ""
                         }}>{item.content}
                    </div>
                ))
            }
            <div style={{marginTop: 24}}>
                {current < steps.length - 1 && (
                    <Button type="primary" onClick={() => next()}>
                        下一步
                    </Button>
                )}
                {current > 0 && (
                    <Button style={{margin: '0 8px'}} onClick={() => prev()}>
                        上一步
                    </Button>
                )}
            </div>
        </div>
    )
}