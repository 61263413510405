import HttpUtil from "../../../../util/httpUtil";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import React from "react";

/**
 * 获取订单信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doList = function (params, successFun, errorFun) {
    HttpUtil.doGet(
        "/order/list",
        params,
        successFun, errorFun);
}
/**
 * 获取订单状态
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doListStatus = function (successFun, errorFun) {
    HttpUtil.doGet(
        "/order/listStatus",
        null,
        successFun, errorFun);
}
/**
 * 获取订单类型
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doListType = function (successFun, errorFun) {
    HttpUtil.doGet(
        "/order/listType",
        null,
        successFun, errorFun);
}
/**
 * 获取订单支付方式
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doListWay = function (successFun, errorFun) {
    HttpUtil.doGet(
        "/order/listWay",
        null,
        successFun, errorFun);
}
/**
 * 更新订单信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doUpdate = function (params, successFun, errorFun) {
    HttpUtil.doPut("/order/update",
        params, successFun, errorFun);
}
/**
 * 删除订单信息
 * @param record
 * @param successFun
 * @param errorFun
 */
export const doDel = function (record, successFun, errorFun) {
    HttpUtil.doDelete(<ExclamationCircleOutlined/>, "删除订单",
        "确认删除" + record.orderNumber + "订单信息？",
        "/order/delete/" + record.id, null, successFun, errorFun);
}
/**
 * 取消订单信息
 * @param record
 * @param successFun
 * @param errorFun
 */
export const doCancel = function (record, successFun, errorFun) {
    HttpUtil.doPutConfirm(<ExclamationCircleOutlined/>, "取消订单",
        "确认取消" + record.orderNumber + "订单信息？",
        "/order/cancel/" + record.id, null, successFun, errorFun);
}
/**
 * 获取快递公司信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doLogisticsCompanyList = function (params, successFun, errorFun) {
    HttpUtil.doGet(
        "/shop/logistics/logisticsCompany/list",
        params,
        successFun, errorFun);
}
// /**
//  * 快递发货
//  * @param params
//  * @param successFun
//  * @param errorFun
//  */
// export const doSend = function (params, successFun, errorFun) {
//     HttpUtil.doPost("/order/send",
//         params, successFun, errorFun);
// }
/**
 * 快递发货
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doDelivery = function (params, successFun, errorFun) {
    HttpUtil.doPost("/order/delivery",
        params, successFun, errorFun);
}
/**
 * 修改物流信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doUpdateSend = function (params, successFun, errorFun) {
    HttpUtil.doPut("/order/updateSend",
        params, successFun, errorFun);
}
/**
 * 确认退款
 * @param record
 * @param successFun
 * @param errorFun
 */
export const doRefund = function (record, successFun, errorFun) {
    HttpUtil.doPutConfirm(<ExclamationCircleOutlined/>, "确认退款",
        "确认退款" + record.orderNumber + "订单信息？",
        "/order/refund/" + record.id, null, successFun, errorFun);
}
/**
 * 确认退款
 * @param record
 * @param successFun
 * @param errorFun
 */
export const doRefund2 = function (params, successFun, errorFun) {
    HttpUtil.doPut("/order/refund/" + params.id + "/" + params.refundAmount, null, successFun, errorFun);
}
/**
 * 确认支付
 * @param record
 * @param successFun
 * @param errorFun
 */
export const doCompletePay = function (record, successFun, errorFun) {
    HttpUtil.doPutConfirm(<ExclamationCircleOutlined/>, "确认支付",
        "确认" + record.orderNumber + "订单支付信息？",
        "/order/completePay/" + record.id, null, successFun, errorFun);
}
/**
 * 打印商品条形码信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doPrint = function (params, successFun, errorFun) {
    HttpUtil.doPost("/order/barcode/print",
        params, successFun, errorFun);
}
/**
 * 打印快递面单信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doPrintLabel = function (params, successFun, errorFun) {
    HttpUtil.doPost("/order/barcode/printLabel",
        params, successFun, errorFun);
}
/**
 * 导出待进货信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doExportPurchasingInfo = function (params, successFun, errorFun) {
    HttpUtil.doExport("/order/exportPurchasingInfo",
        params, "待进货商品", successFun, errorFun);
}
/**
 * 驳回订单
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doReject = function (params, successFun, errorFun) {
    HttpUtil.doPut("/order/reject",
        params, successFun, errorFun);
}
/**
 * 获取经营数据
 * @param successFun
 * @param errorFun
 */
export const getBusinessData = function (successFun, errorFun) {
    HttpUtil.doGet(
        "/order/getBusinessData",
        null,
        successFun, errorFun);
}
/**
 * 获取今日订单
 * @param successFun
 * @param errorFun
 */
export const getTodayOrderInfo = function (successFun, errorFun) {
    HttpUtil.doGet(
        "/order/getTodayOrderInfo",
        null,
        successFun, errorFun);
}
/**
 * 获取月份订单
 * @param successFun
 * @param errorFun
 */
export const getMonthOrderInfo = function (params, successFun, errorFun) {
    HttpUtil.doGet(
        "/order/getMonthOrderInfo",
        params,
        successFun, errorFun);
}
/**
 * 获取日订单
 * @param successFun
 * @param errorFun
 */
export const getDayOrderInfo = function (params, successFun, errorFun) {
    HttpUtil.doGet(
        "/order/getDayOrderInfo",
        params,
        successFun, errorFun);
}
/**
 * 导出订单信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doExportOrderInfo = function (params, successFun, errorFun) {
    HttpUtil.doExport("/order/exportOrderInfo",
        params, "订单信息", successFun, errorFun);
}



