import {Form, Input, InputNumber} from "antd";
import React from "react";
const {TextArea} = Input;

export  default function EditOrderForm(props){
    const layout = {
        labelCol: {span: 4},
        wrapperCol: {span: 20},
    };
    return(
        <div>
            <Form
                ref={props.formRef} {...layout}>
                <Form.Item
                    label="订单ID"
                    name="id"
                    hidden="true">
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="订单总额"
                    name="payAmount"
                    rules={[{required: true, message: '请输入订单总额'}]}>
                    <InputNumber min={0.00} step={0.01} style={{
                        "width":"395px"
                    }}/>
                </Form.Item>
                <Form.Item
                    label="描述"
                    name="remarks">
                    <TextArea/>
                </Form.Item>
            </Form>
        </div>
    )
}