import HttpUtil from "../../../../util/httpUtil";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import React from "react";

/**
 * 获取用户余额申请信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const list = function (params, successFun, errorFun) {
    HttpUtil.doGet("/user/listBalanceApply",
        params, successFun, errorFun);
}
/**
 * 余额申请信息确认
 * @param record
 * @param successFun
 * @param errorFun
 */
export const balanceApplyCheck = function (record, successFun, errorFun) {
    HttpUtil.doPutConfirm(<ExclamationCircleOutlined/>, "余额申请信息确认", "信息确认无误？",
        "/user/balanceApplyCheck/" + record.id, null, successFun, errorFun);
}
/**
 * 更新用户余额
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doUpdateUserBalance = function (params, successFun, errorFun) {
    HttpUtil.doPut("/user/setUserBalance",
        params, successFun, errorFun);
}