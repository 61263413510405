import React, {useEffect, useState} from "react";
import Constant from "../../../../util/constant";
import {Button, Col, Form, Input, Row, Select, Space, Tooltip} from "antd";
import {doList, doListType, doListWay} from "../all/api";
import CommonTable from "../../../../component/CommonTable";
import OrderProductInfo from "../all/orderProductInfo";
import Refund from "../delivery/refund";

export default function AfterSalesOrderInfo() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(Constant.CURRENT_PAGE);
    const [pageSize, setPageSize] = useState(Constant.PAGE_SIZE);

    const [title, setTitle] = useState(null);
    const [orderNumber, setOrderNumber] = useState(null);
    const [username, setUsername] = useState(null);
    const [realName, setRealName] = useState(null);
    const [tel, setTel] = useState(null);
    const [type, setType] = useState(null);
    const [typeList, setTypeList] = useState(null);
    const [way, setWay] = useState(null);
    const [wayList, setWayList] = useState(null);
    const [openOrderProductModel, setOpenOrderProductModel] = useState(false);
    const [orderProductInfo, setOrderProductInfo] = useState(null);
    const [openRefundModel, setOpenRefundModel] = useState(false);
    const [orderInfo, setOrderInfo] = useState(null);
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 50,
        },
        {
            title: '快递公司ID',
            dataIndex: 'logisticsCompanyId',
            key: 'logisticsCompanyId',
            width: 50,
        },
        {
            title: '订单编号',
            dataIndex: 'orderNumber',
            key: 'orderNumber',
            width: 150,
        },
        {
            title: '订单信息',
            dataIndex: 'orderInfo',
            key: 'orderInfo',
            width: 300,
            ellipsis: true,
            render: (text, record) => {
                return (
                    <Tooltip placement="topLeft"
                             title={"编号：" + record.orderNumber + "，标题：" + record.title + "，信息：" + record.orderCommodityInfo}>
                        <span>{"编号：" + record.orderNumber + "，标题：" + record.title + "，信息：" + record.orderCommodityInfo}</span>
                    </Tooltip>
                )
            }
        },
        {
            title: '收货信息',
            dataIndex: 'receivingInformation',
            key: 'receivingInformation',
            width: 200,
            ellipsis: true,
            render: receivingInformation => {
                return (
                    <Tooltip placement="topLeft" title={receivingInformation}>
                        <span>{receivingInformation}</span>
                    </Tooltip>
                )
            }
        },
        {
            title: '订单状态',
            dataIndex: 'statusText',
            key: 'statusText',
        },
        {
            title: '订单总额',
            dataIndex: 'payAmount',
            key: 'payAmount',
        },
        {
            title: '快递公司',
            dataIndex: 'logisticsCompany',
            key: 'logisticsCompany',
        },
        {
            title: '快递编号',
            dataIndex: 'logisticsNumber',
            key: 'logisticsNumber',
            width: 200,
            ellipsis: true,
        },
        {
            title: '快递备注',
            dataIndex: 'logisticsRemarks',
            key: 'logisticsRemarks',
            width: 200,
            ellipsis: true,
        },
        {
            title: '订单类型',
            dataIndex: 'typeText',
            key: 'typeText',
        },
        {
            title: '支付方式',
            dataIndex: 'wayText',
            key: 'wayText',
        },
        {
            title: '备注',
            dataIndex: 'remarks',
            key: 'remarks',
        },
        {
            title: '创建时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: 170,
        },
        {
            title: '支付时间',
            dataIndex: 'gmtPay',
            key: 'gmtPay',
            width: 170,
        },
        {
            title: '发货时间',
            dataIndex: 'gmtDelivery',
            key: 'gmtDelivery',
            width: 170,
        },
        {
            title: '成交时间',
            dataIndex: 'gmtComplete',
            key: 'gmtComplete',
            width: 170,
        },
        {
            title: '取消时间',
            dataIndex: 'gmtCancel',
            key: 'gmtCancel',
            width: 170,
        },
        {
            title: '退货时间',
            dataIndex: 'gmtBack',
            key: 'gmtBack',
            width: 170,
        },
        {
            title: '退款时间',
            dataIndex: 'gmtRefund',
            key: 'gmtRefund',
            width: 170,
        },
        {
            title: '操作',
            key: 'action',
            fixed: 'right',
            width: 140,
            render: (text, record) => (
                <Space size="small" style={{"float": "right"}}>
                    <a onClick={() => orderProduct(record)}>商品详情</a>
                    <a onClick={() => refund(record)}>确认退款</a>
                </Space>
            ),
        },
    ];
    columns.splice(0, 2)
    useEffect(() => {
        init(currentPage, pageSize);
        initType();
        initWay();
    }, []);// eslint-disable-line
    function init(current, page) {
        doList({
            title: title,
            orderNumber: orderNumber,
            username: username,
            realName: realName,
            tel: tel,
            type: type,
            way: way,
            statusList: [5, 9].join(','),
            refund: "未退款",
            currentPage: current === undefined ? currentPage : current,
            pageSize: page === undefined ? pageSize : page
        }, (data) => {
            setData(data.resData.result);
            setLoading(false);
            setTotal(data.resData.total);
        }, () => {
            setData(null);
            setLoading(false);
        })
    }

    function initType() {
        doListType((data) => {
            setTypeList(data.resData);
        }, () => {
            setTypeList(null);
        })
    }

    function initWay() {
        doListWay((data) => {
            setWayList(data.resData);
        }, () => {
            setWayList(null);
        })
    }

    function refund(record) {
        setOrderInfo(record);
        setOpenRefundModel(true);
    }

    function resetPage(currentPage, pageSize) {
        setCurrentPage(currentPage);
        setPageSize(pageSize);
    }

    function orderProduct(record) {
        let orderProductInfos = [];
        if (record.commodityVOList.length > 0) {
            for (let i = 0; i < record.commodityVOList.length; i++) {
                let orderProductInfo = {};
                orderProductInfo.waitSendQuantity = record.commodityVOList[i].productVO.waitSendQuantity;
                orderProductInfo.purchaseQuantity = record.commodityVOList[i].purchaseQuantity;
                orderProductInfo.number = record.commodityVOList[i].productVO.number;
                orderProductInfo.name = record.commodityVOList[i].productVO.name;
                orderProductInfo.image = record.commodityVOList[i].productImageUrl;
                orderProductInfo.color = record.commodityVOList[i].productVO.color;
                orderProductInfo.spec = record.commodityVOList[i].productVO.spec;
                orderProductInfo.size = record.commodityVOList[i].productVO.size;
                orderProductInfo.orderNumber = record.orderNumber;
                orderProductInfo.title = record.title;
                orderProductInfos.push(orderProductInfo);
            }

        }
        setOrderProductInfo(orderProductInfos);
        setOpenOrderProductModel(true);
    }

    return (
        <div>
            <Form>
                <Row gutter={[16, 0]}>
                    <Col span={6}>
                        <Form.Item
                            label="商品标题"
                            name="title">
                            <Input placeholder="请输入商品标题" onChange={(e) => setTitle(e.target.value)}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="订单编号"
                            name="orderNumber">
                            <Input placeholder="请输入订单编号" onChange={(e) => setOrderNumber(e.target.value)}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="下单账户"
                            name="username">
                            <Input placeholder="请输入下单账户" onChange={(e) => setUsername(e.target.value)}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="收货人"
                            name="realName">
                            <Input placeholder="请输入收货人" onChange={(e) => setRealName(e.target.value)}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 0]}>
                    <Col span={6}>
                        <Form.Item
                            label="联系电话"
                            name="tel">
                            <Input placeholder="请输入联系电话" onChange={(e) => setTel(e.target.value)}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="支付方式"
                            name="way">
                            <Select onChange={(value, option) => {
                                setWay(option.key)
                            }}>
                                {
                                    wayList != null ?
                                        wayList.map(item => {
                                            return (<Select.Option key={item.way}
                                                                   value={item.way}>{item.wayText}</Select.Option>)
                                        }) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="订单类型"
                            name="type">
                            <Select onChange={(value, option) => {
                                setType(option.key)
                            }}>
                                {
                                    typeList != null ?
                                        typeList.map(item => {
                                            return (<Select.Option key={item.type}
                                                                   value={item.type}>{item.typeText}</Select.Option>)
                                        }) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item>
                            <Button type="primary" onClick={() => {
                                setLoading(true);
                                init(Constant.CURRENT_PAGE, Constant.PAGE_SIZE);
                            }}>查询</Button>
                            <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                                    onClick={() => {
                                        setTitle(null);
                                        setOrderNumber(null);
                                        setUsername(null);
                                        setRealName(null);
                                        setTel(null);
                                        setWay(null);
                                        setType(null);
                                    }}>重置</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <CommonTable
                columns={columns}
                rowKey={columns => columns.id}
                data={data}
                width={3500}
                loading={loading}
                total={total}
                currentPage={currentPage}
                pageSize={pageSize}
                initData={(currentPage, pageSize) => init(currentPage, pageSize)}
                resetPage={(currentPage, pageSize) => resetPage(currentPage, pageSize)}/>
            <OrderProductInfo open={openOrderProductModel} setOpenOrderProductModel={setOpenOrderProductModel}
                              data={orderProductInfo}
                              init={init}/>
            <Refund open={openRefundModel} setOpenRefundModel={setOpenRefundModel}
                    orderInfo={orderInfo}
                    init={init}/>
        </div>
    )
}