import {ExclamationCircleOutlined} from '@ant-design/icons';
import React from "react";
import HttpUtil from "../../../../../util/httpUtil";

/**
 * 获取仓库信息列表
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doList = function (params, successFun, errorFun) {
    HttpUtil.doGet(
        "/warehouse/list",
        params,
        successFun, errorFun);
}
/**
 * 删除仓库信息
 * @param record
 * @param successFun
 * @param errorFun
 */
export const doDel = function (record, successFun, errorFun) {
    HttpUtil.doDelete(<ExclamationCircleOutlined/>, "删除仓库",
        "确认删除" + record.name + "信息？",
        "/warehouse/delete/" + record.id, null, successFun, errorFun);
}
/**
 * 新增仓库信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doAdd = function (params, successFun, errorFun) {
    HttpUtil.doPost("/warehouse/add",
        params, successFun, errorFun);
}
/**
 * 更新仓库信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doUpdate = function (params, successFun, errorFun) {
    HttpUtil.doPut("/warehouse/update",
        params, successFun, errorFun);
}
/**
 * 禁用仓库
 * @param record
 * @param successFun
 * @param errorFun
 */
export const disable = function (record, successFun, errorFun) {
    HttpUtil.doPutConfirm(<ExclamationCircleOutlined/>, "禁用仓库", "确认禁用" + record.name + "仓库？禁用仓库，将禁用对应的仓位！",
        "/warehouse/disable/" + record.id, null, successFun, errorFun);
}
/**
 * 启用仓库
 * @param record
 * @param successFun
 * @param errorFun
 */
export const enable = function (record, successFun, errorFun) {
    HttpUtil.doPutConfirm(<ExclamationCircleOutlined/>, "启用仓库", "确认启用" + record.name + "仓库？启用仓库，将启用对应的仓位！",
        "/warehouse/enable/" + record.id, null, successFun, errorFun);
}
