import {Row, Col, Form, Input, Select, InputNumber, Switch} from "antd";
import React from "react";

const {TextArea} = Input;

export default function TemplateForm(props) {
    const layout = {
        labelCol: {span: 8},
        wrapperCol: {span: 16},
    };

    return (
        <div>
            <Form
                ref={props.formRef} {...layout}>
                <Form.Item
                    label="模版ID"
                    name="id"
                    hidden="true">
                    <Input/>
                </Form.Item>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="编码"
                            name="number">
                            <Input disabled placeholder="系统自动生成"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="名称"
                            name="name"
                            rules={[{required: true, message: '请输入模版名称'}]}>
                            <Input placeholder="请输入模版名称"
                                   disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="图片宽度"
                            name="imageW"
                            initialValue={500}>
                            <InputNumber placeholder="请输入图片宽度" min={200} max={600}
                                         style={{"width": 245}}
                                         disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="图片高度"
                            name="imageH"
                            initialValue={400}>
                            <InputNumber placeholder="请输入图片高度" min={150} max={500}
                                         style={{"width": 245}}
                                         disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="条码宽度"
                            name="codeW"
                            initialValue={480}>
                            <InputNumber placeholder="请输入条码宽度" min={200} max={500}
                                         style={{"width": 245}}
                                         disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="条码高度"
                            name="codeH"
                            initialValue={120}>
                            <InputNumber placeholder="请输入条码高度" min={50} max={400}
                                         style={{"width": 245}}
                                         disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="左边距"
                            name="codeLeftMargin"
                            initialValue={2}>
                            <InputNumber placeholder="请输入左边距" min={0} max={50} style={{"width": 245}}
                                         disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="上边距"
                            name="codeTopMargin"
                            initialValue={2}>
                            <InputNumber placeholder="请输入上边距" min={0} max={50} style={{"width": 245}}
                                         disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="条码位置"
                            name="codePosition"
                            initialValue={1}>
                            <Select
                                placeholder="请选择条码位置"
                                showSearch
                                allowClear
                                optionFilterProp="label"
                                disabled={(props.view === undefined || props.view === null) ? false : props.view}>
                                {
                                    props.positionList != null ?
                                        props.positionList.map(item => {
                                            return (<Select.Option key={item.position}
                                                                   value={item.position}
                                                                   label={item.positionText}>{item.positionText}</Select.Option>)
                                        }) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="条码文字位置"
                            name="codeCharactersPosition"
                            initialValue={1}>
                            <Select
                                placeholder="请选择条码文字位置"
                                showSearch
                                allowClear
                                optionFilterProp="label"
                                disabled={(props.view === undefined || props.view === null) ? false : props.view}>
                                {
                                    props.positionList != null ?
                                        props.positionList.map(item => {
                                            return (<Select.Option key={item.position}
                                                                   value={item.position}
                                                                   label={item.positionText}>{item.positionText}</Select.Option>)
                                        }) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="条码文字字号"
                            name="codeCharactersSize"
                            initialValue={24}>
                            <InputNumber placeholder="请输入条码文字字号" min={12} max={30}
                                         style={{"width": 245}}
                                         disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="文字字号"
                            name="charactersSize"
                            initialValue={24}>
                            <InputNumber placeholder="请输入文字字号" min={10} max={30} style={{"width": 245}}
                                         disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                        </Form.Item>
                    </Col>
                </Row>
                {/*<Row gutter={16}>*/}
                {/*    <Col span={12}>*/}
                {/*        <Form.Item*/}
                {/*            label="显示商品供应商"*/}
                {/*            name="showSupplier"*/}
                {/*            valuePropName="checked"*/}
                {/*            initialValue={true}>*/}
                {/*            <Switch checkedChildren="开启" unCheckedChildren="关闭" defaultChecked*/}
                {/*                    disabled={(props.view === undefined || props.view === null) ? false : props.view}/>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*    <Col span={12}>*/}
                {/*        <Form.Item*/}
                {/*            label="显示商品品牌"*/}
                {/*            name="showBrand"*/}
                {/*            valuePropName="checked"*/}
                {/*            initialValue={true}>*/}
                {/*            <Switch checkedChildren="开启" unCheckedChildren="关闭" defaultChecked*/}
                {/*                    disabled={(props.view === undefined || props.view === null) ? false : props.view}/>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="显示商品名称"
                            name="showCommodity"
                            valuePropName="checked"
                            initialValue={true}>
                            <Switch checkedChildren="开启" unCheckedChildren="关闭" defaultChecked
                                    disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    {/*<Col span={12}>*/}
                    {/*    <Form.Item*/}
                    {/*        label="显示商品类别"*/}
                    {/*        name="showCategory"*/}
                    {/*        valuePropName="checked"*/}
                    {/*        initialValue={true}>*/}
                    {/*        <Switch checkedChildren="开启" unCheckedChildren="关闭" defaultChecked*/}
                    {/*                disabled={(props.view === undefined || props.view === null) ? false : props.view}/>*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}
                    <Col span={12}>
                        <Form.Item
                            label="显示SKU码"
                            name="showCode"
                            valuePropName="checked"
                            initialValue={true}>
                            <Switch checkedChildren="开启" unCheckedChildren="关闭" defaultChecked
                                    disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="显示商品颜色"
                            name="showColor"
                            valuePropName="checked"
                            initialValue={true}>
                            <Switch checkedChildren="开启" unCheckedChildren="关闭" defaultChecked
                                    disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="显示商品大小"
                            name="showSize"
                            valuePropName="checked"
                            initialValue={true}>
                            <Switch checkedChildren="开启" unCheckedChildren="关闭" defaultChecked
                                    disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="显示商品规格"
                            name="showSpec"
                            valuePropName="checked"
                            initialValue={true}>
                            <Switch checkedChildren="开启" unCheckedChildren="关闭" defaultChecked
                                    disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                        </Form.Item>
                    </Col>
                    {/*<Col span={12}>*/}
                    {/*    <Form.Item*/}
                    {/*        label="显示商品单位"*/}
                    {/*        name="showUnit"*/}
                    {/*        valuePropName="checked"*/}
                    {/*        initialValue={true}>*/}
                    {/*        <Switch checkedChildren="开启" unCheckedChildren="关闭" defaultChecked*/}
                    {/*                disabled={(props.view === undefined || props.view === null) ? false : props.view}/>*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}
                </Row>
                <Row gutter={16}>
                    {/*<Col span={12}>*/}
                    {/*    <Form.Item*/}
                    {/*        label="显示商品重量"*/}
                    {/*        name="showWeight"*/}
                    {/*        valuePropName="checked"*/}
                    {/*        initialValue={true}>*/}
                    {/*        <Switch checkedChildren="开启" unCheckedChildren="关闭" defaultChecked*/}
                    {/*                disabled={(props.view === undefined || props.view === null) ? false : props.view}/>*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            labelCol={{span: 3}}
                            wrapperCol={{span: 21}}
                            label="描述"
                            name="remarks">
                            <TextArea
                                disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}