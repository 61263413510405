import React, {useEffect, useState} from "react";
import Constant from "../../../../../util/constant";
import {Image, Button, Form, Input, Select, Space, Row, Col, message} from "antd";
import {doDel, doList, disable, enable, doPrint} from "./api";
import CommonTable from "../../../../../component/CommonTable";
import {doInitStatus} from "../../../../../common/api";
import {doNoBarcodeList as doInitProduct} from "../../../commodity/manage/product/api"
import {doList as doInitTemplate} from "../../../barcode/setting/template/api"
import Util from "../../../../../util/params";

import AddBarcode from "./addBarcode";

export default function Barcode() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(Constant.CURRENT_PAGE);
    const [pageSize, setPageSize] = useState(Constant.PAGE_SIZE);

    const [productName, setProductName] = useState(null);
    const [productNumber, setProductNumber] = useState(null);
    const [number, setNumber] = useState(null);
    const [status, setStatus] = useState(null);
    const [statusList, setStatusList] = useState(null);
    const [productList, setProductList] = useState(null);
    const [templateList, setTemplateList] = useState(null);
    const [openAddModel, setOpenAddModel] = useState(false);

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 50,
        },
        {
            title: '商品名称',
            dataIndex: 'productName',
            key: 'productName',
        },
        {
            title: '商品颜色',
            dataIndex: 'productColor',
            key: 'productColor',
        },
        {
            title: '商品大小',
            dataIndex: 'productSize',
            key: 'productSize',
        },
        {
            title: '商品规格',
            dataIndex: 'productSpec',
            key: 'productSpec',
        },
        {
            title: 'SKU码',
            dataIndex: 'productNumber',
            key: 'productNumber',
        },
        {
            title: '条码编码',
            dataIndex: 'number',
            key: 'number',
        },
        {
            title: '条码模版',
            dataIndex: 'templateName',
            key: 'templateName',
        },
        {
            title: '条码',
            dataIndex: 'icon',
            key: 'icon',
            render: icon => {
                if (!Util.isEmpty(icon)) {
                    return (
                        <Image
                            width={25}
                            height={20}
                            src={icon}/>
                    )
                }
            }
        },
        {
            title: '状态',
            dataIndex: 'statusText',
            key: 'statusText',
        },
        {
            title: '描述',
            dataIndex: 'remarks',
            key: 'remarks',
        },
        {
            title: '创建时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: 170,
        },
        {
            title: '创建人',
            dataIndex: 'creator',
            key: 'creator',
        },
        {
            title: '最近修改时间',
            dataIndex: 'gmtUpdate',
            key: 'gmtUpdate',
            width: 170,
        },
        {
            title: '最近修改人',
            dataIndex: 'modifier',
            key: 'modifier',
        },
        {
            title: '操作',
            key: 'action',
            fixed: 'right',
            width: 120,
            render: (text, record) => (
                <Space size="small">
                    <a onClick={() => {
                        if (record.status === 0) {
                            disable(record, () => init())
                        } else {
                            enable(record, () => init())
                        }
                    }} style={{
                        'color': '#eea236'
                    }}>{record.status === 0 ? "禁用" : "启用"}</a>
                    <a style={{'color': '#d43f3a'}} onClick={() => doDel(record, () => {
                        init(Constant.CURRENT_PAGE, Constant.PAGE_SIZE)
                    })}>删除</a>
                    {
                        record.status === 0 ? (<a onClick={() => print(record)}>打印</a>) : null
                    }
                </Space>
            ),
        },
    ];
    columns.splice(0, 1)
    useEffect(() => {
        init(currentPage, pageSize);
        initStatus();
    }, []);// eslint-disable-line
    function init(current, page) {
        doList({
            productName: productName,
            productNumber: productNumber,
            number: number,
            status: status,
            currentPage: current === undefined ? currentPage : current,
            pageSize: page === undefined ? pageSize : page,
        }, (data) => {
            setData(data.resData.result);
            setLoading(false);
            setTotal(data.resData.total);
        }, () => {
            setData(null);
            setLoading(false);
        })
    }

    function initStatus() {
        doInitStatus((data) => {
            setStatusList(data.resData);
        }, () => {
            setStatusList(null);
        })
    }

    function initProduct() {
        doInitProduct({status: 0}, (data) => {
            setProductList(data.resData.result);
        }, () => {
            setProductList(null);
        })
    }

    function initTemplate() {
        doInitTemplate({status: 0}, (data) => {
            setTemplateList(data.resData.result);
        }, () => {
            setTemplateList(null);
        })
    }

    function add() {
        initProduct();
        initTemplate();
        setTimeout(() => {
            setOpenAddModel(true);
        }, 100);
    }

    /**
     * 打印
     * @param record
     */
    function print(record) {
        // 调用打印机打印
        let ids = [];
        ids.push(record.id);
        let barcodeDTO = {};
        barcodeDTO.ids = ids;
        doPrint(barcodeDTO, (res) => {
            if (res.code === 200) {
                message.info(res.msg)
            } else {
                message.error(res.msg)
            }
        })
    }

    // /**
    //  * 批量打印
    //  */
    // function batchPrint() {
    //     // 调用打印机打印
    // }

    function resetPage(currentPage, pageSize) {
        setCurrentPage(currentPage);
        setPageSize(pageSize);
    }

    return (
        <div>
            <Form>
                <Row gutter={[16, 0]}>
                    <Col span={6}>
                        <Form.Item
                            label="商品名称"
                            name="productName">
                            <Input placeholder="请输入商品名称" onChange={(e) => setProductName(e.target.value)}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="SKU码"
                            name="productNumber">
                            <Input placeholder="请输入SKU码" onChange={(e) => setProductNumber(e.target.value)}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="条码编码"
                            name="number">
                            <Input placeholder="请输入条码编码" onChange={(e) => setNumber(e.target.value)}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="状态"
                            name="status">
                            <Select onChange={(value, option) => {
                                setStatus(option.key)
                            }}>
                                {
                                    statusList != null ?
                                        statusList.map(item => {
                                            return (<Select.Option key={item.status}
                                                                   value={item.status}>{item.statusText}</Select.Option>)
                                        }) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item>
                            <Button type="primary" onClick={() => {
                                setLoading(true);
                                init(Constant.CURRENT_PAGE, Constant.PAGE_SIZE);
                            }}>查询</Button>
                            <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                                    onClick={() => {
                                        setProductName(null);
                                        setProductNumber(null);
                                        setNumber(null);
                                        setStatus(null);
                                    }}>重置</Button>
                            <Button type="primary" style={{'marginLeft': '10px'}} onClick={() => add()}>新增条码</Button>
                            {/*<Button type="primary" style={{'marginLeft': '10px'}}*/}
                            {/*        onClick={() => batchPrint()}>批量打印</Button>*/}
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <CommonTable
                columns={columns}
                rowKey={columns => columns.id}
                data={data}
                width={2000}
                loading={loading}
                total={total}
                currentPage={currentPage}
                pageSize={pageSize}
                initData={(currentPage, pageSize) => init(currentPage, pageSize)}
                resetPage={(currentPage, pageSize) => resetPage(currentPage, pageSize)}/>
            <AddBarcode open={openAddModel}
                        setOpenAddModel={setOpenAddModel}
                        productList={productList}
                        templateList={templateList}
                        init={init}/>
        </div>
    )
}