import CommonModal from "../../../../component/CommonModal";
import React from "react";
import AddressForm from "./addressForm";

export default function Address(props) {
    const addFormRef = React.createRef();

    function handleCancel() {
        props.setOpenAddressModel(false);
    }

    return (
        <CommonModal
            title="用户地址"
            open={props.open}
            width={800}
            onOk={() => handleCancel()}
            onCancel={() => handleCancel()}
            destroyOnClose={true}
            form={<AddressForm formRef={addFormRef} userId={props.userId}/>}/>
    )
}