import Head from "../head/head"
import styles from "./home.module.css"
import {Outlet} from 'react-router-dom'
import NavigateComponent from "../../common/NavigateComponent";
import LeftMenu from "../menu/menu";

export default function Home() {
    return (
        <div>
            <Head/>
            <NavigateComponent element={LeftMenu}/>
            <div className={styles.content} id="content">
                <div className={styles.content_info}>
                    <Outlet/>
                </div>
            </div>
        </div>
    )
}