import React, {useEffect, useState} from "react";
import Constant from "../../../../../util/constant";
import {Button, Col, Form, Image, Input, Row, Select, Space, TreeSelect} from "antd";
import {doInitStatus, disable, doDel, doList, enable} from "./api";
import CommonTable from "../../../../../component/CommonTable";
import {doTreeList} from "../../../shop/base/category/api";
import AddSold from "./addSold";
import Util from "../../../../../util/params";
import ViewSold from "./viewSold";
import EditSold from "./editSold";

export default function Sold() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(Constant.CURRENT_PAGE);
    const [pageSize, setPageSize] = useState(Constant.PAGE_SIZE);

    const [title, setTitle] = useState(null);
    const [number, setNumber] = useState(null);
    const [shopCategoryId, setShopCategoryId] = useState(null);
    const [status, setStatus] = useState(null);
    const [statusList, setStatusList] = useState(null);
    const [categoryList, setCategoryList] = useState(null);

    const [saleCommodity, setSaleCommodity] = useState(null);
    const [openAddModel, setOpenAddModel] = useState(false);
    const [openViewModel, setOpenViewModel] = useState(false);
    const [openEditModel, setOpenEditModel] = useState(false);
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 50,
        },
        {
            title: '商品图片',
            dataIndex: 'imagesUrl',
            key: 'imagesUrl',
            render: imagesUrl => {
                if (!Util.isEmpty(imagesUrl)) {
                    let images = imagesUrl.split(",");
                    return (
                        <Image.PreviewGroup
                            items={images}>
                            <Image
                                width={25}
                                height={20}
                                src={images[0]}/>
                        </Image.PreviewGroup>
                    )
                }
            }
        },
        {
            title: '商品编码',
            dataIndex: 'number',
            key: 'number',
        },
        {
            title: '商品标题',
            dataIndex: 'title',
            key: 'title',
            width: 300,
            ellipsis: true,
        },
        {
            title: '库存总量',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        // {
        //     title: '总销量',
        //     dataIndex: 'sales',
        //     key: 'sales',
        // },
        {
            title: '状态',
            dataIndex: 'statusText',
            key: 'statusText',
        },
        {
            title: '商品类型',
            dataIndex: 'typeText',
            key: 'typeText',
        },
        {
            title: '排序',
            dataIndex: 'orderNumber',
            key: 'orderNumber',
        },
        {
            title: '描述',
            dataIndex: 'remarks',
            key: 'remarks',
        },
        {
            title: '创建时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: 170,
        },
        {
            title: '创建人',
            dataIndex: 'creator',
            key: 'creator',
        },
        {
            title: '最近修改时间',
            dataIndex: 'gmtUpdate',
            key: 'gmtUpdate',
            width: 170,
        },
        {
            title: '最近修改人',
            dataIndex: 'modifier',
            key: 'modifier',
        },
        {
            title: '操作',
            key: 'action',
            fixed: 'right',
            width: 155,
            render: (text, record) => (
                <Space size="small" style={{"float": "right"}}>
                    <a onClick={() => view(record)}>查看</a>
                    <a onClick={() => edit(record)}>编辑</a>
                    <a onClick={() => {
                        if (record.status === 0) {
                            disable(record, () => init())
                        } else {
                            enable(record, () => init())
                        }
                    }} style={{
                        'color': '#eea236'
                    }}>{record.status === 0 ? "下架" : "上架"}</a>
                    {
                        record.status === 0 ? null :
                            <a style={{'color': '#d43f3a'}} onClick={() => doDel(record, () => {
                                init()
                            })}>删除</a>
                    }
                </Space>
            ),
        },
    ];
    columns.splice(0, 1)
    useEffect(() => {
        init(currentPage, pageSize);
        initStatus();
        initCategory();
    }, []);// eslint-disable-line
    function init(current, page) {
        doList({
            title: title,
            number: number,
            status: status,
            shopCategoryId: shopCategoryId,
            currentPage: current === undefined ? currentPage : current,
            pageSize: page === undefined ? pageSize : page
        }, (data) => {
            setData(data.resData.result);
            setLoading(false);
            setTotal(data.resData.total);
        }, () => {
            setData(null);
            setLoading(false);
        })
    }

    function initCategory() {
        doTreeList({status: 0}, (data) => {
            setCategoryList(data.resData);
        }, () => {
            setCategoryList(null);
        });
    }

    function add() {
        setOpenAddModel(true);
    }

    function view(record) {
        setSaleCommodity(record);
        setOpenViewModel(true);
    }

    function edit(record) {
        setSaleCommodity(record);
        setOpenEditModel(true);
    }

    function initStatus() {
        doInitStatus((data) => {
            setStatusList(data.resData);
        }, () => {
            setStatusList(null);
        })
    }

    function resetPage(currentPage, pageSize) {
        setCurrentPage(currentPage);
        setPageSize(pageSize);
    }

    return (
        <div>
            <Form>
                <Row gutter={[16, 0]}>
                    <Col span={6}>
                        <Form.Item
                            label="商品标题"
                            name="title">
                            <Input placeholder="请输入商品标题" onChange={(e) => setTitle(e.target.value)}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="商品编码"
                            name="number">
                            <Input placeholder="请输入商品编码" onChange={(e) => setNumber(e.target.value)}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="上架类目"
                            name="shopCategory">
                            <TreeSelect
                                showSearch
                                treeNodeFilterProp="title"
                                onChange={(value) => {
                                    if (value === undefined || value === null) {
                                        setShopCategoryId(null);
                                    } else {
                                        setShopCategoryId(value);
                                    }
                                }}
                                style={{width: '100%'}}
                                dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                allowClear
                                treeData={categoryList}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="状态"
                            name="status">
                            <Select onChange={(value, option) => {
                                setStatus(option.key)
                            }}>
                                {
                                    statusList != null ?
                                        statusList.map(item => {
                                            return (<Select.Option key={item.status}
                                                                   value={item.status}>{item.statusText}</Select.Option>)
                                        }) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 0]}>
                    <Col span={6}>
                        <Form.Item>
                            <Button type="primary" onClick={() => {
                                setLoading(true);
                                init(Constant.CURRENT_PAGE, Constant.PAGE_SIZE);
                            }}>查询</Button>
                            <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                                    onClick={() => {
                                        setTitle(null);
                                        setShopCategoryId(null);
                                        setNumber(null);
                                        setStatus(null);
                                    }}>重置</Button>
                            <Button type="primary" style={{'marginLeft': '10px'}} onClick={() => add()}>新增上架</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <CommonTable
                columns={columns}
                rowKey={columns => columns.id}
                data={data}
                width={2500}
                loading={loading}
                total={total}
                currentPage={currentPage}
                pageSize={pageSize}
                initData={(currentPage, pageSize) => init(currentPage, pageSize)}
                resetPage={(currentPage, pageSize) => resetPage(currentPage, pageSize)}/>
            <AddSold open={openAddModel}
                     setOpenAddModel={setOpenAddModel}
                     categoryList={categoryList}
                     init={init}/>
            <ViewSold open={openViewModel}
                      setOpenViewModel={setOpenViewModel}
                      categoryList={categoryList}
                      saleCommodity={saleCommodity}/>
            <EditSold open={openEditModel}
                      setOpenEditModel={setOpenEditModel}
                      categoryList={categoryList}
                      saleCommodity={saleCommodity}
                      init={init}/>
        </div>
    )
}