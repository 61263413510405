import React from "react";
import CommonModal from "../../../../../component/CommonModal";
import TemplateForm from "./templateForm";

export default function ViewTemplate(props) {
    const viewFormRef = React.createRef();
    if (props.barcodeTemplate != null && props.open) {
        setTimeout(() => {
            viewFormRef.current.setFieldsValue(props.barcodeTemplate);
            viewFormRef.current.setFieldsValue({
                'codePosition': props.barcodeTemplate.codePositionText,
                'codeCharactersPosition': props.barcodeTemplate.codeCharactersPositionText,
            });
        }, 100);
    }

    function handleCancel() {
        props.setOpenViewModel(false);
    }

    return (
        <CommonModal
            title="编辑模版"
            open={props.open}
            onOk={() => handleCancel()}
            onCancel={() => handleCancel()}
            destroyOnClose={true}
            width={800}
            form={<TemplateForm formRef={viewFormRef}
                                view={true}/>}/>
    )
}