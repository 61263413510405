import HttpUtil from "../../../../../util/httpUtil";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import React from "react";

/**
 * 获取运费模版信息列表
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doList = function (params, successFun, errorFun) {
    HttpUtil.doGet(
        "/shop/logistics/freightTemplate/list",
        params,
        successFun, errorFun);
}
/**
 * 获取配送区域信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doListRegion = function (params, successFun, errorFun) {
    HttpUtil.doGet(
        "/shop/logistics/freightTemplate/listRegion",
        params,
        successFun, errorFun);
}
/**
 * 获取计费规则信息
 * @param id
 * @param successFun
 * @param errorFun
 */
export const getBillingRules = function (id, successFun, errorFun) {
    HttpUtil.doGet(
        "/shop/logistics/freightTemplate/getBillingRule/" + id,
        null,
        successFun, errorFun);
}
/**
 * 删除运费模版信息
 * @param record
 * @param successFun
 * @param errorFun
 */
export const doDel = function (record, successFun, errorFun) {
    HttpUtil.doDelete(<ExclamationCircleOutlined/>, "删除运费模版",
        "确认删除" + record.name + "信息？",
        "/shop/logistics/freightTemplate/delete/" + record.id, null, successFun, errorFun);
}
/**
 * 新增运费模版信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doAdd = function (params, successFun, errorFun) {
    HttpUtil.doPost("/shop/logistics/freightTemplate/add",
        params, successFun, errorFun);
}
/**
 * 更新运费模版信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doUpdate = function (params, successFun, errorFun) {
    HttpUtil.doPut("/shop/logistics/freightTemplate/update",
        params, successFun, errorFun);
}
/**
 * 禁用运费模版
 * @param record
 * @param successFun
 * @param errorFun
 */
export const disable = function (record, successFun, errorFun) {
    HttpUtil.doPutConfirm(<ExclamationCircleOutlined/>, "禁用运费模版", "确认禁用" + record.name + "运费模版？",
        "/shop/logistics/freightTemplate/disable/" + record.id, null, successFun, errorFun);
}
/**
 * 启用运费模版
 * @param record
 * @param successFun
 * @param errorFun
 */
export const enable = function (record, successFun, errorFun) {
    HttpUtil.doPutConfirm(<ExclamationCircleOutlined/>, "启用运费模版", "确认启用" + record.name + "运费模版？",
        "/shop/logistics/freightTemplate/enable/" + record.id, null, successFun, errorFun);
}
