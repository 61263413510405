import type {TabsProps} from "antd";
import {Tabs} from "antd";
import React from "react";
import AllVideo from "./all/allVideo";

export default function VideoTab() {
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: '全部视频',
            children: <AllVideo/>,
        }
    ];

    return (
        <Tabs defaultActiveKey="1" items={items}/>
    )
}