const Constant = {
    BASE_URL: "https://weiljs.cn/neo",
    AES_KEY: "34439a96e68b129093105b67de81c0fc",
    USERNAME: "USERNAME",
    USERTYPE: "USERTYPE",
    AUTHORIZATION: "AUTHORIZATION",
    IS_SYSTEM_ADMIN: "9",
    CURRENT_PAGE: 1,
    PAGE_SIZE: 10,
}
export default Constant;