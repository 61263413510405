import {Form, Input} from "antd";
import React from "react";

const {TextArea} = Input;

export default function WarehouseForm(props) {
    const layout = {
        labelCol: {span: 4},
        wrapperCol: {span: 20},
    };
    return (
        <div>
            <Form
                ref={props.formRef} {...layout}>
                <Form.Item
                    label="仓库ID"
                    name="id"
                    hidden="true">
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="仓库编码"
                    name="number">
                    <Input disabled placeholder="系统自动生成"/>
                </Form.Item>
                <Form.Item
                    label="仓库名称"
                    name="name"
                    rules={[{required: true, message: '请输入仓库名称'}]}>
                    <Input placeholder="请输入仓库名称"/>
                </Form.Item>
                <Form.Item
                    label="仓库地址"
                    name="address"
                    rules={[{required: true, message: '请输入仓库地址'}]}>
                    <Input placeholder="请输入仓库地址"/>
                </Form.Item>
                <Form.Item
                    label="仓库容量"
                    name="capacity">
                    <Input placeholder="请输入仓库容量"/>
                </Form.Item>
                <Form.Item
                    label="仓库描述"
                    name="remarks">
                    <TextArea/>
                </Form.Item>
            </Form>
        </div>
    )
}