import {ExclamationCircleOutlined} from '@ant-design/icons';
import React from "react";
import HttpUtil from "../../../../../util/httpUtil";

/**
 * 获取仓位信息列表
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doList = function (params, successFun, errorFun) {
    HttpUtil.doGet(
        "/warehouse/position/list",
        params,
        successFun, errorFun);
}
/**
 * 获取仓位信息列表
 * @param params
 * @param successFun
 * @param errorFun
 */
export const listWarehouse = function (params, successFun, errorFun) {
    HttpUtil.doGet(
        "/warehouse/list",
        params,
        successFun, errorFun);
}
/**
 * 删除仓位信息
 * @param record
 * @param successFun
 * @param errorFun
 */
export const doDel = function (record, successFun, errorFun) {
    HttpUtil.doDelete(<ExclamationCircleOutlined/>, "删除仓位",
        "确认删除" + record.name + "信息？",
        "/warehouse/position/delete/" + record.id, null, successFun, errorFun);
}
/**
 * 新增仓位信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doAdd = function (params, successFun, errorFun) {
    HttpUtil.doPost("/warehouse/position/add",
        params, successFun, errorFun);
}
/**
 * 更新仓位信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doUpdate = function (params, successFun, errorFun) {
    HttpUtil.doPut("/warehouse/position/update",
        params, successFun, errorFun);
}
/**
 * 禁用仓位
 * @param record
 * @param successFun
 * @param errorFun
 */
export const disable = function (record, successFun, errorFun) {
    HttpUtil.doPutConfirm(<ExclamationCircleOutlined/>, "禁用仓位", "确认禁用" + record.name + "仓位？",
        "/warehouse/position/disable/" + record.id, null, successFun, errorFun);
}
/**
 * 启用仓位
 * @param record
 * @param successFun
 * @param errorFun
 */
export const enable = function (record, successFun, errorFun) {
    HttpUtil.doPutConfirm(<ExclamationCircleOutlined/>, "启用仓位", "确认启用" + record.name + "仓位？",
        "/warehouse/position/enable/" + record.id, null, successFun, errorFun);
}

