import HttpUtil from "../../../../../util/httpUtil";

/**
 * 获取入库条码信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doList = function (params, successFun, errorFun) {
    HttpUtil.doGet(
        "/warehouse/warehousingCode/list",
        params,
        successFun, errorFun);
}
/**
 * 打印条形码信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doPrint = function (params, successFun, errorFun) {
    HttpUtil.doPost("/warehouse/warehousingCode/print",
        params, successFun, errorFun);
}
