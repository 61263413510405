import type {TabsProps} from "antd";
import {Tabs} from "antd";
import React from "react";
import Account from "./account/account";
import BalanceApply from "./apply/balanceApply";

export default function CustomerTab() {
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: '账户管理',
            children: <Account/>,
        },
        {
            key: '2',
            label: '余额申请',
            children: <BalanceApply/>,
        }
    ];

    return (
        <Tabs defaultActiveKey="1" items={items}/>
    )
}