import {Form, Input} from "antd";
import React from "react";

const {TextArea} = Input;

export default function BrandForm(props) {
    const layout = {
        labelCol: {span: 4},
        wrapperCol: {span: 20},
    };
    return (
        <div>
            <Form
                ref={props.formRef} {...layout}>
                <Form.Item
                    label="品牌ID"
                    name="id"
                    hidden="true">
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="编码"
                    name="number">
                    <Input disabled placeholder="系统自动生成"/>
                </Form.Item>
                <Form.Item
                    label="名称"
                    name="name"
                    rules={[{required: true, message: '请输入名称'}]}>
                    <Input placeholder="请输入名称"/>
                </Form.Item>
                <Form.Item
                    label="描述"
                    name="remarks">
                    <TextArea/>
                </Form.Item>
            </Form>
        </div>
    )
}