import type {TabsProps} from "antd";
import {Tabs} from "antd";
import React from "react";
import ShopCategory from "./category/shopCategory";
import Label from "./label/label";
import Commitment from "./commitment/commitment";
import PaymentQrCode from "./paymentQrCode/paymentQrCode";
import Banner from "./banner/banner";
import Agreement from "./agreement/agreement";
import UserManual from "./userManual/userManual";

export default function ShopBaseTab() {
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: '类目管理',
            children: <ShopCategory/>,
        },
        {
            key: '2',
            label: '标签管理',
            children: <Label/>,
        },
        {
            key: '3',
            label: '承诺管理',
            children: <Commitment/>,
        },
        {
            key: '4',
            label: '收款码',
            children: <PaymentQrCode/>,
        },
        {
            key: '5',
            label: '首页横幅',
            children: <Banner/>,
        },
        {
            key: '6',
            label: '协议管理',
            children: <Agreement/>,
        },
        {
            key: '7',
            label: '用户手册',
            children: <UserManual/>,
        }
    ];

    return (
        <Tabs defaultActiveKey="1" items={items}/>
    )
}