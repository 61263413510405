import React, {useEffect, useState} from "react";
import Constant from "../../../../util/constant";
import CommonTable from "../../../../component/CommonTable";
import {listHarvestAddress} from "./api";

export default function AddressForm(props) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(Constant.CURRENT_PAGE);
    const [pageSize, setPageSize] = useState(Constant.PAGE_SIZE);

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '收货人',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: '联系电话',
            dataIndex: 'tel',
            key: 'tel',
        },
        {
            title: '省',
            dataIndex: 'province',
            key: 'province',
        },
        {
            title: '市',
            dataIndex: 'city',
            key: 'city',
        },
        {
            title: '区县',
            dataIndex: 'county',
            key: 'county',
        },
        {
            title: '详细地址',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: '默认地址',
            dataIndex: 'isDefaultText',
            key: 'isDefaultText',
        }
    ];

    useEffect(() => {
        init();
    }, []);// eslint-disable-line

    function init() {
        listHarvestAddress({
            userId: props.userId,
            pageSize: pageSize,
            currentPage: currentPage,
        }, (data) => {
            setData(data.resData.result);
            setLoading(false);
            setTotal(data.resData.total);
        }, () => {
            setData(null);
            setLoading(false);
        })
    }

    function resetPage(currentPage, pageSize) {
        setCurrentPage(currentPage);
        setPageSize(pageSize);
    }

    return (
        <div>
            <CommonTable
                columns={columns}
                rowKey={columns => columns.id}
                data={data}
                width={1000}
                loading={loading}
                total={total}
                currentPage={currentPage}
                pageSize={pageSize}
                initData={init}
                resetPage={resetPage}/>
        </div>
    )
}