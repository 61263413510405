import {ExclamationCircleOutlined} from '@ant-design/icons';
import React from "react";
import HttpUtil from "../../../../../util/httpUtil";

/**
 * 获取横幅列表
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doList = function (params, successFun, errorFun) {
    HttpUtil.doGet(
        "/shop/base/banner/list",
        params,
        successFun, errorFun);
}
/**
 * 删除横幅
 * @param record
 * @param successFun
 * @param errorFun
 */
export const doDel = function (record, successFun, errorFun) {
    HttpUtil.doDelete(<ExclamationCircleOutlined/>, "删除横幅",
        "确认删除" + record.name + "信息？",
        "/shop/base/banner/delete/" + record.id, null, successFun, errorFun);
}
/**
 * 新增横幅
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doAdd = function (params, successFun, errorFun) {
    HttpUtil.doPost("/shop/base/banner/add",
        params, successFun, errorFun);
}
