import React, {useState} from "react";
import {doAdd} from "./api";
import CommonModal from "../../../../../component/CommonModal";
import ProductForm from "./productForm";

export default function AddProduct(props) {
    const addFormRef = React.createRef();
    const [addSubmitLoading, setAddSubmitLoading] = useState(false);
    const [categoryId, setCategoryId] = useState(null);
    const [productColorList, setProductColorList] = useState([{key: 1}]);

    function add() {
        if (!addSubmitLoading) {
            addFormRef.current.validateFields().then(values => {
                let addProductColorList = []
                if (productColorList.length > 0) {
                    for (let i = 0; i < productColorList.length; i++) {
                        let productColor = {};
                        productColor.color = values[("color" + productColorList[i].key)];
                        productColor.size = values[("size" + productColorList[i].key)];
                        productColor.image = values[("imageFiles" + productColorList[i].key)].length > 0
                            ? values[("imageFiles" + productColorList[i].key)][0].response : null;
                        addProductColorList.push(productColor);
                    }
                }
                setAddSubmitLoading(true)
                doAdd({
                    name: values.name,
                    categoryId: categoryId,
                    supplierId: values.supplierId,
                    brandId: values.brandId,
                    spec: values.spec,
                    weight: values.weight,
                    // image: values.imageFiles.length > 0 ? values.imageFiles[0].response : null,
                    // color: values.color,
                    // size: values.size,
                    productColorList: addProductColorList,
                    unit: values.unit,
                    procurePrice: values.procurePrice,
                    salePrice: values.salePrice,
                    retailPrice: values.retailPrice,
                    remarks: values.remarks
                }, (data) => {
                    setAddSubmitLoading(false);
                    props.setOpenAddModel(false);
                    setCategoryId(null);
                    props.init();
                }, () => {
                    setAddSubmitLoading(false);
                })
            }).catch(e => {
                console.log(e);
            })
        }
    }

    function handleCancel() {
        props.setOpenAddModel(false);
        setCategoryId(null);
    }

    return (
        <CommonModal
            title="新增SKU"
            open={props.open}
            onOk={() => add()}
            onCancel={() => handleCancel()}
            destroyOnClose={true}
            width={750}
            confirmLoading={addSubmitLoading}
            form={<ProductForm formRef={addFormRef}
                               productColorList={productColorList}
                               setProductColorList={setProductColorList}
                               setCategoryId={setCategoryId}
                               categoryList={props.categoryList}
                               supplierList={props.supplierList}
                               brandList={props.brandList}/>}/>
    )
}