import React from "react";
import {doDelivery} from "../all/api";
import CommonModal from "../../../../component/CommonModal";
import SendOrderInfoForm from "./sendOrderInfoForm";

export default function SendOrderInfo(props) {
    const sendFormRef = React.createRef();
    if (props.orderInfo != null && props.open) {
        setTimeout(() => {
            sendFormRef.current.setFieldsValue({
                orderId: props.orderInfo.id
            });
        }, 100);
    }

    function send() {
        sendFormRef.current.validateFields().then(values => {
            doDelivery({
                orderId: values.orderId,
                sendQuantityType: values.sendQuantityType,
                logisticsCompanyId: values.logisticsCompanyId,
                packageNum: values.packageNum,
                warehousingCodeNumbers: values.warehousingCodeNumbers.split(","),
                remarks: values.remarks
            }, (data) => {
                clearInterval(sendFormRef.current);
                props.setOpenSendModel(false);
                props.init();
            })
        }).catch(e => {
            console.log(e)
        })

    }

    function handleCancel() {
        props.setOpenSendModel(false);
    }

    return (
        <CommonModal
            title="订单发货"
            open={props.open}
            onOk={() => send()}
            onCancel={() => handleCancel()}
            destroyOnClose={true}
            form={<SendOrderInfoForm formRef={sendFormRef}/>}/>
    )
}