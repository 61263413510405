import React from "react";
import {Table, Checkbox} from "antd";
import HttpUtil from "../util/httpUtil";

export default class CommonTable extends React.Component {
    state = {
        selected: [],
        checkboxText: "全选",
        isCheckAll: false,
        selectedRowKeys: [],
    }
    onSelectChange = (selectedRowKeys) => {
        const selected = this.state.selectedRowKeys;
        if (selectedRowKeys.length > 0) {
            selectedRowKeys.forEach(item => {
                if (selected.indexOf(item)) {
                    selected.push(item);
                }
            })
        }
        this.setState({
            selected: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
            selectedRowKeys: selected.sort(function (a, b) {
                return a - b
            }),
        });
        this.props.selectedRowKeysChange({
            selectedRowKeys: selected.sort(function (a, b) {
                return a - b
            })
        });
        if (this.state.selectedRowKeys.length === 0) {
            this.setState({
                isCheckAll: false,
                checkboxText: '全选',
            })
        }
    }
    rowOnSelect = (record, selected, selectedRows, nativeEvent) => {
        const selectedRowKeys = this.state.selectedRowKeys;
        if (selected === true) {
            selectedRowKeys.push(record.id);
        } else {
            const index = selectedRowKeys.indexOf(record.id);
            if (index >= 0) {
                selectedRowKeys.splice(index, 1);
            }
        }
        this.setState({
            selected: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
            selectedRowKeys: selectedRowKeys.sort(function (a, b) {
                return a - b
            }),
        });
        this.props.selectedRowKeysChange({
            selectedRowKeys: selectedRowKeys.sort(function (a, b) {
                return a - b
            })
        });
    }
    onSelectAll = (selected, selectedRows, changeRows) => {
        if (!selected) {
            const selectedRowKeys = this.state.selectedRowKeys;
            for (let i = 0; i < changeRows.length; i++) {
                const index = selectedRowKeys.indexOf(changeRows[i].id);
                selectedRowKeys.splice(index, 1);
            }
            this.setState({
                selected: selectedRowKeys.sort(function (a, b) {
                    return a - b
                }),
                selectedRowKeys: selectedRowKeys.sort(function (a, b) {
                    return a - b
                }),
            });
            this.props.selectedRowKeysChange({
                selectedRowKeys: selectedRowKeys.sort(function (a, b) {
                    return a - b
                })
            });
        }
    }
    checkSelectAll = (e) => {
        this.setState({
            isCheckAll: e.target.checked,
        });
        if (e.target.checked) {
            HttpUtil.doPost(this.props.checkSelectAllUrl, this.props.checkSelectParams, (data) => {
                this.setState({
                    selectedRowKeys: data.resData.sort(function (a, b) {
                        return a - b
                    }),
                    checkboxText: '取消全选',
                });
                this.props.selectedRowKeysChange({
                    selectedRowKeys: data.resData.sort(function (a, b) {
                        return a - b
                    })
                });
            }, null);
        } else {
            this.setState({
                selectedRowKeys: [],
                checkboxText: '全选',
            });
            this.props.selectedRowKeysChange({
                selectedRowKeys: [],
            });
        }
    }

    render() {
        const {columns, rowKey, data, loading, width, total, currentPage, pageSize, initData, resetPage, openRowSelection, closePagination} = this.props;
        const rowSelection = {
            selectedRowKeys: [...this.state.selectedRowKeys],
            fixed: true,
            onChange: this.onSelectChange,
            onSelect: this.rowOnSelect,
            onSelectAll: this.onSelectAll,
        }
        let pagination;
        if (closePagination) {
            pagination = false
        } else {
            pagination = {
                showTotal: () => `共 ${total} 条`,
                onShowSizeChange: (currentPage, pageSize) => {
                    resetPage(currentPage, pageSize)
                    setTimeout(() => {
                        initData(currentPage, pageSize);
                    }, 100);
                },
                total: total,
                current: currentPage,
                pageSize: pageSize,
                onChange: (currentPage, pageSize) => {
                    resetPage(currentPage, pageSize)
                    setTimeout(() => {
                        initData(currentPage, pageSize);
                    }, 100);
                }
            }
        }
        return (
            <div>
                <Table
                    columns={columns}
                    size="small"
                    dataSource={data}
                    rowSelection={openRowSelection ? rowSelection : null}
                    rowKey={rowKey}
                    loading={loading}
                    style={{"marginTop": "15px"}}
                    scroll={{x: width}}
                    pagination={pagination}/>
                {
                    openRowSelection === true ? (
                        <div style={{
                            marginTop: "-38px",
                            marginLeft: "8px",
                            display: (data === null || data.length === 0) ? 'none' : ''
                        }}>
                            < Checkbox onChange={this.checkSelectAll}
                                       checked={this.state.isCheckAll}>{this.state.checkboxText}</Checkbox>
                        </div>
                    ) : null
                }
            </div>
        )
    }
}