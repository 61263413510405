import React from "react";
import BalanceForm from "./balanceForm";
import {doUpdateUserBalance} from "../apply/api";
import CommonModal from "../../../../component/CommonModal";

export default function SetBalance(props) {
    const editFormRef = React.createRef();
    if (props.userWallet != null && props.open) {
        setTimeout(() => {
            editFormRef.current.setFieldsValue(props.userWallet);
        }, 100);
    }


    function update() {
        editFormRef.current.validateFields().then(values => {
            doUpdateUserBalance({
                userId: values.userId,
                balance: values.balance,
                arrears: values.arrears,
                repayment: values.repayment,
                remarks: values.remarks
            }, (data) => {
                clearInterval(editFormRef.current);
                props.setOpenBalanceModel(false);
                props.init();
            })
        }).catch(e => {
            console.log(e)
        })

    }

    function handleCancel() {
        props.setOpenBalanceModel(false);
    }

    return (
        <CommonModal
            title="设置信用额度"
            open={props.open}
            onOk={() => update()}
            onCancel={() => handleCancel()}
            destroyOnClose={true}
            form={<BalanceForm formRef={editFormRef}/>}/>
    )
}