import HttpUtil from "../../util/httpUtil";
import Aes from "../../util/aes";
import Constant from "../../util/constant";

/**
 * 登陆
 * @param username
 * @param password
 * @param successFun
 * @param errorFun
 */
export const doLogin = function (username, password, successFun, errorFun) {
    HttpUtil.doPost(
        "/user/login/doLogin",
        {
            username: username,
            password: Aes.aesEncrypt(password, Constant.AES_KEY)
        },
        successFun, errorFun)
};