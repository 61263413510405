import React from "react";
import CommonModal from "../../../../component/CommonModal";
import SendMergeOrderInfoForm from "./sendMergeOrderInfoForm";
import {doDeliveryMergeOrder} from "./api";

export default function SendMergeOrderInfo(props) {
    const sendFormRef = React.createRef();
    if (props.orderInfo != null && props.open) {
        setTimeout(() => {
            sendFormRef.current.setFieldsValue({
                orderId: props.orderInfo.id,
                orderNumber: props.orderInfo.orderNumber
            });
        }, 100);
    }

    function send() {
        sendFormRef.current.validateFields().then(values => {
            doDeliveryMergeOrder({
                orderNumber: values.orderNumber,
                sendQuantity: true,
                sendType: 1,
                logisticsCompanyId: values.logisticsCompanyId,
                packageNum: values.packageNum,
                warehousingCodeNumbers: values.warehousingCodeNumbers.split(","),
                remarks: values.remarks
            }, (data) => {
                clearInterval(sendFormRef.current);
                props.setOpenSendModel(false);
                props.init();
            })
        }).catch(e => {
            console.log(e)
        })

    }

    function handleCancel() {
        props.setOpenSendModel(false);
    }

    return (
        <CommonModal
            title="订单发货"
            open={props.open}
            onOk={() => send()}
            onCancel={() => handleCancel()}
            destroyOnClose={true}
            form={<SendMergeOrderInfoForm formRef={sendFormRef}/>}/>
    )
}