import CryptoJS from 'crypto-js'

const Aes = {}
/**
 * 加密
 * @param encryptString 要加密的字符串
 * @param key 秘钥
 * @returns {string} 加密后的字符串
 */
Aes.aesEncrypt = function (encryptString, key) {
    let aesKey = CryptoJS.enc.Utf8.parse(key);
    let aesEncryptString = CryptoJS.enc.Utf8.parse(encryptString);
    let encrypted = CryptoJS.AES.encrypt(aesEncryptString, aesKey, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
}

/**
 * 解密
 * @param decryptString 要解密的字符串
 * @param key 秘钥
 * @returns {string} 解密后的字符串
 */
Aes.aesDecrypt = function aesDecrypt(decryptString, key) {
    let aesKey = CryptoJS.enc.Utf8.parse(key);
    let decrypt = CryptoJS.AES.decrypt(decryptString, aesKey, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7});
    return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}
export default Aes;