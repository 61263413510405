import {Button, Form, Input, Select, Space} from "antd";
import {useEffect, useState} from "react";
import Constant from "../../../../../util/constant";
import CommonTable from "../../../../../component/CommonTable";
import AddWarehouse from "./addWarehouse";
import {doDel, doList, disable, enable} from "./api";
import EditWarehouse from "./editWarehouse";
import {doInitStatus} from "../../../../../common/api";

export default function WarehouseInfo() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(Constant.CURRENT_PAGE);
    const [pageSize, setPageSize] = useState(Constant.PAGE_SIZE);

    const [name, setName] = useState(null);
    const [status, setStatus] = useState(null);
    const [openAddModel, setOpenAddModel] = useState(false);
    const [openEditModel, setOpenEditModel] = useState(false);
    const [warehouseInfo, setWarehouseInfo] = useState(null);
    const [statusList, setStatusList] = useState(null);
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 50,
        },
        {
            title: '编码',
            dataIndex: 'number',
            key: 'number',
        },
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '地址',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: '容量/立方',
            dataIndex: 'capacity',
            key: 'capacity',
        },
        {
            title: '状态',
            dataIndex: 'statusText',
            key: 'statusText',
        },
        {
            title: '描述',
            dataIndex: 'remarks',
            key: 'remarks',
        },
        {
            title: '创建时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: 170,
        },
        {
            title: '创建人',
            dataIndex: 'creator',
            key: 'creator',
        },
        {
            title: '最近修改时间',
            dataIndex: 'gmtUpdate',
            key: 'gmtUpdate',
            width: 170,
        },
        {
            title: '最近修改人',
            dataIndex: 'modifier',
            key: 'modifier',
        },
        {
            title: '操作',
            key: 'action',
            fixed: 'right',
            width: 120,
            render: (text, record) => (
                <Space size="small">
                    <a onClick={() => edit(record)}>编辑</a>
                    <a onClick={() => {
                        if (record.status === 0) {
                            disable(record, () => init())
                        } else {
                            enable(record, () => init())
                        }
                    }} style={{
                        'color': '#eea236'
                    }}>{record.status === 0 ? "禁用" : "启用"}</a>
                    <a style={{'color': '#d43f3a'}} onClick={() => doDel(record, () => {
                        init()
                    })}>删除</a>
                </Space>
            ),
        },
    ];
    columns.splice(0, 1)
    useEffect(() => {
        init(currentPage, pageSize);
        initStatus();
    }, []);// eslint-disable-line
    function init(current, page) {
        doList({
            name: name,
            status: status,
            currentPage: current === undefined ? currentPage : current,
            pageSize: page === undefined ? pageSize : page
        }, (data) => {
            setData(data.resData.result);
            setLoading(false);
            setTotal(data.resData.total);
        }, () => {
            setData(null);
            setLoading(false);
        })
    }

    function initStatus() {
        doInitStatus((data) => {
            setStatusList(data.resData);
        }, () => {
            setStatusList(null);
        })
    }

    function add() {
        setOpenAddModel(true);
    }

    function edit(record) {
        setWarehouseInfo(record);
        setOpenEditModel(true);
    }

    function resetPage(currentPage, pageSize) {
        setCurrentPage(currentPage);
        setPageSize(pageSize);
    }

    return (
        <div>
            <Form layout='inline'>
                <Form.Item
                    label="仓库名称"
                    name="name">
                    <Input placeholder="请输入仓库名称" onChange={(e) => setName(e.target.value)}/>
                </Form.Item>
                <Form.Item
                    label="状态"
                    name="status"
                    style={{width: 180}}>
                    <Select onChange={(value, option) => {
                        setStatus(option.key)
                    }}>
                        {
                            statusList != null ?
                                statusList.map(item => {
                                    return (<Select.Option key={item.status}
                                                           value={item.status}>{item.statusText}</Select.Option>)
                                }) : null
                        }
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" onClick={() => {
                        setLoading(true);
                        init(Constant.CURRENT_PAGE, Constant.PAGE_SIZE);
                    }}>查询</Button>
                    <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                            onClick={() => {
                                setName(null);
                                setStatus(null);
                            }}>重置</Button>
                    <Button type="primary" style={{'marginLeft': '10px'}} onClick={() => add()}>新增仓库</Button>
                </Form.Item>
            </Form>
            <CommonTable
                columns={columns}
                rowKey={columns => columns.id}
                data={data}
                width={1700}
                loading={loading}
                total={total}
                currentPage={currentPage}
                pageSize={pageSize}
                initData={(currentPage, pageSize) => init(currentPage, pageSize)}
                resetPage={(currentPage, pageSize) => {
                    resetPage(currentPage, pageSize)
                }}/>
            <AddWarehouse open={openAddModel} setOpenAddModel={setOpenAddModel} init={init}/>
            <EditWarehouse open={openEditModel} setOpenEditModel={setOpenEditModel} warehouseInfo={warehouseInfo}
                           init={init}/>
        </div>
    )
}