import React from "react";
import {doUpdate} from "./api";
import CommonModal from "../../../../../component/CommonModal";
import CommitmentForm from "./commitmentForm";

export default function EditCommitment(props) {
    const editFormRef = React.createRef();
    if (props.label != null && props.open) {
        setTimeout(() => {
            editFormRef.current.setFieldsValue(props.label);
        }, 100);
    }


    function update() {
        editFormRef.current.validateFields().then(values => {
            doUpdate({
                id: values.id,
                name: values.name,
                remarks: values.remarks
            }, (data) => {
                clearInterval(editFormRef.current);
                props.setOpenEditModel(false);
                props.init();
            })
        }).catch(e => {
            console.log(e)
        })

    }

    function handleCancel() {
        props.setOpenEditModel(false);
    }

    return (
        <CommonModal
            title="编辑承诺"
            open={props.open}
            onOk={() => update()}
            onCancel={() => handleCancel()}
            destroyOnClose={true}
            form={<CommitmentForm formRef={editFormRef}/>}/>
    )
}