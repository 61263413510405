import {Col, Form, Row, DatePicker, Button, Select} from "antd";
import React, {useEffect, useState} from "react";
import locale from "antd/es/date-picker/locale/zh_CN";
import Constant from "../../../../util/constant";
import {doLogisticsCompanyList} from "../../order/all/api";
import CommonTable from "../../../../component/CommonTable";
import {doList} from "./api";

const {RangePicker} = DatePicker;

export default function PackageData() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(Constant.CURRENT_PAGE);
    const [pageSize, setPageSize] = useState(Constant.PAGE_SIZE);

    const [startMonth, setStartMonth] = useState(null);
    const [endMonth, setEndMonth] = useState(null);
    const [logisticsCompanyId, setLogisticsCompanyId] = useState(null);
    const [logisticsCompanyList, setLogisticsCompanyList] = useState(null);
    useEffect(() => {
        init(currentPage, pageSize);
        initLogisticsCompany();
    }, []);// eslint-disable-line
    const columns = [
        {
            title: '月份',
            dataIndex: 'month',
            key: 'month',
        },
        {
            title: '快递公司',
            dataIndex: 'logisticsCompany',
            key: 'logisticsCompany',
        },
        {
            title: '包裹数',
            dataIndex: 'packageNumber',
            key: 'packageNumber',
        }
    ];

    function init(current, page) {
        doList({
            startMonth: startMonth,
            endMonth: endMonth,
            logisticsCompanyId: logisticsCompanyId,
            currentPage: current === undefined ? currentPage : current,
            pageSize: page === undefined ? pageSize : page
        }, (data) => {
            setData(data.resData.result);
            setLoading(false);
            setTotal(data.resData.total);
        }, () => {
            setData(null);
            setLoading(false);
        })
    }

    function initLogisticsCompany() {
        doLogisticsCompanyList({
            status: 0,
        }, (data) => {
            setLogisticsCompanyList(data.resData.result);
        }, () => {
            setLogisticsCompanyList(null);
        })
    }

    function resetPage(currentPage, pageSize) {
        setCurrentPage(currentPage);
        setPageSize(pageSize);
    }

    return (
        <div>
            <Form>
                <Row gutter={[16, 0]}>
                    <Col span={8}>
                        <Form.Item
                            label="时间"
                            name="date">
                            <RangePicker
                                format="YYYY-MM"
                                locale={locale}
                                picker="month"
                                style={{"width": "340px"}}
                                onChange={(dates, dateStrings) => {
                                    setStartMonth(dateStrings[0]);
                                    setEndMonth(dateStrings[1]);
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="快递公司"
                            name="logisticsCompanyId">
                            <Select placeholder="请选择快递公司"
                                    optionFilterProp="label"
                                    showSearch
                                    allowClear
                                    onChange={(value, option) => {
                                        setLogisticsCompanyId(option.key)
                                    }}>
                                {
                                    logisticsCompanyList != null ?
                                        logisticsCompanyList.map(item => {
                                            return (<Select.Option key={item.id}
                                                                   value={item.id}
                                                                   label={item.name}>{item.name}</Select.Option>)
                                        }) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item>
                            <Button type="primary" onClick={() => {
                                setLoading(true);
                                init(Constant.CURRENT_PAGE, Constant.PAGE_SIZE);
                            }}>查询</Button>
                            <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                                    onClick={() => {
                                        setStartMonth(null);
                                        setEndMonth(null);
                                        setLogisticsCompanyId(null);
                                    }}>重置</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <CommonTable
                columns={columns}
                rowKey={columns => columns.month}
                data={data}
                loading={loading}
                total={total}
                currentPage={currentPage}
                pageSize={pageSize}
                initData={(currentPage, pageSize) => init(currentPage, pageSize)}
                resetPage={(currentPage, pageSize) => resetPage(currentPage, pageSize)}/>
        </div>
    )
}