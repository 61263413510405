import {ExclamationCircleOutlined} from '@ant-design/icons';
import React from "react";
import HttpUtil from "../../../../../util/httpUtil";

/**
 * 获取商品条形码信息列表
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doList = function (params, successFun, errorFun) {
    HttpUtil.doGet(
        "/barcode/commodity/list",
        params,
        successFun, errorFun);
}
/**
 * 删除商品条形码信息
 * @param record
 * @param successFun
 * @param errorFun
 */
export const doDel = function (record, successFun, errorFun) {
    HttpUtil.doDelete(<ExclamationCircleOutlined/>, "删除商品条形码",
        "确认删除" + record.productName + "条形码信息？",
        "/barcode/commodity/delete/" + record.id, null, successFun, errorFun);
}
/**
 * 新增商品条形码信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doAdd = function (params, successFun, errorFun) {
    HttpUtil.doPost("/barcode/commodity/add",
        params, successFun, errorFun);
}
/**
 * 更新商品条形码信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doUpdate = function (params, successFun, errorFun) {
    HttpUtil.doPut("/barcode/commodity/update",
        params, successFun, errorFun);
}
/**
 * 禁用商品条形码
 * @param record
 * @param successFun
 * @param errorFun
 */
export const disable = function (record, successFun, errorFun) {
    HttpUtil.doPutConfirm(<ExclamationCircleOutlined/>, "禁用商品条形码", "确认禁用" + record.productName + "条形码信息？",
        "/barcode/commodity/disable/" + record.id, null, successFun, errorFun);
}
/**
 * 启用商品条形码
 * @param record
 * @param successFun
 * @param errorFun
 */
export const enable = function (record, successFun, errorFun) {
    HttpUtil.doPutConfirm(<ExclamationCircleOutlined/>, "启用商品条形码", "确认启用" + record.productName + "条形码信息？",
        "/barcode/commodity/enable/" + record.id, null, successFun, errorFun);
}

/**
 * 打印商品条形码信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doPrint = function (params, successFun, errorFun) {
    HttpUtil.doPost("/barcode/commodity/barcode/print",
        params, successFun, errorFun);
}
