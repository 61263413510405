import {Form, Input, TreeSelect, InputNumber, Upload, message, Modal} from "antd";
import React, {useState} from "react";
import Content from "../../../../../util/constant";
import {doDeleteFile} from "../../../../../common/api";
import {PlusOutlined} from "@ant-design/icons";

const {TextArea} = Input;

export default function ShopCategoryForm(props) {
    const [imageFileList, setImageFileList] = useState([]);
    const [previewImage, setPreviewImage] = useState(null);
    const [previewImageVisible, setPreviewImageVisible] = useState(null);
    const [previewImageTitle, setPreviewImageTitle] = useState(null);
    const layout = {
        labelCol: {span: 4},
        wrapperCol: {span: 20},
    };
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };
    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
    function handlePreviewImageCancel() {
        setPreviewImageVisible(false);
    }
    return (
        <div>
            <Form
                ref={props.formRef} {...layout} initialValues={props.category}>
                <Form.Item
                    label="类目ID"
                    name="id"
                    hidden="true">
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="编码"
                    name="number">
                    <Input disabled placeholder="系统自动生成"/>
                </Form.Item>
                <Form.Item
                    label="父类目"
                    name="parentName">
                    <TreeSelect
                        showSearch
                        treeNodeFilterProp="title"
                        onChange={(value) => {
                            if (value === undefined || value === null) {
                                props.setParentId(0);
                            } else {
                                props.setParentId(value);
                            }
                        }}
                        style={{width: '100%'}}
                        dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                        allowClear
                        treeData={props.categoryList}
                    />
                </Form.Item>
                <Form.Item
                    label="名称"
                    name="name"
                    rules={[{required: true, message: '请输入类目名称'}]}>
                    <Input placeholder="请输入类目名称"/>
                </Form.Item>
                <Form.Item
                    label="序号"
                    name="orderNumber"
                    rules={[{required: true, message: '请输入类目排序，数字越小，排序越靠前'}]}>
                    <InputNumber max={100} min={1} style={{"width": "393px"}}/>
                </Form.Item>
                <Form.Item
                    label="类目图片"
                    name="imageFiles"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}>
                    <Upload
                        action={Content.BASE_URL + "/uploadFile"}
                        name='file'
                        withCredentials={true}
                        accept='.png,.jpg,.jpeg,.tiff'
                        listType="picture-card"
                        className="avatar-uploader"
                        fileList={imageFileList}
                        maxCount={1}
                        multiple={true}
                        beforeUpload={(file) => {
                            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
                                || file.type === 'image/jpg' || file.type === 'image/tiff';
                            const isLt2M = file.size / 1024 / 1024 < 20;
                            return new Promise((resolve, reject) => {
                                if (!isJpgOrPng) {
                                    message.error('文件格式不正常！');
                                    reject();
                                } else if (!isLt2M) {
                                    message.error('文件大小最大20M！');
                                    reject();
                                } else {
                                    resolve(file);
                                }
                            });
                        }}
                        onChange={(options) => {
                            const {fileList} = options;
                            let imageList = [];
                            for (let i = 0; i < fileList.length; i++) {
                                if (fileList[i].response !== undefined) {
                                    imageList[i] = fileList[i].response;
                                } else if (fileList[i].response === undefined && fileList[i].url !== undefined) {
                                    imageList[i] = fileList[i].url;
                                }

                            }
                            if (imageList.length > 0) {
                                setImageFileList(imageList);
                            }
                        }}
                        onPreview={async (file) => {
                            if (!file.url && !file.preview) {
                                file.preview = await getBase64(file.originFileObj);
                            }
                            setPreviewImage(file.url || file.preview);
                            setPreviewImageVisible(true);
                            setPreviewImageTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
                        }}
                        onRemove={(file) => {
                            if (file.response !== undefined) {
                                doDeleteFile({
                                    url: file.response
                                }, () => {
                                    let imageFiles = imageFileList;
                                    imageFiles = imageFiles.filter(item => item !== file.response);
                                    setImageFileList(imageFiles);
                                }, null);
                            }
                        }}>
                        <div>
                            <PlusOutlined style={{"fontSize": "32px", "color": "#cccccc"}}/>
                            <div style={{marginTop: 8}}>上传图片</div>
                        </div>
                    </Upload>
                </Form.Item>
                <Form.Item
                    label="描述"
                    name="remarks">
                    <TextArea/>
                </Form.Item>
            </Form>
            <Modal
                open={previewImageVisible}
                title={previewImageTitle}
                footer={null}
                onCancel={() => handlePreviewImageCancel()}>
                <img alt="example" style={{width: '100%'}} src={previewImage}/>
            </Modal>
        </div>
    )
}