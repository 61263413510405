import {ExclamationCircleOutlined} from '@ant-design/icons';
import React from "react";
import HttpUtil from "../../../../../util/httpUtil";
import Axios from "../../../../../util/axios";

/**
 * 获取位置信息
 * @param successFun
 * @param errorFun
 */
export const doInitPosition = function (successFun, errorFun) {
    HttpUtil.doGet(
        "/barcode/template/listPosition",
        null,
        successFun, errorFun);
}

/**
 * 获取条码模版信息列表
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doList = function (params, successFun, errorFun) {
    HttpUtil.doGet(
        "/barcode/template/list",
        params,
        successFun, errorFun);
}
/**
 * 删除条码模版信息
 * @param record
 * @param successFun
 * @param errorFun
 */
export const doDel = function (record, successFun, errorFun) {
    HttpUtil.doDelete(<ExclamationCircleOutlined/>, "删除条码模版",
        "确认删除" + record.name + "信息？",
        "/barcode/template/delete/" + record.id, null, successFun, errorFun);
}
/**
 * 新增条码模版信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doAdd = function (params, successFun, errorFun) {
    HttpUtil.doPost("/barcode/template/add",
        params, successFun, errorFun);
}
/**
 * 更新条码模版信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doUpdate = function (params, successFun, errorFun) {
    HttpUtil.doPut("/barcode/template/update",
        params, successFun, errorFun);
}
/**
 * 禁用条码模版
 * @param record
 * @param successFun
 * @param errorFun
 */
export const disable = function (record, successFun, errorFun) {
    HttpUtil.doPutConfirm(<ExclamationCircleOutlined/>, "禁用条码模版", "确认禁用" + record.name + "？",
        "/barcode/template/disable/" + record.id, null, successFun, errorFun);
}
/**
 * 启用条码模版
 * @param record
 * @param successFun
 * @param errorFun
 */
export const enable = function (record, successFun, errorFun) {
    HttpUtil.doPutConfirm(<ExclamationCircleOutlined/>, "启用条码模版", "确认启用" + record.name + "？",
        "/barcode/template/enable/" + record.id, null, successFun, errorFun);
}
/**
 * 预览条形码
 * @param record
 * @param successFun
 * @param errorFun
 */
export const previewBarcode = function (record, successFun, errorFun) {
    Axios({
        method: 'post',
        url: "/barcode/template/previewBarcode/" + record.id,
        responseType: 'blob',
    }).then(res => {
        if (res.status === 200) {
            if (successFun != null && successFun !== undefined) {
                successFun(res.data);
            }
        }
    }).catch(err => {
        console.log(err);
        if (errorFun != null && errorFun !== undefined) {
            errorFun();
        }
    });
}

