import React from "react";
import {doReject} from "../all/api";
import CommonModal from "../../../../component/CommonModal";
import RejectOrderForm from "./rejectOrderForm";

export default function RejectOrderInfo(props) {
    const rejectFormRef = React.createRef();
    if (props.orderInfo != null && props.open) {
        setTimeout(() => {
            rejectFormRef.current.setFieldsValue(props.orderInfo);
        }, 100);
    }

    function update() {
        rejectFormRef.current.validateFields().then(values => {
            doReject({
                id: values.id,
                remarks: values.remarks
            }, (data) => {
                clearInterval(rejectFormRef.current);
                props.setOpenRejectModel(false);
                props.init();
            })
        }).catch(e => {
            console.log(e)
        })

    }

    function handleCancel() {
        props.setOpenRejectModel(false);
    }

    return (
        <CommonModal
            title="驳回订单"
            open={props.open}
            onOk={() => update()}
            onCancel={() => handleCancel()}
            destroyOnClose={true}
            form={<RejectOrderForm formRef={rejectFormRef}/>}/>
    )
}