import {Form, Input, Radio} from "antd";
import React from "react";

const {TextArea} = Input;

export default function CompanyForm(props) {
    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };
    return (
        <div>
            <Form
                ref={props.formRef} {...layout}>
                <Form.Item
                    label="物流公司ID"
                    name="id"
                    hidden="true">
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="编码"
                    name="number">
                    <Input disabled placeholder="系统自动生成"/>
                </Form.Item>
                <Form.Item
                    label="物流公司"
                    name="name"
                    rules={[{required: true, message: '请输入物流公司'}]}>
                    <Input placeholder="请输入物流公司"/>
                </Form.Item>
                <Form.Item
                    label="快递100编码"
                    name="com"
                    rules={[{required: true, message: '请输入快递100编码'}]}>
                    <Input placeholder="请输入快递100编码"/>
                </Form.Item>
                <Form.Item
                    label="默认物流"
                    name="isDefault"
                    initialValue={0}
                    rules={[{required: true, message: '请选择默认物流'}]}>
                    <Radio.Group>
                        <Radio value={0}>否</Radio>
                        <Radio value={1}>是</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label="描述"
                    name="remarks">
                    <TextArea/>
                </Form.Item>
            </Form>
        </div>
    )
}