import React from "react";
import type {TabsProps} from 'antd';
import {Tabs} from "antd";
import BusinessData from "./businessData/businessData";
import PackageData from "./packageData/packageData";

export default function PageTab() {
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: '业务数据',
            children: <BusinessData/>,
        },
        {
            key: '2',
            label: '包裹数据',
            children: <PackageData/>,
        }
    ];

    return (
        <Tabs defaultActiveKey="1" items={items}/>
    )
}