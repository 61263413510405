import React from "react";
import type {TabsProps} from 'antd';
import {Tabs} from "antd";
import InboundInfo from "./inbound/inboundInfo";
import OutboundInfo from "./outbound/outboundInfo";
import CodeInfo from "./code/codeInfo";

export default function WarehousingTab() {
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: '商品入库',
            children: <InboundInfo/>,
        },
        {
            key: '2',
            label: '商品出库',
            children: <OutboundInfo/>,
        },

        {
            key: '3',
            label: '入库条码',
            children: <CodeInfo/>,
        }
    ];

    return (
        <Tabs defaultActiveKey="1" items={items}/>
    )
}