import styles from "./head.module.css"
import React from "react";
import {
    DesktopOutlined,
} from '@ant-design/icons'
import Avatar from "./avatar";
import NavigateComponent from "../../common/NavigateComponent";

export default function Head() {
    return (
        <div className={styles.header}>
            <div className={styles.logo}>
                <div className={styles.img}>{<DesktopOutlined/>}</div>
                <div className={styles.title}>尼欧服饰</div>
            </div>
            <NavigateComponent element={Avatar}/>
        </div>
    )
}