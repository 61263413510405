import React, {useEffect, useState} from "react";
import Constant from "../../../../util/constant";
import {Button, Form, Image, Input, Select, Space} from "antd";
import CommonTable from "../../../../component/CommonTable";
import Util from "../../../../util/params";
import {balanceApplyCheck, list} from "./api";

export default function BalanceApply() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(Constant.CURRENT_PAGE);
    const [pageSize, setPageSize] = useState(Constant.PAGE_SIZE);

    const [username, setUsername] = useState(null);
    const [checked, setChecked] = useState(null);
    const columns: ColumnsType<DataType> = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '账户',
            dataIndex: 'username',
            key: 'username',
            width: 250,
        },
        {
            title: '身份证正面照',
            dataIndex: 'idCardFrontImage',
            key: 'idCardFrontImage',
            render: idCardFrontImage => {
                if (!Util.isEmpty(idCardFrontImage)) {
                    return (
                        <Image
                            width={25}
                            height={20}
                            src={idCardFrontImage}/>
                    )
                }
            }
        },
        {
            title: '身份证反面照',
            dataIndex: 'idCardBackImage',
            key: 'idCardBackImage',
            render: idCardBackImage => {
                if (!Util.isEmpty(idCardBackImage)) {
                    return (
                        <Image
                            width={25}
                            height={20}
                            src={idCardBackImage}/>
                    )
                }
            }
        },
        {
            title: '人与店面合照',
            dataIndex: 'storefrontImage',
            key: 'storefrontImage',
            render: storefrontImage => {
                if (!Util.isEmpty(storefrontImage)) {
                    return (
                        <Image
                            width={25}
                            height={20}
                            src={storefrontImage}/>
                    )
                }
            }
        },
        {
            title: '状态',
            dataIndex: 'checkedText',
            key: 'checkedText',
        },
        {
            title: '创建时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: 170
        },
        {
            title: '创建人',
            dataIndex: 'creator',
            key: 'creator',
            width: 250,
        },
        {
            title: '最近修改时间',
            dataIndex: 'gmtUpdate',
            key: 'gmt_update',
            width: 170
        },
        {
            title: '最近修改人',
            dataIndex: 'modifier',
            key: 'modifier',
        },
        {
            title: '备注',
            dataIndex: 'remarks',
            key: 'remarks',
        }, {
            title: '操作',
            key: 'action',
            fixed: 'right',
            width: 75,
            render: (text, record) => (
                <Space size="small">
                    {
                        record.checked === 0 ? (<a onClick={() => {
                            balanceApplyCheck(record, () => {
                                init()
                            })
                        }}>信息确认</a>) : null
                    }
                </Space>
            )
        }
    ];
    columns.splice(0, 1);
    useEffect(() => {
        init(currentPage, pageSize);
    }, []);// eslint-disable-line
    function init() {
        list({
            username: username,
            checked: checked,
            currentPage: currentPage,
            pageSize: pageSize
        }, (data) => {
            setData(data.resData.result);
            setLoading(false);
            setTotal(data.resData.total);
        }, () => {
            setData(null);
            setLoading(false);
        })
    }

    function resetPage(currentPage, pageSize) {
        setCurrentPage(currentPage);
        setPageSize(pageSize);
    }

    return (
        <div>
            <Form layout='inline'>
                <Form.Item
                    label="账户"
                    name="username">
                    <Input placeholder="请输入账户" onChange={(e) => setUsername(e.target.value)}/>
                </Form.Item>
                <Form.Item
                    label="状态"
                    name="checked"
                    style={{width: 180}}>
                    <Select onChange={(e) => setChecked(e)}>
                        <Select.Option key={0}
                                       value={0}
                                       label={"未核对"}>未核对</Select.Option>
                        <Select.Option key={1}
                                       value={1}
                                       label={"已核对"}>已核对</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" onClick={() => {
                        setLoading(true);
                        init(Constant.CURRENT_PAGE, Constant.PAGE_SIZE);
                    }}>查询</Button>
                    <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                            onClick={() => {
                                setUsername(null);
                                setChecked(null);
                            }}>重置</Button>
                </Form.Item>
            </Form>
            <CommonTable
                columns={columns}
                rowKey={columns => columns.id}
                data={data}
                width={2000}
                loading={loading}
                total={total}
                currentPage={currentPage}
                pageSize={pageSize}
                initData={(currentPage, pageSize) => init(currentPage, pageSize)}
                resetPage={(currentPage, pageSize) => resetPage(currentPage, pageSize)}/>
        </div>
    )
}