import React from "react";
import {Form, Input, InputNumber} from "antd";
export default function RefundForm(props){
    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };
    return (
        <div>
            <Form
                ref={props.formRef} {...layout}>
                <Form.Item
                    label="订单ID"
                    name="orderId"
                    hidden="true">
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="退款金额"
                    name="refundAmount"
                    initialValue={props.refundAmount}
                    rules={[{required: true, message: '请输入退款金额'}]}>
                    <InputNumber placeholder="" min={0.01}
                                 style={{"width": 353}}
                                 step={0.01}/>
                </Form.Item>
            </Form>
        </div>
    )
}