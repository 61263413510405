import type {TabsProps} from "antd";
import {Tabs} from "antd";
import React from "react";
import InventoryInformation from "./information/inventoryInformation";
import InventoryChangeRecord from "./record/inventoryChangeRecord";

export default function InventoryTab() {
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: '库存信息',
            children: <InventoryInformation/>,
        },
        {
            key: '2',
            label: '库存变动记录',
            children: <InventoryChangeRecord/>,
        }
    ];

    return (
        <Tabs defaultActiveKey="1" items={items}/>
    )
}