import {DatePicker, Form, Input, Select} from "antd";
import React from "react";
import locale from "antd/es/date-picker/locale/zh_CN";
import 'dayjs/locale/zh-cn';
const {TextArea} = Input;
const {RangePicker} = DatePicker;
export default function VideoForm(props) {
    const layout = {
        labelCol: {span: 4},
        wrapperCol: {span: 20},
    };
    return (
        <div>
            <Form
                ref={props.formRef} {...layout}>
                <Form.Item
                    label="视频ID"
                    name="id"
                    hidden="true">
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="直播标题"
                    name="title"
                    rules={[{required: true, message: '请输入标题'}]}>
                    <Input placeholder="请输入标题"/>
                </Form.Item>
                <Form.Item
                    label="直播地址"
                    name="videoUrl"
                    rules={[{required: true, message: '请输入直播地址'}]}>
                    <Input placeholder="请输入直播地址"/>
                </Form.Item>
                <Form.Item
                    label="直播类型"
                    name="type"
                    rules={[{required: true, message: '请选择直播类型'}]}>
                    <Select
                        placeholder="请选择直播类型"
                        optionFilterProp="label">
                        {
                            props.typeList != null ?
                                props.typeList.map(item => {
                                    return (<Select.Option key={item.type}
                                                           value={item.type}
                                                           label={item.typeText}>{item.typeText}</Select.Option>)
                                }) : null
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    label="直播时间"
                    name="time"
                    rules={[{required: true, message: '请选择直播时间'}]}>
                    <RangePicker showTime locale={locale} onChange={(date, dateString) => {
                        props.setTimeStart(dateString[0]);
                        props.setTimeEnd(dateString[1]);
                    }}/>
                </Form.Item>
                <Form.Item
                    label="描述"
                    name="remarks">
                    <TextArea/>
                </Form.Item>
            </Form>
        </div>
    )
}