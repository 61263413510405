import React, {useState} from "react";
import {doUpdate} from "./api";
import CommonModal from "../../../../../component/CommonModal";
import FreightForm from "./freightForm";
import {message} from "antd";

export default function EditFreight(props) {
    const editFormRef = React.createRef();
    const [regions, setRegions] = useState(props.billingRule.regions);
    const [first, setFirst] = useState(props.billingRule.first);
    const [firstFee, setFirstFee] = useState(props.billingRule.firstFee);
    const [additional, setAdditional] = useState(props.billingRule.additional);
    const [additionalFee, setAdditionalFee] = useState(props.billingRule.additionalFee);

    function update() {
        if (props.type !== 2) {
            if (regions === undefined
                || regions === null
                || regions.length === 0) {
                message.error("请选择配送区域")
            } else {
                let billingRule = {};
                billingRule.first = first;
                billingRule.firstFee = firstFee;
                billingRule.additional = additional;
                billingRule.additionalFee = additionalFee;
                billingRule.regions = regions;
                editFormRef.current.validateFields().then(values => {
                    doUpdate({
                        id: values.id,
                        ruleId: values.ruleId,
                        name: values.name,
                        type: values.type,
                        billingRule: billingRule,
                        remarks: values.remarks
                    }, (data) => {
                        clearInterval(editFormRef.current);
                        props.setOpenEditModel(false);
                        props.init();
                    })
                }).catch(e => {
                    console.log(e)
                })
            }
        } else {
            let billingRule = {};
            billingRule.first = 0;
            billingRule.firstFee = 0;
            billingRule.additional = 0;
            billingRule.additionalFee = 0;
            billingRule.regions = null;
            editFormRef.current.validateFields().then(values => {
                doUpdate({
                    id: values.id,
                    ruleId: values.ruleId,
                    name: values.name,
                    type: values.type,
                    billingRule: billingRule,
                    remarks: values.remarks
                }, (data) => {
                    clearInterval(editFormRef.current);
                    props.setOpenEditModel(false);
                    props.init();
                })
            }).catch(e => {
                console.log(e)
            })
        }
    }

    function handleCancel() {
        props.setOpenEditModel(false);
    }

    return (
        <CommonModal
            title="编辑运费模版"
            open={props.open}
            width={800}
            onOk={() => update()}
            onCancel={() => handleCancel()}
            destroyOnClose={true}
            form={<FreightForm formRef={editFormRef}
                               first={props.billingRule.first}
                               setFirst={setFirst}
                               firstFee={props.billingRule.firstFee}
                               setFirstFee={setFirstFee}
                               additional={props.billingRule.additional}
                               setAdditional={setAdditional}
                               additionalFee={props.billingRule.additionalFee}
                               setAdditionalFee={setAdditionalFee}
                               regions={props.billingRule.regions}
                               setRegions={setRegions}
                               freight={props.freight}
                               type={props.type}
                               setType={props.setType}/>}
        />
    )
}