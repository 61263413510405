import React, {useState} from "react";
import {doUpdate} from "./api";
import CommonModal from "../../../../../component/CommonModal";
import CategoryForm from "./categoryForm";

export default function EditCategory(props) {
    const editFormRef = React.createRef();
    const [parentId, setParentId] = useState(null);


    function update() {
        editFormRef.current.validateFields().then(values => {
            doUpdate({
                id: values.id,
                parentId: parentId,
                name: values.name,
                remarks: values.remarks
            }, (data) => {
                props.setOpenEditModel(false);
                setParentId(null);
                props.init();
            })
        }).catch(e => {
            console.log(e)
        })

    }

    function handleCancel() {
        props.setOpenEditModel(false);
        setParentId(null);
    }

    return (
        <CommonModal
            title="编辑类别"
            open={props.open}
            onOk={() => update()}
            onCancel={() => handleCancel()}
            destroyOnClose={true}
            form={<CategoryForm formRef={editFormRef}
                                category={props.category}
                                categoryList={props.categoryList}
                                setParentId={setParentId}/>}/>
    )
}