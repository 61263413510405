import React, {useEffect, useState} from "react";
import Constant from "../../../../../util/constant";
import CommonTable from "../../../../../component/CommonTable";
import {doList} from "./api"
import {Button, Col, Form, Input, Row, Select, Space} from "antd";
import {doList as doWarehouseList} from "../../../warehouse/information/warehouse/api";
import {doList as doCategoryList} from "../../information/category/api";
import {doList as doBrandList} from "../../information/brand/api";
import EditInventoryInfo from "./editInventoryInfo";

export default function InventoryInformation() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(Constant.CURRENT_PAGE);
    const [pageSize, setPageSize] = useState(Constant.PAGE_SIZE);

    const [productName, setProductName] = useState(null);
    const [productNumber, setProductNumber] = useState(null);
    const [categoryId, setCategoryId] = useState(null);
    const [warehouseId, setWarehouseId] = useState(null);
    const [brandId, setBrandId] = useState(null);
    const [warehouseList, setWarehouseList] = useState(null);
    const [categoryList, setCategoryList] = useState(null);
    const [brandList, setBrandList] = useState(null);
    const [openEditModel, setOpenEditModel] = useState(false);
    const [inventory, setInventory] = useState(null);

    const columns = [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '仓库编码',
            dataIndex: 'warehouseNumber',
            key: 'warehouseNumber',
        },
        {
            title: '仓库名称',
            dataIndex: 'warehouseName',
            key: 'warehouseName',
        },
        {
            title: 'SKU码',
            dataIndex: 'productNumber',
            key: 'productNumber',
        },
        {
            title: '商品名称',
            dataIndex: 'productName',
            key: 'productName',
        },
        {
            title: '商品颜色',
            dataIndex: 'productColor',
            key: 'productColor',
        },
        {
            title: '商品大小',
            dataIndex: 'productSize',
            key: 'productSize',
        },
        {
            title: '商品规格',
            dataIndex: 'productSpec',
            key: 'productSpec',
        },
        {
            title: '库存总量',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: '商品类目',
            dataIndex: 'categoryName',
            key: 'categoryName',
        },
        {
            title: '商品品牌',
            dataIndex: 'brandName',
            key: 'brandName',
        },
        {
            title: '操作',
            key: 'action',
            fixed: 'right',
            width: 75,
            render: (text, record) => (
                <Space size="small" style={{"float": "right"}}>
                    <a onClick={() => edit(record)}>修改库存</a>
                </Space>
            ),
        },
    ];
    columns.splice(0, 1)
    useEffect(() => {
        init(currentPage, pageSize);
        initWarehouse();
        initCategory();
        initBrand();
    }, []);// eslint-disable-line
    function init(current, page) {
        doList({
            productName: productName,
            productNumber: productNumber,
            categoryId: categoryId,
            warehouseId: warehouseId,
            brandId: brandId,
            currentPage: current === undefined ? currentPage : current,
            pageSize: page === undefined ? pageSize : page
        }, (data) => {
            setData(data.resData.result);
            setLoading(false);
            setTotal(data.resData.total);
        }, () => {
            setData(null);
            setLoading(false);
        })
    }

    function initWarehouse() {
        doWarehouseList({
            status: 0
        }, (data) => {
            setWarehouseList(data.resData.result)
        }, () => {
            setWarehouseList(null);
        })
    }

    function initCategory() {
        doCategoryList({status: 0}, (data) => {
            setCategoryList(data.resData.result);
        }, () => {
            setCategoryList(null);
        });
    }

    function initBrand() {
        doBrandList({status: 0}, (data) => {
            setBrandList(data.resData.result);
        }, () => {
            setBrandList(null);
        });
    }

    function resetPage(currentPage, pageSize) {
        setCurrentPage(currentPage);
        setPageSize(pageSize);
    }

    function edit(record) {
        setInventory(record);
        setOpenEditModel(true);
    }

    return (
        <div>
            <Form>
                <Row gutter={[16, 0]}>
                    <Col span={6}>
                        <Form.Item
                            label="SKU码"
                            name="productNumber">
                            <Input placeholder="请输入SKU码" onChange={(e) => setProductNumber(e.target.value)}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="商品名称"
                            name="productName">
                            <Input placeholder="请输入商品名称" onChange={(e) => setProductName(e.target.value)}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="仓库"
                            name="warehouseId">
                            <Select showSearch
                                    placeholder="请选择仓库"
                                    allowClear
                                    optionFilterProp="label"
                                    onChange={(value, option) => {
                                        if (option !== undefined) {
                                            setWarehouseId(option.key);
                                        } else {
                                            setWarehouseId(null);
                                        }
                                    }}>
                                {
                                    warehouseList != null ?
                                        warehouseList.map(item => {
                                            return (<Select.Option key={item.id}
                                                                   value={item.id}
                                                                   label={item.name}>{item.name}</Select.Option>)
                                        }) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="商品类目"
                            name="categoryId">
                            <Select
                                placeholder="请选择商品类目"
                                showSearch
                                allowClear
                                optionFilterProp="label"
                                onChange={(value, option) => {
                                    if (option !== undefined) {
                                        setCategoryId(option.key);
                                    } else {
                                        setCategoryId(null);
                                    }
                                }}>
                                {
                                    categoryList != null ?
                                        categoryList.map(item => {
                                            return (<Select.Option key={item.id}
                                                                   value={item.id}
                                                                   label={item.name}>{item.name}</Select.Option>)
                                        }) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 0]}>
                    <Col span={6}>
                        <Form.Item
                            label="商品品牌"
                            name="brandId">
                            <Select
                                placeholder="请选择商品品牌"
                                showSearch
                                allowClear
                                optionFilterProp="label"
                                onChange={(value, option) => {
                                    if (option !== undefined) {
                                        setBrandId(option.key);
                                    } else {
                                        setBrandId(null);
                                    }
                                }}>
                                {
                                    brandList != null ?
                                        brandList.map(item => {
                                            return (<Select.Option key={item.id}
                                                                   value={item.id}
                                                                   label={item.name}>{item.name}</Select.Option>)
                                        }) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item>
                            <Button type="primary" onClick={() => {
                                setLoading(true);
                                init(Constant.CURRENT_PAGE, Constant.PAGE_SIZE);
                            }}>查询</Button>
                            <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                                    onClick={() => {
                                        setProductName(null);
                                        setProductNumber(null);
                                        setCategoryId(null);
                                        setWarehouseId(null);
                                        setBrandId(null);
                                    }}>重置</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <CommonTable
                columns={columns}
                rowKey={columns => columns.warehouseNumber + columns.productNumber}
                data={data}
                width={1500}
                loading={loading}
                total={total}
                currentPage={currentPage}
                pageSize={pageSize}
                initData={(currentPage, pageSize) => init(currentPage, pageSize)}
                resetPage={(currentPage, pageSize) => resetPage(currentPage, pageSize)}/>
            <EditInventoryInfo open={openEditModel}
                               setOpenEditModel={setOpenEditModel}
                               inventory={inventory}
                               init={init}/>
        </div>
    )
}