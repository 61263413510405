import React, {useState} from "react";
import CommonModal from "../../../../../component/CommonModal";
import InboundForm from "./inboundForm";
import {doAdd} from "./api";
import {message} from "antd";

export default function AddInbound(props) {
    const addFormRef = React.createRef();
    const [addSubmitLoading, setAddSubmitLoading] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [quantity, setQuantity] = useState(new Map());
    const [eachQuantity, setEachQuantity] = useState(new Map());

    function add() {
        if (!addSubmitLoading) {
            addFormRef.current.validateFields().then(values => {
                setAddSubmitLoading(true)
                if (dataSource.length > 0) {
                    let productList = [];
                    for (let i = 0; i < dataSource.length; i++) {
                        let product = {};
                        product.productId = dataSource[i].productId.props.value;
                        product.productName = dataSource[i].productName.props.value;
                        product.productSku = dataSource[i].productNumber.props.value;
                        product.productColor = dataSource[i].productColor.props.value;
                        product.productSize = dataSource[i].productSize.props.value;
                        product.productSpec = dataSource[i].productSpec.props.value;
                        product.quantity = quantity.get(dataSource[i].number.props.value);
                        product.eachQuantity = eachQuantity.get(dataSource[i].number.props.value);
                        productList[i] = product;
                    }
                    doAdd({
                        warehouseId: values.warehouseId,
                        positionId: values.positionId,
                        capacity: values.capacity,
                        warehousingProductDTOList: productList,
                        remarks: values.remarks
                    }, (data) => {
                        setAddSubmitLoading(false);
                        props.setOpenAddModel(false);
                        props.init();
                        setDataSource([]);
                        setQuantity(new Map());
                        setEachQuantity(new Map());
                    }, () => {
                        setAddSubmitLoading(false);
                    })
                } else {
                    message.error("请选择入库产品！")
                }
            }).catch(e => {
                console.log(e);
            })
        }
    }

    function handleCancel() {
        setDataSource([]);
        setQuantity(new Map());
        setEachQuantity(new Map());
        props.setOpenAddModel(false);
    }

    return (
        <CommonModal
            title="新增入库"
            width={1000}
            open={props.open}
            onOk={() => add()}
            onCancel={() => handleCancel()}
            destroyOnClose={true}
            confirmLoading={addSubmitLoading}
            form={<InboundForm formRef={addFormRef}
                               add={add}
                               dataSource={dataSource}
                               setDataSource={setDataSource}
                               quantity={quantity}
                               setQuantity={setQuantity}
                               eachQuantity={eachQuantity}
                               setEachQuantity={setEachQuantity}/>}/>
    )
}