import {useNavigate, useLocation, useParams, useSearchParams} from "react-router-dom";

function NavigateComponent(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const searchParams = useSearchParams();
    return (
        <div>
            <props.element navigate={navigate} location={location} params={params} searchParms={searchParams}/>
        </div>
    )
}

export default NavigateComponent;