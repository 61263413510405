import {Form, Input, Select} from "antd";
import React from "react";

const {TextArea} = Input;
export default function ProcessForm(props) {
    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };
    return (
        <div>
            <Form
                ref={props.formRef} {...layout}>
                <Form.Item
                    label="审核结果"
                    name="processResult"
                    rules={[{required: true, message: '请选择结果'}]}>
                    <Select
                        options={[
                            {
                                key: '1',
                                value: '1',
                                label: '通过',
                            },
                            {
                                key: '2',
                                value: '2',
                                label: '不通过',
                            }
                        ]}
                    />
                </Form.Item>
                <Form.Item
                    label="审核备注"
                    name="processRemarks">
                    <TextArea/>
                </Form.Item>
            </Form>
        </div>
    )
}