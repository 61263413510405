import {ExclamationCircleOutlined} from '@ant-design/icons';
import React from "react";
import HttpUtil from "../../../../../util/httpUtil";

/**
 * 获取仓库状态
 * @param successFun
 * @param errorFun
 */
export const doInitStatus = function (successFun, errorFun) {
    HttpUtil.doGet(
        "/warehouse/warehousing/listStatus",
        null,
        successFun, errorFun);
}

/**
 * 获取入库信息列表
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doList = function (params, successFun, errorFun) {
    HttpUtil.doGet(
        "/warehouse/warehousing/list",
        params,
        successFun, errorFun);
}
/**
 * 删除入库信息
 * @param record
 * @param successFun
 * @param errorFun
 */
export const doDel = function (record, successFun, errorFun) {
    HttpUtil.doDelete(<ExclamationCircleOutlined/>, "删除入库",
        "确认删除" + record.productName + "入库信息？",
        "/warehouse/warehousing/delete/" + record.id, null, successFun, errorFun);
}
/**
 * 新增入库信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doAdd = function (params, successFun, errorFun) {
    HttpUtil.doPost("/warehouse/warehousing/add",
        params, successFun, errorFun);
}
/**
 * 获取商品信息
 * @param productCodeNumber
 * @param successFun
 * @param errorFun
 */
export const getProductInfo = function (productCodeNumber, successFun, errorFun) {
    HttpUtil.doGet(
        "/warehouse/warehousing/getProductInfo/" + productCodeNumber,
        null,
        successFun, errorFun);
}
/**
 * 入库审核信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doProcess = function (params, successFun, errorFun) {
    HttpUtil.doPost("/warehouse/warehousing/process",
        params, successFun, errorFun);
}