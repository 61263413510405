import type {TabsProps} from "antd";
import {Tabs} from "antd";
import React from "react";
import ProductInfo from "./product/productInfo";
import Sold from "./sold/sold";

export default function ManageTab() {
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: '商品SKU',
            children: <ProductInfo/>,
        },
        {
            key: '2',
            label: '商品上架',
            children: <Sold/>,
        }
    ];

    return (
        <Tabs defaultActiveKey="1" items={items}/>
    )
}