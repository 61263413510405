import HttpUtil from "../../../../../util/httpUtil";

/**
 * 获取仓库状态
 * @param successFun
 * @param errorFun
 */
export const doInitStatus = function (successFun, errorFun) {
    HttpUtil.doGet(
        "/warehouse/outbound/listStatus",
        null,
        successFun, errorFun);
}

/**
 * 获取入库信息列表
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doList = function (params, successFun, errorFun) {
    HttpUtil.doGet(
        "/warehouse/outbound/list",
        params,
        successFun, errorFun);
}