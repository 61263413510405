import React from "react";
import {Form, Input} from "antd";

export default function EditInventoryForm(props) {
    const layout = {
        labelCol: {span: 4},
        wrapperCol: {span: 20},
    };
    return (
        <div>
            <Form
                ref={props.formRef} {...layout}>
                <Form.Item
                    label="ID"
                    name="id"
                    hidden="true">
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="库存总量"
                    name="quantity"
                    rules={[{required: true, message: '请输入库存总量'}]}>
                    <Input placeholder="请输入库存总量"/>
                </Form.Item>
            </Form>
        </div>
    )
}