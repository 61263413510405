import React from "react";
import CommonModal from "../../../../../component/CommonModal";
import {Form, Image} from "antd";

export default function PreviewBarcode(props) {
    const previewBarcodeFormRef = React.createRef();

    function handleCancel() {
        props.setOpenPreviewBarcodeModel(false);
    }

    return (
        <CommonModal
            title="预览条形码"
            open={props.open}
            onOk={() => handleCancel()}
            onCancel={() => handleCancel()}
            destroyOnClose={true}
            width={500}
            form={
                <Form
                    ref={previewBarcodeFormRef}>
                    <Form.Item>
                        <Image
                            src={props.previewBarcodeImageUrl}
                        />
                    </Form.Item>
                </Form>
            }/>
    )
}