import {Modal, Tooltip, Row, Form, Input, Col, Radio, InputNumber, Upload, TreeSelect, Select, message} from "antd";
import React, {useEffect, useState} from "react";
import {QuestionCircleOutlined, PlusOutlined} from '@ant-design/icons';
import {doList as doFreightTemplateList} from "../../../shop/logistics/freight/api";
import Content from "../../../../../util/constant";
import {doDeleteFile} from "../../../../../common/api";

export default function BaseForm(props) {
    const [freightTemplateList, setFreightTemplateList] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [previewImageVisible, setPreviewImageVisible] = useState(null);
    const [previewImageTitle, setPreviewImageTitle] = useState(null);
    const [imageFileList, setImageFileList] = useState([]);
    const [videoFileList, setVideoFileList] = useState([]);

    useEffect(() => {
        initFreightTemplate();
    }, []);// eslint-disable-line
    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    function initFreightTemplate() {
        doFreightTemplateList({status: 0}, (data) => {
            setFreightTemplateList(data.resData.result);
        }, () => {
            setFreightTemplateList(null);
        });
    }

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    function handlePreviewImageCancel() {
        setPreviewImageVisible(false);
    }

    return (
        <div>
            <Form
                ref={props.formRef} {...layout}>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="上架编码"
                            name="number">
                            <Input disabled placeholder="系统自动生成"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="商品标题"
                            name="title"
                            rules={[{required: true, message: '请输入商品标题'}]}>
                            <Input disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="上架分类"
                            name="shopCategoryIds"
                            rules={[{required: true, message: '请输入商品标题'}]}
                            initialValue={(props.shopCategoryIds === undefined
                                || props.shopCategoryIds === null) ? [] : props.shopCategoryIds}>
                            <TreeSelect
                                treeCheckable={true}
                                multiple={true}
                                showCheckedStrategy={TreeSelect.SHOW_ALL}
                                showSearch
                                treeNodeFilterProp="title"
                                style={{width: '100%'}}
                                dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                allowClear
                                treeCheckStrictly={true}
                                treeData={props.categoryList}
                                disabled={(props.view === undefined || props.view === null) ? false : props.view}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="运费模版"
                            name="freightTemplateId"
                            rules={[{required: true, message: '请选择运费模版'}]}>
                            <Select showSearch={true}
                                    disabled={(props.view === undefined || props.view === null) ? false : props.view}
                                    optionFilterProp="label">
                                {
                                    freightTemplateList != null ?
                                        freightTemplateList.map(item => {
                                            return (<Select.Option key={item.id}
                                                                   value={item.id}
                                                                   label={item.name}>{item.name}</Select.Option>)
                                        }) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="商品状态"
                            name="status"
                            initialValue={0}
                            rules={[{required: true, message: '请选择商品状态'}]}
                            disabled={(props.view === undefined || props.view === null) ? false : props.view}>
                            <Radio.Group>
                                <Radio value={0}>上架</Radio>
                                <Radio value={1}>下架</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="商品排序"
                            name="orderNumber"
                            initialValue={100}
                            rules={[{required: true, message: '请输入商品排序'}]}>
                            <InputNumber min={1} max={1000}
                                         addonAfter={
                                             <Tooltip title="数字越小，排序越靠前">
                                                 <QuestionCircleOutlined/>
                                             </Tooltip>
                                         }
                                         style={{"width": "150px"}}
                                         disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="商品图片"
                            name="imageFiles"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                            rules={[{required: true, message: '请上传商品图片'}]}>
                            <Upload
                                action={Content.BASE_URL + "/uploadFile"}
                                name='file'
                                withCredentials={true}
                                accept='.png,.jpg,.jpeg,.tiff'
                                listType="picture-card"
                                className="avatar-uploader"
                                fileList={imageFileList}
                                maxCount={5}
                                multiple={true}
                                disabled={(props.view === undefined || props.view === null) ? false : props.view}
                                beforeUpload={(file) => {
                                    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
                                        || file.type === 'image/jpg' || file.type === 'image/tiff';
                                    const isLt2M = file.size / 1024 / 1024 < 20;
                                    return new Promise((resolve, reject) => {
                                        if (!isJpgOrPng) {
                                            message.error('文件格式不正常！');
                                            reject();
                                        } else if (!isLt2M) {
                                            message.error('文件大小最大20M！');
                                            reject();
                                        } else {
                                            resolve(file);
                                        }
                                    });
                                }}
                                onChange={(options) => {
                                    const {fileList} = options;
                                    let imageList = [];
                                    for (let i = 0; i < fileList.length; i++) {
                                        if (fileList[i].response !== undefined) {
                                            imageList[i] = fileList[i].response;
                                        } else if (fileList[i].response === undefined && fileList[i].url !== undefined) {
                                            imageList[i] = fileList[i].url;
                                        }

                                    }
                                    if (imageList.length > 0) {
                                        setImageFileList(imageList);
                                        props.formRef.current.setFieldsValue({
                                            imagesUrl: imageList
                                        });
                                    }
                                }}
                                onPreview={async (file) => {
                                    if (!file.url && !file.preview) {
                                        file.preview = await getBase64(file.originFileObj);
                                    }
                                    setPreviewImage(file.url || file.preview);
                                    setPreviewImageVisible(true);
                                    setPreviewImageTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
                                }}
                                onRemove={(file) => {
                                    if (file.response !== undefined) {
                                        doDeleteFile({
                                            url: file.response
                                        }, () => {
                                            let imageFiles = imageFileList;
                                            imageFiles = imageFiles.filter(item => item !== file.response);
                                            setImageFileList(imageFiles);
                                            props.formRef.current.setFieldsValue({
                                                imagesUrl: imageFiles
                                            });
                                        }, null);
                                    }
                                }}>
                                {
                                    imageFileList.length >= 5 ? null : (
                                        <div>
                                            <PlusOutlined style={{"fontSize": "32px", "color": "#cccccc"}}/>
                                            <div style={{marginTop: 8}}>上传图片</div>
                                        </div>
                                    )
                                }
                            </Upload>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="商品视频"
                            name="videoFile"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}>
                            <Upload
                                action={Content.BASE_URL + "/uploadFile"}
                                name='file'
                                accept='.avi,.mp4,.wmv,.mpeg,.mpg,.mpg'
                                listType="picture-card"
                                className="avatar-uploader"
                                fileList={videoFileList}
                                withCredentials={true}
                                maxCount={2}
                                disabled={(props.view === undefined || props.view === null) ? false : props.view}
                                beforeUpload={(file) => {
                                    const isJpgOrPng = file.type === 'video/mp4' || file.type === 'video/avi'
                                        || file.type === 'image/wmv' || file.type === 'image/mpeg'
                                        || file.type === 'image/mpg' || file.type === 'image/mpg';
                                    const isLt2M = file.size / 1024 / 1024 < 300;
                                    return new Promise((resolve, reject) => {
                                        if (!isJpgOrPng) {
                                            message.error('文件格式不正常！');
                                            reject();
                                        } else if (!isLt2M) {
                                            message.error('文件大小最大300M！');
                                            reject();
                                        } else {
                                            resolve(file);
                                        }
                                    });
                                }}
                                onChange={(options) => {
                                    const {fileList} = options;
                                    let videoList = [];
                                    for (let i = 0; i < fileList.length; i++) {
                                        if (fileList[i].response !== undefined) {
                                            videoList[i] = fileList[i].response;
                                        } else if (fileList[i].response === undefined && fileList[i].url !== undefined) {
                                            videoList[i] = fileList[i].url;
                                        }

                                    }
                                    if (videoList.length > 0) {
                                        setVideoFileList(videoList);
                                        props.formRef.current.setFieldsValue({
                                            videoUrl: videoList
                                        });
                                    }
                                }}
                                onRemove={(file) => {
                                    if (file.response !== undefined) {
                                        doDeleteFile({
                                            url: file.response
                                        }, () => {
                                            let videoFiles = videoFileList;
                                            videoFiles = videoFiles.filter(item => item !== file.response);
                                            setVideoFileList(videoFiles);
                                            props.formRef.current.setFieldsValue({
                                                videoUrl: videoFiles
                                            });
                                        }, null);
                                    }
                                }}>
                                {
                                    videoFileList.length >= 2 ? null : (
                                        <div>
                                            <PlusOutlined style={{"fontSize": "32px", "color": "#cccccc"}}/>
                                            <div style={{marginTop: 8}}>上传视频</div>
                                        </div>
                                    )
                                }
                            </Upload>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    label="商品图片"
                    name="imagesUrl"
                    hidden="true">
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="商品视频"
                    name="videoUrl"
                    hidden="true">
                    <Input/>
                </Form.Item>
            </Form>
            <Modal
                open={previewImageVisible}
                title={previewImageTitle}
                footer={null}
                onCancel={() => handlePreviewImageCancel()}>
                <img alt="example" style={{width: '100%'}} src={previewImage}/>
            </Modal>
        </div>
    )
}