import React from "react";
import {doUpdate} from "./api";
import CommonModal from "../../../../../component/CommonModal";
import AgreementForm from "./agreementForm";

export default function EditAgreement(props) {
    const editFormRef = React.createRef();


    function update() {
        editFormRef.current.validateFields().then(values => {
            doUpdate({
                id: values.id,
                title: values.title,
                type: values.type,
                content: values.content,
                remarks: values.remarks
            }, (data) => {
                props.setOpenEditModel(false);
                props.init();
            }, () => {
            })
        }).catch(e => {
            console.log(e);
        })
    }

    function handleCancel() {
        props.setOpenEditModel(false);
    }

    return (
        <CommonModal
            title="编辑协议"
            width={1000}
            open={props.open}
            onOk={() => update()}
            onCancel={() => handleCancel()}
            destroyOnClose={true}
            form={<AgreementForm formRef={editFormRef} agreement={props.agreement}/>}/>
    )
}