import {Button, Col, Form, Input, Row, Select, Space, DatePicker} from "antd";
import React, {useEffect, useState} from "react";
import Constant from "../../../../../util/constant";
import CommonTable from "../../../../../component/CommonTable";
import {doList, doInitStatus} from "./api";
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'dayjs/locale/zh-cn';
import AddInbound from "./addInbound";
import ViewInboundInfo from "./viewInboundInfo";
import Process from "./process";

const {RangePicker} = DatePicker;

export default function InboundInfo() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(Constant.CURRENT_PAGE);
    const [pageSize, setPageSize] = useState(Constant.PAGE_SIZE);

    const [productName, setProductName] = useState(null);
    const [productNumber, setProductNumber] = useState(null);
    const [number, setNumber] = useState(null);
    const [warehousingStartDate, setWarehousingStartDate] = useState(null);
    const [warehousingEndDate, setWarehousingEndDate] = useState(null);
    const [warehouseName, setWarehouseName] = useState(null);
    const [positionName, setPositionName] = useState(null);
    const [user, setUser] = useState(null);
    const [status, setStatus] = useState(null);
    const [openViewModel, setOpenViewModel] = useState(false);
    const [openAddModel, setOpenAddModel] = useState(false);
    const [openProcessModel, setOpenProcessModel] = useState(false);
    const [warehousingInfo, setWarehousingInfo] = useState(null);
    const [statusList, setStatusList] = useState(null);
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 50,
        },
        {
            title: '编码',
            dataIndex: 'number',
            key: 'number',
        },
        {
            title: '商品名称',
            dataIndex: 'productName',
            key: 'productName',
        },
        {
            title: '商品颜色',
            dataIndex: 'productColor',
            key: 'productColor',
        },
        {
            title: '商品大小',
            dataIndex: 'productSize',
            key: 'productSize',
        },
        {
            title: '商品规格',
            dataIndex: 'productSpec',
            key: 'productSpec',
        },
        {
            title: 'SKU编码',
            dataIndex: 'productNumber',
            key: 'productNumber',
        },
        {
            title: '仓库',
            dataIndex: 'warehouseName',
            key: 'warehouseName',
        },
        {
            title: '仓位',
            dataIndex: 'positionName',
            key: 'positionName',
        },
        {
            title: '入库数量',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: '状态',
            dataIndex: 'statusText',
            key: 'statusText',
        },
        {
            title: '描述',
            dataIndex: 'remarks',
            key: 'remarks',
        },
        {
            title: '单据时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: 170,
        },
        {
            title: '操作人',
            dataIndex: 'creator',
            key: 'creator',
        },
        {
            title: '最近修改时间',
            dataIndex: 'gmtUpdate',
            key: 'gmtUpdate',
            width: 170,
        },
        {
            title: '最近修改人',
            dataIndex: 'modifier',
            key: 'modifier',
        },
        {
            title: '操作',
            key: 'action',
            fixed: 'right',
            width: 45,
            render: (text, record) => (
                <Space size="small">
                    <a onClick={() => view(record)}>查看</a>
                </Space>
            ),
        },
    ];
    columns.splice(0, 1)
    useEffect(() => {
        init(currentPage, pageSize);
        initStatus();
    }, []);// eslint-disable-line
    function init(current, page) {
        doList({
            number: number,
            productName: productName,
            productNumber: productNumber,
            status: status,
            warehouseName: warehouseName,
            positionName: positionName,
            user: user,
            warehousingStartDate: warehousingStartDate,
            warehousingEndDate: warehousingEndDate,
            currentPage: current === undefined ? currentPage : current,
            pageSize: page === undefined ? pageSize : page
        }, (data) => {
            setData(data.resData.result);
            setLoading(false);
            setTotal(data.resData.total);
        }, () => {
            setData(null);
            setLoading(false);
        })
    }

    function initStatus() {
        doInitStatus((data) => {
            setStatusList(data.resData);
        }, () => {
            setStatusList(null);
        })
    }

    function view(record) {
        setWarehousingInfo(record);
        setOpenViewModel(record);
    }

    function add() {
        setOpenAddModel(true);
    }
    function resetPage(current, page) {
        setCurrentPage(current);
        setPageSize(page);
    }

    return (
        <div>
            <Form>
                <Row gutter={[16, 0]}>
                    <Col span={6}>
                        <Form.Item
                            label="单据编号"
                            name="number">
                            <Input placeholder="请输入单据编号" onChange={(e) => setNumber(e.target.value)}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="商品名称"
                            name="productName">
                            <Input placeholder="请输入商品名称" onChange={(e) => setProductName(e.target.value)}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="SKU码"
                            name="productNumber">
                            <Input placeholder="请输入SKU码" onChange={(e) => setProductNumber(e.target.value)}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="单据日期"
                            name="warehousingDate">
                            <RangePicker locale={locale} onChange={(date, dateString) => {
                                setWarehousingStartDate(dateString[0]);
                                setWarehousingEndDate(dateString[1]);
                            }}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 0]}>
                    <Col span={6}>
                        <Form.Item
                            label="仓库名称"
                            name="warehouseName">
                            <Input placeholder="请输入仓库名称" onChange={(e) => setWarehouseName(e.target.value)}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="仓位名称"
                            name="positionName">
                            <Input placeholder="请输入仓位名称" onChange={(e) => setPositionName(e.target.value)}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="操作人"
                            name="user">
                            <Input placeholder="请输入操作人" onChange={(e) => setUser(e.target.value)}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="入库状态"
                            name="status">
                            <Select onChange={(value, option) => {
                                setStatus(option.key)
                            }}>
                                {
                                    statusList != null ?
                                        statusList.map(item => {
                                            return (<Select.Option key={item.status}
                                                                   value={item.status}>{item.statusText}</Select.Option>)
                                        }) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 0]}>
                    <Col span={6}>
                        <Form.Item>
                            <Button type="primary" onClick={() => {
                                setLoading(true);
                                init(Constant.CURRENT_PAGE, Constant.PAGE_SIZE);
                            }}>查询</Button>
                            <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                                    onClick={() => {
                                        setProductName(null);
                                        setProductNumber(null);
                                        setWarehousingStartDate(null);
                                        setWarehousingEndDate(null);
                                        setWarehouseName(null);
                                        setPositionName(null);
                                        setUser(null);
                                        setNumber(null);
                                        setStatus(null);
                                    }}>重置</Button>
                            <Button type="primary" style={{'marginLeft': '10px'}} onClick={() => add()}>新增入库</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <CommonTable
                columns={columns}
                rowKey={columns => columns.id}
                data={data}
                width={2000}
                loading={loading}
                total={total}
                currentPage={currentPage}
                pageSize={pageSize}
                initData={(currentPage, pageSize) => init(currentPage, pageSize)}
                resetPage={(currentPage, pageSize) => resetPage(currentPage, pageSize)}/>
            <AddInbound open={openAddModel} setOpenAddModel={setOpenAddModel} init={init}/>
            <ViewInboundInfo open={openViewModel}
                             setOpenViewModel={setOpenViewModel}
                             warehousingInfo={warehousingInfo}/>
            <Process open={openProcessModel}
                     setOpenProcessModel={setOpenProcessModel}
                     warehousingInfo={warehousingInfo}
                     init={init}/>
        </div>
    )
}