import React from "react";
import CommonModal from "../../../../../component/CommonModal";
import ProcessForm from "./processForm";
import {doProcess} from "./api";

export default function Process(props) {
    const processFormRef = React.createRef();

    function handleCancel() {
        props.setOpenProcessModel(false);
    }

    function process() {
        processFormRef.current.validateFields().then(values => {
            doProcess({
                id: props.warehousingInfo.id,
                status: values.processResult,
                processRemarks: values.processRemarks,
            }, (data) => {
                props.setOpenProcessModel(false);
                props.init();
            })
        }).catch(e => {
            console.log(e);
        })
    }

    return (
        <CommonModal
            title="入库审核"
            open={props.open}
            onOk={() => process()}
            onCancel={() => handleCancel()}
            destroyOnClose={true}
            width={500}
            form={<ProcessForm formRef={processFormRef}/>}/>
    )
}