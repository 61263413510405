import React, {useState} from "react";
import {doUpdate} from "./api";
import CommonModal from "../../../../../component/CommonModal";
import ProductForm from "./productForm";

export default function EditProduct(props) {
    const editFormRef = React.createRef();
    const [categoryId, setCategoryId] = useState(null);

    function update() {
        editFormRef.current.validateFields().then(values => {
            doUpdate({
                id: values.id,
                name: values.name,
                categoryId: categoryId,
                supplierId: values.supplierId,
                brandId: values.brandId,
                //image: values.imageFiles.length > 0 ? values.imageFiles[0].response : null,
                spec: values.spec,
                weight: values.weight,
                color: values.color,
                size: values.size,
                unit: values.unit,
                procurePrice: values.procurePrice,
                salePrice: values.salePrice,
                retailPrice: values.retailPrice,
                remarks: values.remarks
            }, (data) => {
                props.setOpenEditModel(false);
                setCategoryId(null);
                props.init();
            })
        }).catch(e => {
            console.log(e)
        })

    }

    function handleCancel() {
        props.setOpenEditModel(false);
        setCategoryId(null);
    }

    return (
        <CommonModal
            title="编辑SKU"
            width={750}
            open={props.open}
            onOk={() => update()}
            onCancel={() => handleCancel()}
            destroyOnClose={true}
            form={<ProductForm formRef={editFormRef}
                               categoryList={props.categoryList}
                               supplierList={props.supplierList}
                               setCategoryId={setCategoryId}
                               productInfo={props.productInfo}
                               brandList={props.brandList}/>}/>
    )
}