import {Row, Col, Badge, Timeline, DatePicker, Button} from "antd";
import React, {useEffect, useState} from "react";
import Card from "./card";
import styles from "./page.module.css";
import {getBusinessData, getTodayOrderInfo, getMonthOrderInfo, getDayOrderInfo} from "../../order/all/api";
import {Line} from '@ant-design/charts';
import locale from "antd/es/date-picker/locale/zh_CN";


const {RangePicker} = DatePicker;
export default function BusinessData() {
    const [businessData, setBusinessData] = useState(null)
    const [orderInfoList, setOrderInfoList] = useState(null)
    const [count, setCount] = useState(0);
    const [monthOrderData, setMonthOrderData] = useState(null);
    const [dayOrderData, setDayOrderData] = useState(null);
    const [startMonth, setStartMonth] = useState(null);
    const [endMonth, setEndMonth] = useState(null);
    const [startDay, setStartDay] = useState(null);
    const [endDay, setEndDay] = useState(null);
    useEffect(() => {
        init();
        const interval = setInterval(getOrderInfo, 60 * 1000);
        // 清理函数，在组件卸载时调用
        return () => clearInterval(interval);
    }, []);// eslint-disable-line
    function init() {
        getData();
        getOrderInfo();
        getMonthOrder();
        getDayOrder();
    }

    function getData() {
        getBusinessData((data) => {
            setBusinessData(data.resData);
        }, () => {
            setBusinessData(null);
        });
    }

    async function getOrderInfo() {
        getTodayOrderInfo((data) => {
            if (data.resData != null) {
                setCount(data.resData.thirtyMinCount)
                setOrderInfoList(data.resData.orderInfoVOList);
            }
        }, () => {
            setOrderInfoList(null);
        })
    }

    function getMonthOrder() {
        getMonthOrderInfo({
            startMonth,
            endMonth
        }, (data) => {
            setMonthOrderData(data.resData)
        }, () => {
            setMonthOrderData(null);
        });
    }

    function getDayOrder() {
        getDayOrderInfo({
            startDay,
            endDay
        }, (data) => {
            setDayOrderData(data.resData)
        }, () => {
            setDayOrderData(null);
        });
    }

    const monthConfig = {
        data: monthOrderData,
        xField: 'month',
        yField: 'value',
        seriesField: 'type',
        colorField: 'type',
        point: {
            shape: "circle"
        },
        forceFit: true,
        padding: '0px',
        legend: {
            layout: 'horizontal',
            position: 'bottom-center'
        },
        axis: {
            x: {
                labelFontSize: 8,
                style: {
                    labelTransform: 'rotate(-30)'
                }
            },
        }
    };
    const dayConfig = {
        data: dayOrderData,
        xField: 'day',
        yField: 'value',
        seriesField: 'type',
        colorField: 'type',
        point: {
            shape: "circle"
        },
        forceFit: true,
        padding: '0px',
        legend: {
            layout: 'horizontal',
            position: 'bottom-center'
        },
        scrollbar: {
            x: {}
        },
        axis: {
            x: {
                labelFontSize: 8,
                style: {
                    labelTransform: 'rotate(-30)'
                }
            },
        }
    };
    return (
        <div>
            <Row className={styles.row}>
                <Col span={2}>
                    <Card title="总订单" content={businessData != null ? businessData.sumOrder : 0}/>
                </Col>
                <Col span={4}>
                    <Card title="总成交额" content={businessData != null ? businessData.sumAmount : 0}/>
                </Col>
                <Col span={4}>
                    <Card title="总成交转换率"
                          content={businessData != null ? (businessData.convert * 100).toFixed(2) + "%" : 0}/>
                </Col>
                <Col span={4}>
                    <Card title="总现货款" content={businessData != null ? businessData.spots : 0}/>
                </Col>
                <Col span={4}>
                    <Card title="总预售款" content={businessData != null ? businessData.presale : 0}/>
                </Col>
                <Col span={4}>
                    <Card title="总包裹数" content={businessData != null ? businessData.parcel : 0}/>
                </Col>
                <Col span={2}>
                    <Card title="总件数" content={businessData != null ? businessData.sumPurchaseQuantity : 0}/>
                </Col>
            </Row>
            <Row className={styles.row} style={{"marginTop":"10px"}}>
                <Col span={2}>
                    <Card title="今日订单" content={businessData != null ? businessData.todaySumOrder : 0}/>
                </Col>
                <Col span={4}>
                    <Card title="今日成交额" content={businessData != null ? businessData.todaySumAmount : 0}/>
                </Col>
                <Col span={4}>
                    <Card title="今日成交转换率"
                          content={businessData != null ? (businessData.todayConvert * 100).toFixed(2) + "%" : 0}/>
                </Col>
                <Col span={4}>
                    <Card title="今日现货款" content={businessData != null ? businessData.todaySpots : 0}/>
                </Col>
                <Col span={4}>
                    <Card title="今日预售款" content={businessData != null ? businessData.todayPresale : 0}/>
                </Col>
                <Col span={4}>
                    <Card title="今日包裹数" content={businessData != null ? businessData.todayParcel : 0}/>
                </Col>
                <Col span={2}>
                    <Card title="今日件数" content={businessData != null ? businessData.todayPurchaseQuantity : 0}/>
                </Col>
            </Row>
            <div style={{position: "absolute", width: "100%", paddingBottom: "10px"}}>
                <div className={styles.charts}>
                    <Row>
                        <RangePicker
                            format="YYYY-MM-DD"
                            locale={locale}
                            style={{"width": "600px"}}
                            onChange={(dates, dateStrings) => {
                                setStartDay(dateStrings[0]);
                                setEndDay(dateStrings[1]);
                            }}
                        />
                        <Button type="primary" style={{"marginLeft": "10px"}} onClick={() => {
                            getDayOrder();
                        }}>查询</Button>
                    </Row>
                    {
                        dayOrderData != null ? (<Line {...dayConfig} />) : null
                    }
                </div>
                <div className={styles.charts}>
                    <Row>
                        <RangePicker
                            format="YYYY-MM"
                            locale={locale}
                            picker="month"
                            style={{"width": "600px"}}
                            onChange={(dates, dateStrings) => {
                                setStartMonth(dateStrings[0]);
                                setEndMonth(dateStrings[1]);
                            }}
                        />
                        <Button type="primary" style={{"marginLeft": "10px"}} onClick={() => {
                            getMonthOrder();
                        }}>查询</Button>
                    </Row>
                    {
                        monthOrderData != null ? (<Line {...monthConfig} />) : null
                    }
                </div>
            </div>
            <div style={{position: "absolute", marginLeft: "calc(65% + 35px)", paddingBottom: "10px"}}
                 className={styles.message}>
                <div className={styles.title}>
                    <span>今日订单</span>
                    <span style={{marginLeft: '20px'}}>近三十分钟订单
                        <Badge count={count} style={{backgroundColor: '#52c41a', marginLeft: '5px', marginTop: '-5px'}}
                               title={"近三十分钟订单数"}/>
                   </span>
                </div>
                <div style={{marginTop: '10px', height: '535px', overflow: 'scroll'}}>
                    <Timeline style={{marginTop: '10px'}}>
                        {
                            orderInfoList != null ? (
                                orderInfoList.map(item => {
                                    return (<Timeline.Item
                                        color={item.color}>{item.title} {item.realName} {item.gmtCreate}</Timeline.Item>)
                                })
                            ) : null
                        }
                    </Timeline>
                </div>
            </div>
        </div>
    )
}