import {Col, Form, Input, InputNumber, message, Row} from "antd";
import React from "react";
import Util from "../../../../../util/params";
import {getProductInfo} from "./api";

const {Search} = Input;
export default function AddInboundLineForm(props) {
    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };

    function onSearch(value, event) {
        if (!Util.isEmpty(value)) {
            getProductInfo(value, (data) => {
                props.formRef.current.setFieldsValue({
                    productId: data.resData.productId,
                    productName: data.resData.productName,
                    productNumber: data.resData.productNumber,
                    productSpec: data.resData.productSpec,
                    productColor: data.resData.productColor,
                    productSize: data.resData.productSize,
                    productUnit: data.resData.productUnit,
                    productWeight: data.resData.productWeight,
                });
            })
        } else {
            message.info("请输入商品条码")
        }
    }

    return (
        <Form ref={props.formRef} {...layout}>
            <Row gutter={[16, 0]}>
                <Col span={12}>
                    <Form.Item
                        label="商品ID"
                        name="productId"
                        hidden="true">
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="商品条码"
                        name="number"
                        rules={[{required: true, message: '请输入商品条码'}]}>
                        <Search placeholder="请输入商品条码"
                                onSearch={(value, event) => onSearch(value, event)}/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="商品名称"
                        name="productName"
                        rules={[{required: true, message: '请输入商品名称'}]}>
                        <Input readOnly={true}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 0]}>
                <Col span={12}>
                    <Form.Item
                        label="SKU码"
                        name="productNumber"
                        rules={[{required: true, message: '请输入商品SKU码'}]}>
                        <Input readOnly={true}/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="商品规格"
                        name="productSpec">
                        <Input readOnly={true}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 0]}>
                <Col span={12}>
                    <Form.Item
                        label="商品颜色"
                        name="productColor">
                        <Input readOnly={true}/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="商品大小"
                        name="productSize">
                        <Input readOnly={true}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 0]}>
                <Col span={12}>
                    <Form.Item
                        label="商品单位"
                        name="productUnit">
                        <Input readOnly={true}/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="商品重量"
                        name="productWeight">
                        <Input readOnly={true}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 0]}>
                <Col span={12}>
                    <Form.Item
                        label="入库数量"
                        name="quantity"
                        rules={[{required: true, message: '请输入入库数量'}]}>
                        <InputNumber min={1} style={{"width": "238px"}}/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="每笔件数"
                        name="eachQuantity"
                        rules={[{required: true, message: '请输入每笔件数'}]}>
                        <InputNumber min={1} style={{"width": "238px"}}/>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}