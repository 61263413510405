import React from "react";
import {doUpdate} from "./api";
import CommonModal from "../../../../../component/CommonModal";
import UserManualForm from "./userManualForm";

export default function EditUserManual(props) {
    const editFormRef = React.createRef();


    function update() {
        editFormRef.current.validateFields().then(values => {
            doUpdate({
                id: values.id,
                title: values.title,
                content: values.content,
                remarks: values.remarks
            }, (data) => {
                props.setOpenEditModel(false);
                props.init();
            }, () => {
            })
        }).catch(e => {
            console.log(e);
        })
    }

    function handleCancel() {
        props.setOpenEditModel(false);
    }

    return (
        <CommonModal
            title="编辑用户手册"
            width={1000}
            open={props.open}
            onOk={() => update()}
            onCancel={() => handleCancel()}
            destroyOnClose={true}
            form={<UserManualForm formRef={editFormRef} userManual={props.userManual}/>}/>
    )
}