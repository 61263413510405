import React from "react";
import styles from './page.module.css';

export default function Card(props) {
    return (
        <div className={styles.card} style={{background: props.background}}>
            <span className={styles.title}>{props.title}</span>
            <span className={styles.content}>{props.content}</span>
        </div>
    )
}