import React from "react";
import RoleForm from "./roleForm";
import CommonModal from "../../../../component/CommonModal";
import {updateRoleInfo} from "./api";

export default function EditRole(props) {
    const editFormRef = React.createRef();
    if (props.roleInfo != null && props.open) {
        setTimeout(() => {
            editFormRef.current.setFieldsValue(props.roleInfo);
        }, 100);
    }

    function update() {
        editFormRef.current.validateFields().then(values => {
            updateRoleInfo({
                id: values.id,
                name: values.name,
                remarks: values.remarks
            }, (data) => {
                clearInterval(editFormRef.current);
                props.setOpenEditModel(false);
                props.init();
            })
        }).catch(e => {
            console.log(e)
        })

    }

    function handleCancel() {
        props.setOpenEditModel(false);
    }

    return (
        <CommonModal
            title="编辑系统角色"
            open={props.open}
            onOk={() => update()}
            onCancel={() => handleCancel()}
            destroyOnClose={true}
            form={<RoleForm formRef={editFormRef}/>}/>
    )
}