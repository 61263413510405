import HttpUtil from "../util/httpUtil";
/**
 * 获取仓库状态
 * @param successFun
 * @param errorFun
 */
export const doInitStatus = function (successFun, errorFun) {
    HttpUtil.doGet(
        "/listStatus",
        null,
        successFun, errorFun);
}
/**
 * 删除文件信息
 * @param successFun
 * @param errorFun
 */
export const doDeleteFile = function (params,successFun, errorFun) {
    HttpUtil.doDeleteOnOk(
        "/deleteFile",
        params,
        successFun, errorFun);
}
