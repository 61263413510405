import {Button, Col, Form, Input, message, Row, Select, Space} from "antd";
import Constant from "../../../../util/constant";
import React, {useEffect, useState} from "react";
import {
    disable,
    enable,
    listCustomerInfo,
    listUserStatus,
    resetPassword,
    addressable,
    notAddressable
} from "../../../head/admin/user/api";
import CommonTable from "../../../../component/CommonTable";
import Address from "./address";
import SetBalance from "./setBalance";
import EditUserInfo from "./editUserInfo";
import Util from "../../../../util/params";
import {doExportArrears, doExportRepayment} from "./api";

export default function Account() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(Constant.CURRENT_PAGE);
    const [pageSize, setPageSize] = useState(Constant.PAGE_SIZE);

    const [userNumber, setUserNumber] = useState(null);
    const [username, setUsername] = useState(null);
    const [realName, setRealName] = useState(null);
    const [nickname, setNickname] = useState(null);
    const [status, setStatus] = useState(null);
    const [statusList, setStatusList] = useState(null);
    const [userId, setUserId] = useState(null);
    const [openAddressModel, setOpenAddressModel] = useState(false);
    const [openEditModel, setOpenEditModel] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [openBalanceModel, setOpenBalanceModel] = useState(false);
    const [userWallet, setUserWallet] = useState(false);
    const [isExporting, setIsExporting] = useState(false);
    const [isRepaymentExporting, setIsRepaymentExporting] = useState(false);
    const columns: ColumnsType<DataType> = [
        {
            title: '编号',
            dataIndex: 'id',
            key: 'id',
            fixed: 'left',
            width: 90,
            render(text, record) {
                return text.toString().padStart(8, '0');
            }
        },
        {
            title: '账户',
            dataIndex: 'username',
            key: 'username',
            width: 250,
            ellipsis: true,
        },
        {
            title: '姓名',
            dataIndex: 'realName',
            key: 'realName',
        },
        {
            title: '昵称',
            dataIndex: 'nickname',
            key: 'nickname',
        },
        {
            title: '信用额度',
            dataIndex: 'balance',
            key: 'balance',
        },
        {
            title: '欠款',
            dataIndex: 'arrears',
            key: 'arrears',
        },
        {
            title: '湖州地区可访问',
            dataIndex: 'addressableText',
            key: 'addressableText',
        },
        {
            title: '用户状态',
            dataIndex: 'statusText',
            key: 'statusText',
        },
        {
            title: '创建时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: 170
        },
        {
            title: '创建人',
            dataIndex: 'creator',
            key: 'creator',
        },
        {
            title: '最近修改时间',
            dataIndex: 'gmtUpdate',
            key: 'gmt_update',
            width: 170
        },
        {
            title: '最近修改人',
            dataIndex: 'modifier',
            key: 'modifier',
            width: 250
        },
        {
            title: '备注',
            dataIndex: 'remarks',
            key: 'remarks',
        }, {
            title: '操作',
            key: 'action',
            fixed: 'right',
            width: 485,
            render: (text, record) => (
                <Space size="small">
                    <a onClick={() => initAddress(record)}>地址信息</a>
                    <a onClick={() => resetPassword(record, (data) => {
                        message.success(data.msg);
                    })} style={{
                        'color': '#eea236'
                    }}>重置密码</a>
                    <a onClick={() => {
                        if (record.addressable === 0) {
                            addressable(record, () => init())
                        } else {
                            notAddressable(record, () => init())
                        }
                    }} style={{
                        'color': '#eea236'
                    }}>{record.addressable === 0 ? "置为可访问" : "置为不可访问"}</a>
                    <a onClick={() => {
                        if (record.status === 0) {
                            disable(record, () => init())
                        } else {
                            enable(record, () => init())
                        }
                    }} style={{
                        'color': '#eea236'
                    }}>{record.status === 0 ? "禁用" : "启用"}</a>
                    <a onClick={() => setBalance(record)}>设置信用额度</a>
                    <a onClick={() => edit(record)}>编辑姓名</a>
                    <a disabled={isRepaymentExporting} onClick={() => exportRepayment(record)}>导出还款</a>
                </Space>
            )
        }
    ];
    useEffect(() => {
        init(currentPage, pageSize);
        initUserStatus();
    }, []);// eslint-disable-line
    function init(current, page) {
        listCustomerInfo({
            userNumber: userNumber,
            username: username,
            realName: realName,
            nickname: nickname,
            status: status,
            currentPage: current === undefined ? currentPage : current,
            pageSize: page === undefined ? pageSize : page
        }, (data) => {
            setData(data.resData.result);
            setLoading(false);
            setTotal(data.resData.total);

        }, () => {
            setData(null);
            setLoading(false);
        })
    }

    function resetPage(current, page) {
        setCurrentPage(current);
        setPageSize(page);
    }

    function initUserStatus() {
        listUserStatus((data) => {
            setStatusList(data.resData);
        }, () => {
            setStatusList(null);
        })
    }

    function initAddress(record) {
        setUserId(record.id);
        setOpenAddressModel(true);
    }

    function setBalance(record) {
        let userWallet = {};
        userWallet.userId = record.id;
        userWallet.balance = record.balance;
        userWallet.arrears = -record.arrears;
        setUserWallet(userWallet);
        setOpenBalanceModel(true);
    }

    function edit(record) {
        setUserInfo(record);
        setOpenEditModel(true);
    }

    function exportArrears() {
        setIsExporting(true);
        doExportArrears(null, () => {
            message.success("导出成功！");
            setIsExporting(false);
        }, () => {
            message.error("导出失败！")
            setIsExporting(false);
        })
    }

    function exportRepayment(record) {
        setIsRepaymentExporting(true);
        doExportRepayment({userId: record.id}, () => {
            message.success("导出成功！");
            setIsRepaymentExporting(false);
        }, () => {
            message.error("导出失败！")
            setIsRepaymentExporting(false);
        })
    }

    return (
        <div>
            <Form>
                <Row gutter={[16, 0]}>
                    <Col span={6}>
                        <Form.Item
                            label="编号"
                            name="userNumber">
                            <Input placeholder="请输入编号" onChange={(e) => {
                                if (Util.isNumber(e.target.value)) {
                                    setUserNumber(e.target.value);
                                }
                            }}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="账户"
                            name="username">
                            <Input placeholder="请输入账户" onChange={(e) => setUsername(e.target.value)}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="姓名"
                            name="realName">
                            <Input placeholder="请输入姓名" onChange={(e) => setRealName(e.target.value)}/>
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item
                            label="昵称"
                            name="nickname">
                            <Input placeholder="请输入昵称" onChange={(e) => setNickname(e.target.value)}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 0]}>
                    <Col span={6}>
                        <Form.Item
                            label="状态"
                            name="status">
                            <Select onChange={(value, option) => {
                                setStatus(option.key)
                            }}>
                                {
                                    statusList != null ?
                                        statusList.map(item => {
                                            return (<Select.Option key={item.status}
                                                                   value={item.status}>{item.statusText}</Select.Option>)
                                        }) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item>
                            <Button type="primary" onClick={() => {
                                setLoading(true);
                                init(Constant.CURRENT_PAGE, Constant.PAGE_SIZE);
                            }}>查询</Button>
                            <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                                    onClick={() => {
                                        setUserNumber(null);
                                        setUsername(null);
                                        setRealName(null);
                                        setNickname(null);
                                        setStatus(null);
                                    }}>重置</Button>
                            <Button type="primary" style={{'marginLeft': '10px'}}
                                    disabled={isExporting}
                                    onClick={exportArrears}>导出欠款</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <CommonTable
                columns={columns}
                rowKey={columns => columns.id}
                data={data}
                width={2500}
                loading={loading}
                total={total}
                currentPage={currentPage}
                pageSize={pageSize}
                initData={(currentPage, pageSize) => init(currentPage, pageSize)}
                resetPage={(currentPage, pageSize) => resetPage(currentPage, pageSize)}/>
            <Address open={openAddressModel}
                     setOpenAddressModel={setOpenAddressModel}
                     userId={userId}/>
            <SetBalance open={openBalanceModel}
                        setOpenBalanceModel={setOpenBalanceModel}
                        userWallet={userWallet}
                        init={init}/>
            <EditUserInfo open={openEditModel}
                          setOpenEditModel={setOpenEditModel}
                          userInfo={userInfo}
                          init={init}/>
        </div>
    )
}