import {Input, Form, Row, Col, Select, InputNumber} from "antd";
import React, {useEffect, useState} from "react";
import {doList as doLabelList} from "../../../shop/base/label/api";
import {doList as doCommitmentsList} from "../../../shop/base/commitment/api";

export default function MoreForm(props) {
    const [labelList, setLabelList] = useState(null);
    const [commitmentsList, setCommitmentsList] = useState(null);
    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };
    useEffect(() => {
        initLabel();
        initCommitments();
    }, []);// eslint-disable-line
    function initLabel() {
        doLabelList(null, (data) => {
            setLabelList(data.resData.result);
        }, () => {
            setLabelList(null);
        })
    }

    function initCommitments() {
        doCommitmentsList(null, (data) => {
            setCommitmentsList(data.resData.result);
        }, () => {
            setCommitmentsList(null);
        })
    }

    return (
        <div>
            <Form
                ref={props.formRef} {...layout}>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="商品类型"
                            name="type"
                            rules={[{required: true, message: '请选择商品类型'}]}>
                            <Select disabled={(props.view === undefined || props.view === null) ? false : props.view}>
                                <Select.Option key={0}
                                               value={0}
                                               label={"现货商品"}>现货商品</Select.Option>
                                <Select.Option key={1}
                                               value={1}
                                               label={"预售商品"}>预售商品</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="每笔件数"
                            name="oneCount"
                            rules={[{required: true, message: '请输入每笔件数'}]}>
                            <InputNumber min={1} step={1} style={{"width":"362px"}}
                                         disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="商品卖点"
                            name="sellingPoint">
                            <Input placeholder="一句话简述"
                                   disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="商品标签"
                            name="label">
                            <Select disabled={(props.view === undefined || props.view === null) ? false : props.view}
                                    showSearch={true} mode="multiple">
                                {
                                    labelList != null ?
                                        labelList.map(item => {
                                            return (<Select.Option key={item.name}
                                                                   value={item.name}>{item.name}</Select.Option>)
                                        }) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="服务承诺"
                            name="commitments">
                            <Select disabled={(props.view === undefined || props.view === null) ? false : props.view}
                                    showSearch={true} mode="multiple">
                                {
                                    commitmentsList != null ?
                                        commitmentsList.map(item => {
                                            return (<Select.Option key={item.name}
                                                                   value={item.name}>{item.name}</Select.Option>)
                                        }) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}