import HttpUtil from "../../../../../util/httpUtil";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import React from "react";

/**
 * 获取物流公司信息列表
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doList = function (params, successFun, errorFun) {
    HttpUtil.doGet(
        "/shop/logistics/logisticsCompany/list",
        params,
        successFun, errorFun);
}
/**
 * 删除物流公司信息
 * @param record
 * @param successFun
 * @param errorFun
 */
export const doDel = function (record, successFun, errorFun) {
    HttpUtil.doDelete(<ExclamationCircleOutlined/>, "删除物流公司",
        "确认删除" + record.name + "信息？",
        "/shop/logistics/logisticsCompany/delete/" + record.id, null, successFun, errorFun);
}
/**
 * 新增物流公司信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doAdd = function (params, successFun, errorFun) {
    HttpUtil.doPost("/shop/logistics/logisticsCompany/add",
        params, successFun, errorFun);
}
/**
 * 更新物流公司信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doUpdate = function (params, successFun, errorFun) {
    HttpUtil.doPut("/shop/logistics/logisticsCompany/update",
        params, successFun, errorFun);
}
/**
 * 禁用物流公司
 * @param record
 * @param successFun
 * @param errorFun
 */
export const disable = function (record, successFun, errorFun) {
    HttpUtil.doPutConfirm(<ExclamationCircleOutlined/>, "禁用物流公司", "确认禁用" + record.name + "物流公司？",
        "/shop/logistics/logisticsCompany/disable/" + record.id, null, successFun, errorFun);
}
/**
 * 启用物流公司
 * @param record
 * @param successFun
 * @param errorFun
 */
export const enable = function (record, successFun, errorFun) {
    HttpUtil.doPutConfirm(<ExclamationCircleOutlined/>, "启用物流公司", "确认启用" + record.name + "物流公司？",
        "/shop/logistics/logisticsCompany/enable/" + record.id, null, successFun, errorFun);
}
