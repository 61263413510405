import HttpUtil from "../../util/httpUtil";

/**
 * 登出
 * @param successFun
 * @param errorFun
 */
export const loginOut = function (successFun, errorFun) {
    HttpUtil.doPost(
        "/user/loginOut",
        null,
        successFun, errorFun)
};
/**
 * 获取用户信息
 * @param successFun
 * @param errorFun
 */
export const getUserInfo = function (successFun, errorFun) {
    HttpUtil.doGet(
        "/user/get",
        null,
        successFun, errorFun)
};
/**
 * 更新用户信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const updateUserInfo = function (params, successFun, errorFun) {
    HttpUtil.doPut(
        "/user/update",
        params,
        successFun, errorFun)
};
/**
 * 更新密码
 * @param params
 * @param successFun
 * @param errorFun
 */
export const updatePassword = function (params, successFun, errorFun) {
    HttpUtil.doPut(
        "/user/updatePassword",
        params,
        successFun, errorFun)
};