import React, {useState} from "react";
import {doAdd} from "./api";
import CommonModal from "../../../../../component/CommonModal";
import BannerForm from "./bannerForm";

export default function AddBanner(props) {
    const addFormRef = React.createRef();
    const [addSubmitLoading, setAddSubmitLoading] = useState(false);


    function add() {
        if (!addSubmitLoading) {
            if (!addSubmitLoading) {
                addFormRef.current.validateFields().then(values => {
                    setAddSubmitLoading(true)
                    doAdd({
                        name: values.name,
                        url: values.url,
                        image: values.imageFiles.length > 0 ? values.imageFiles[0].response : null,
                        orderNumber: values.orderNumber,
                        remarks: values.remarks
                    }, (data) => {
                        setAddSubmitLoading(false);
                        props.setOpenAddModel(false);
                        props.init();
                    }, () => {
                        setAddSubmitLoading(false);
                    })
                }).catch(e => {
                    console.log(e);
                })
            }
        }
    }

    function handleCancel() {
        props.setOpenAddModel(false);
    }

    return (
        <CommonModal
            title="新增横幅"
            open={props.open}
            onOk={() => add()}
            onCancel={() => handleCancel()}
            destroyOnClose={true}
            confirmLoading={addSubmitLoading}
            form={<BannerForm formRef={addFormRef}/>}/>
    )
}