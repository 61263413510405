import React from "react";
import CommonModal from "../../../../../component/CommonModal";
import SoldForm from "./soldForm";

export default function ViewSold(props) {
    const baseFormRef = React.createRef();
    const specFormRef = React.createRef();
    const detailFormRef = React.createRef();
    const moreFormRef = React.createRef();

    if (props.saleCommodity != null && props.open) {
        setTimeout(() => {
            baseFormRef.current.setFieldsValue(props.saleCommodity);
            baseFormRef.current.setFieldsValue({
                shopCategoryIds: props.saleCommodity.shopCategoryIds === null ? [] : props.saleCommodity.shopCategoryIds.split(','),
            });
            specFormRef.current.setFieldsValue(props.saleCommodity);
            moreFormRef.current.setFieldsValue({
                type: props.saleCommodity.type,
                oneCount: props.saleCommodity.oneCount,
                sellingPoint: props.saleCommodity.sellingPoint,
                label: props.saleCommodity.labelList === [] ? [] : props.saleCommodity.labelList,
                commitments: props.saleCommodity.commitmentList === null ? [] : props.saleCommodity.commitmentList,
            });
        }, 100);
    }

    function handleCancel() {
        props.setOpenViewModel(false);
    }

    return (
        <CommonModal
            title="查看上架商品"
            open={props.open}
            onOk={() => handleCancel()}
            onCancel={() => handleCancel()}
            destroyOnClose={true}
            width={1050}
            form={<SoldForm baseFormRef={baseFormRef}
                            specFormRef={specFormRef}
                            detailFormRef={detailFormRef}
                            moreFormRef={moreFormRef}
                            categoryList={props.categoryList}
                            skuInfo={(props.saleCommodity === null || props.saleCommodity === undefined
                                || props.saleCommodity.skuInfo === undefined || props.saleCommodity.skuInfo === null) ? null : props.saleCommodity.skuInfo}
                            details={(props.saleCommodity === null || props.saleCommodity === undefined
                                || props.saleCommodity.details === undefined || props.saleCommodity.details === null) ? null : props.saleCommodity.details}
                            view={true}/>}/>
    )
}