import {ExclamationCircleOutlined} from '@ant-design/icons';
import React from "react";
import HttpUtil from "../../../../../util/httpUtil";

/**
 * 获取承诺列表
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doList = function (params, successFun, errorFun) {
    HttpUtil.doGet(
        "/shop/base/commitment/list",
        params,
        successFun, errorFun);
}
/**
 * 删除承诺
 * @param record
 * @param successFun
 * @param errorFun
 */
export const doDel = function (record, successFun, errorFun) {
    HttpUtil.doDelete(<ExclamationCircleOutlined/>, "删除承诺",
        "确认删除" + record.name + "信息？",
        "/shop/base/commitment/delete/" + record.id, null, successFun, errorFun);
}
/**
 * 新增承诺
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doAdd = function (params, successFun, errorFun) {
    HttpUtil.doPost("/shop/base/commitment/add",
        params, successFun, errorFun);
}
/**
 * 更新承诺
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doUpdate = function (params, successFun, errorFun) {
    HttpUtil.doPut("/shop/base/commitment/update",
        params, successFun, errorFun);
}
