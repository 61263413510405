import React from "react";
import {Form, Input} from "antd";

export default function EditUserInfoForm(props) {
    const layout = {
        labelCol: {span: 4},
        wrapperCol: {span: 20},
    };
    return (
        <div>
            <Form
                ref={props.formRef} {...layout}>
                <Form.Item
                    label="用户ID"
                    name="id"
                    hidden="true">
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="姓名"
                    name="realName"
                    rules={[{required: true, message: '请输入姓名'}]}>
                    <Input placeholder="请输入姓名"/>
                </Form.Item>
            </Form>
        </div>
    )
}