import React from "react";
import {doUpdate} from "./api";
import CommonModal from "../../../../../component/CommonModal";
import TemplateForm from "./templateForm";

export default function EditTemplate(props) {
    const editFormRef = React.createRef();
    if (props.barcodeTemplate != null && props.open) {
        setTimeout(() => {
            editFormRef.current.setFieldsValue(props.barcodeTemplate);
        }, 100);
    }


    function update() {
        editFormRef.current.validateFields().then(values => {
            doUpdate({
                id: values.id,
                name: values.name,
                imageW: values.imageW,
                imageH: values.imageH,
                codeW: values.codeW,
                codeH: values.codeH,
                codeLeftMargin: values.codeLeftMargin,
                codeTopMargin: values.codeTopMargin,
                codePosition: values.codePosition,
                codeCharactersPosition: values.codeCharactersPosition,
                codeCharactersSize: values.codeCharactersSize,
                charactersSize: values.charactersSize,
                //showSupplier: (values.showSupplier === true || values.showSupplier === 1) ? 1 : 0,
                //showBrand: (values.showBrand === true || values.showBrand === 1) ? 1 : 0,
                showCode: (values.showCode === true || values.showCode === 1) ? 1 : 0,
                showCommodity: (values.showCommodity === true || values.showCommodity === 1) ? 1 : 0,
                //showCategory: (values.showCategory === true || values.showCategory === 1) ? 1 : 0,
                showColor: (values.showColor === true || values.showColor === 1) ? 1 : 0,
                showSize: (values.showSize === true || values.showSize === 1) ? 1 : 0,
                showSpec: (values.showSpec === true || values.showSpec === 1) ? 1 : 0,
                //showWeight: (values.showWeight === true || values.showWeight === 1) ? 1 : 0,
                //showUnit: (values.showUnit === true || values.showUnit === 1) ? 1 : 0,
                remarks: values.remarks
            }, (data) => {
                props.setOpenEditModel(false);
                props.init();
            })
        }).catch(e => {
            console.log(e)
        })

    }

    function handleCancel() {
        props.setOpenEditModel(false);
    }

    return (
        <CommonModal
            title="编辑模版"
            width={800}
            open={props.open}
            onOk={() => update()}
            onCancel={() => handleCancel()}
            destroyOnClose={true}
            form={<TemplateForm formRef={editFormRef}
                                positionList={props.positionList}/>}/>
    )
}