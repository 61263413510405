import {Form, Input} from "antd";
import React from "react";

const {TextArea} = Input;

export default function RejectOrderForm(props) {
    const layout = {
        labelCol: {span: 4},
        wrapperCol: {span: 20},
    };
    return (
        <div>
            <Form
                ref={props.formRef} {...layout}>
                <Form.Item
                    label="订单ID"
                    name="id"
                    hidden="true">
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="驳回备注"
                    name="remarks"
                    rules={[{required: true, message: '请输入驳回备注'}]}>
                    <TextArea/>
                </Form.Item>
            </Form>
        </div>
    )
}