import React from "react";
import CommonModal from "../../../../../component/CommonModal";
import ProductForm from "./productForm";

export default function ViewProduct(props) {
    const viewFormRef = React.createRef();
    if (props.productInfo != null && props.open) {
        setTimeout(() => {
            viewFormRef.current.setFieldsValue(props.productInfo);
            viewFormRef.current.setFieldsValue({
                'categoryId': props.productInfo.category,
                'supplierId': props.productInfo.supplier,
                'brandId': props.productInfo.brand,
            });
        }, 100);
    }
    function handleCancel() {
        props.setOpenViewModel(false);
    }

    return (
        <CommonModal
            title="查看SKU"
            open={props.open}
            onOk={() => handleCancel()}
            onCancel={() => handleCancel()}
            destroyOnClose={true}
            width={750}
            form={<ProductForm formRef={viewFormRef} view={true}  productInfo={props.productInfo}/>}/>
    )
}