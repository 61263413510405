import {ExclamationCircleOutlined} from '@ant-design/icons';
import React from "react";
import HttpUtil from "../../../../../util/httpUtil";

/**
 * 获取品牌信息列表
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doList = function (params, successFun, errorFun) {
    HttpUtil.doGet(
        "/commodity/brand/list",
        params,
        successFun, errorFun);
}
/**
 * 删除品牌信息
 * @param record
 * @param successFun
 * @param errorFun
 */
export const doDel = function (record, successFun, errorFun) {
    HttpUtil.doDelete(<ExclamationCircleOutlined/>, "删除品牌",
        "确认删除" + record.name + "信息？",
        "/commodity/brand/delete/" + record.id, null, successFun, errorFun);
}
/**
 * 新增品牌信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doAdd = function (params, successFun, errorFun) {
    HttpUtil.doPost("/commodity/brand/add",
        params, successFun, errorFun);
}
/**
 * 更新品牌信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doUpdate = function (params, successFun, errorFun) {
    HttpUtil.doPut("/commodity/brand/update",
        params, successFun, errorFun);
}
/**
 * 禁用品牌
 * @param record
 * @param successFun
 * @param errorFun
 */
export const disable = function (record, successFun, errorFun) {
    HttpUtil.doPutConfirm(<ExclamationCircleOutlined/>, "禁用品牌", "确认禁用" + record.name + "？",
        "/commodity/brand/disable/" + record.id, null, successFun, errorFun);
}
/**
 * 启用品牌
 * @param record
 * @param successFun
 * @param errorFun
 */
export const enable = function (record, successFun, errorFun) {
    HttpUtil.doPutConfirm(<ExclamationCircleOutlined/>, "启用品牌", "确认启用" + record.name + "？",
        "/commodity/brand/enable/" + record.id, null, successFun, errorFun);
}

