import React, {useState} from "react";
import {doAdd} from "./api";
import CommonModal from "../../../../../component/CommonModal";
import TemplateForm from "./templateForm";

export default function AddTemplate(props) {
    const addFormRef = React.createRef();
    const [addSubmitLoading, setAddSubmitLoading] = useState(false);


    function add() {
        if (!addSubmitLoading) {
            addFormRef.current.validateFields().then(values => {
                console.log(values)
                setAddSubmitLoading(true)
                doAdd({
                    name: values.name,
                    imageW: values.imageW,
                    imageH: values.imageH,
                    codeW: values.codeW,
                    codeH: values.codeH,
                    codeLeftMargin: values.codeLeftMargin,
                    codeTopMargin: values.codeTopMargin,
                    codePosition: values.codePosition,
                    codeCharactersPosition: values.codeCharactersPosition,
                    codeCharactersSize: values.codeCharactersSize,
                    charactersSize: values.charactersSize,
                    //showSupplier: (values.showSupplier === true || values.showSupplier === 1) ? 1 : 0,
                    //showBrand: (values.showBrand === true || values.showBrand === 1) ? 1 : 0,
                    showCode: (values.showCode === true || values.showCode === 1) ? 1 : 0,
                    showCommodity: (values.showCommodity === true || values.showCommodity === 1) ? 1 : 0,
                    //showCategory: (values.showCategory === true || values.showCategory === 1) ? 1 : 0,
                    showColor: (values.showColor === true || values.showColor === 1) ? 1 : 0,
                    showSize: (values.showSize === true || values.showSize === 1) ? 1 : 0,
                    showSpec: (values.showSpec === true || values.showSpec === 1) ? 1 : 0,
                    //showWeight: (values.showWeight === true || values.showWeight === 1) ? 1 : 0,
                    //showUnit: (values.showUnit === true || values.showUnit === 1) ? 1 : 0,
                    remarks: values.remarks
                }, (data) => {
                    setAddSubmitLoading(false);
                    props.setOpenAddModel(false);
                    props.init();
                }, () => {
                    setAddSubmitLoading(false);
                })
            }).catch(e => {
                console.log(e);
            })
        }
    }

    function handleCancel() {
        props.setOpenAddModel(false);
    }

    return (
        <CommonModal
            title="新增模版"
            open={props.open}
            onOk={() => add()}
            onCancel={() => handleCancel()}
            destroyOnClose={true}
            width={800}
            confirmLoading={addSubmitLoading}
            form={<TemplateForm formRef={addFormRef}
                                positionList={props.positionList}/>}/>
    )
}