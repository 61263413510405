import React from "react";
import {doUpdateSend} from "./api";
import CommonModal from "../../../../component/CommonModal";
import EditLogisticsForm from "./editLogisticsForm";

export default function EditSendOrderInfo(props){
    const sendFormRef = React.createRef();
    if (props.orderInfo != null && props.open) {
        setTimeout(() => {
            sendFormRef.current.setFieldsValue({
                orderId: props.orderInfo.id,
                logisticsCompanyId: props.orderInfo.logisticsCompanyId,
                logisticsNumber: props.orderInfo.logisticsNumber,
                remarks: props.orderInfo.logisticsRemarks,
            });
        }, 100);
    }

    function update() {
        sendFormRef.current.validateFields().then(values => {
            doUpdateSend({
                orderId: values.orderId,
                logisticsCompanyId: values.logisticsCompanyId,
                logisticsNumber: values.logisticsNumber,
                remarks: values.remarks
            }, (data) => {
                clearInterval(sendFormRef.current);
                props.setOpenSendModel(false);
                props.init();
            })
        }).catch(e => {
            console.log(e)
        })

    }

    function handleCancel() {
        props.setOpenSendModel(false);
    }

    return (
        <CommonModal
            title="修改物流信息"
            open={props.open}
            onOk={() => update()}
            onCancel={() => handleCancel()}
            destroyOnClose={true}
            form={<EditLogisticsForm formRef={sendFormRef}/>}/>
    )
}