import HttpUtil from "../../../../../util/httpUtil";

/**
 * 获取商品库存变更信息列表
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doList = function (params, successFun, errorFun) {
    HttpUtil.doGet(
        "/commodity/inventoryHistory/list",
        params,
        successFun, errorFun);
}

