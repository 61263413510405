import React, {useEffect, useState} from "react";
import Constant from "../../../../../util/constant";
import CommonTable from "../../../../../component/CommonTable";
import {doList} from "./api"
import {Button, Col, DatePicker, Form, Input, Row, Select} from "antd";
import {doList as doWarehouseList} from "../../../warehouse/information/warehouse/api";
import locale from "antd/es/date-picker/locale/zh_CN";

const {RangePicker} = DatePicker;

export default function InventoryChangeRecord() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(Constant.CURRENT_PAGE);
    const [pageSize, setPageSize] = useState(Constant.PAGE_SIZE);

    const [productName, setProductName] = useState(null);
    const [productNumber, setProductNumber] = useState(null);
    const [warehouseId, setWarehouseId] = useState(null);
    const [warehouseList, setWarehouseList] = useState(null);
    const [type, setType] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const columns = [
        {
            title: '单据编号',
            dataIndex: 'number',
            key: 'number',
        },
        {
            title: '仓库编码',
            dataIndex: 'warehouseNumber',
            key: 'warehouseNumber',
        },
        {
            title: '仓库名称',
            dataIndex: 'warehouseName',
            key: 'warehouseName',
        },
        {
            title: 'SKU码',
            dataIndex: 'productNumber',
            key: 'productNumber',
        },
        {
            title: '商品名称',
            dataIndex: 'productName',
            key: 'productName',
        },
        {
            title: '类型',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: '状态',
            dataIndex: 'statusText',
            key: 'statusText',
        },
        {
            title: '商品规格',
            dataIndex: 'productSpec',
            key: 'productSpec',
        },
        {
            title: '商品颜色',
            dataIndex: 'productColor',
            key: 'productColor',
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: '单据时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
        },
        {
            title: '操作人',
            dataIndex: 'creator',
            key: 'creator',
        }
    ];

    useEffect(() => {
        init(currentPage, pageSize);
        initWarehouse();
    }, []);// eslint-disable-line
    function init(current, page) {
        doList({
            productName: productName,
            productNumber: productNumber,
            warehouseId: warehouseId,
            type: type,
            startDate: startDate,
            endDate: endDate,
            currentPage: current === undefined ? currentPage : current,
            pageSize: page === undefined ? pageSize : page
        }, (data) => {
            setData(data.resData.result);
            setLoading(false);
            setTotal(data.resData.total);
        }, () => {
            setData(null);
            setLoading(false);
        })
    }

    function initWarehouse() {
        doWarehouseList({
            status: 0
        }, (data) => {
            setWarehouseList(data.resData.result)
        }, () => {
            setWarehouseList(null);
        })
    }

    function resetPage(currentPage, pageSize) {
        setCurrentPage(currentPage);
        setPageSize(pageSize);
    }

    return (
        <div>
            <Form>
                <Row gutter={[16, 0]}>
                    <Col span={8}>
                        <Form.Item
                            label="SKU码"
                            name="productNumber">
                            <Input placeholder="请输入SKU码" onChange={(e) => setProductNumber(e.target.value)}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="商品名称"
                            name="productName">
                            <Input placeholder="请输入商品名称" onChange={(e) => setProductName(e.target.value)}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="单据日期"
                            name="date">
                            <RangePicker locale={locale} onChange={(date, dateString) => {
                                setStartDate(dateString[0]);
                                setEndDate(dateString[1]);
                            }}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 0]}>
                    <Col span={8}>
                        <Form.Item
                            label="所属仓库"
                            name="warehouseId">
                            <Select showSearch
                                    placeholder="请选择仓库"
                                    allowClear
                                    optionFilterProp="label"
                                    onChange={(value, option) => {
                                        if (option !== undefined) {
                                            setWarehouseId(option.key);
                                        } else {
                                            setWarehouseId(null);
                                        }
                                    }}>
                                {
                                    warehouseList != null ?
                                        warehouseList.map(item => {
                                            return (<Select.Option key={item.id}
                                                                   value={item.id}
                                                                   label={item.name}>{item.name}</Select.Option>)
                                        }) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="变动类别"
                            name="type">
                            <Select onChange={(value, option) => {
                                setType(option.label)
                            }}
                                    options={[
                                        {
                                            key: '0',
                                            value: '0',
                                            label: '入库',
                                        },
                                        {
                                            key: '1',
                                            value: '1',
                                            label: '出库',
                                        }
                                    ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item>
                            <Button type="primary" onClick={() => {
                                setLoading(true);
                                init(Constant.CURRENT_PAGE, Constant.PAGE_SIZE);
                            }}>查询</Button>
                            <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                                    onClick={() => {
                                        setProductName(null);
                                        setProductNumber(null);
                                        setType(null);
                                        setWarehouseId(null);
                                        setStartDate(null);
                                        setEndDate(null);
                                    }}>重置</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <CommonTable
                columns={columns}
                rowKey={columns => columns.number}
                data={data}
                width={1500}
                loading={loading}
                total={total}
                currentPage={currentPage}
                pageSize={pageSize}
                initData={(currentPage, pageSize) => init(currentPage, pageSize)}
                resetPage={(currentPage, pageSize) => resetPage(currentPage, pageSize)}/>
        </div>
    )
}