const Util = {
    isEmpty(value) {
        return value === undefined || value === null || value.trim() === '';
    },
    checkUserName(username) {
        if (Util.isEmpty(username)) {
            return false;
        }
        let reg = new RegExp(/^[a-zA-Z][_a-zA-Z0-9]{3,127}$/);
        if (reg.test(username)) {
            return true;
        } else {
            return false;
        }
    },
    checkPassword(password) {
        if (Util.isEmpty(password)) {
            return false;
        }
        let reg = new RegExp(/^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[_!@#$%^&*()])[0-9a-zA-Z_!@#$%^&*()]{8,16}$/);
        if (reg.test(password)) {
            return true;
        } else {
            return false;
        }
    },
    isEmail: function (email) {
        if (Util.isEmpty(email)) {
            return false;
        }
        let reg = new RegExp(/^([a-zA-Z]|[0-9])(\w|\\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/);
        if (reg.test(email)) {
            return true;
        } else {
            return false;
        }
    },
    isNumber: function (number) {
        if (number === null || number === undefined) {
            return false;
        }
        let reg = new RegExp(/(^[0-9]\d*$)/);
        if (reg.test(number)) {
            return true;
        } else {
            return false;
        }
    },
    isPrice: function (number) {
        if (number === null || number === undefined) {
            return false;
        }
        let reg = new RegExp(/(^\d+(\.\d{1,3})?$)/);
        if (reg.test(number)) {
            return true;
        } else {
            return false;
        }
    },
    isTel: function (tel) {
        if (Util.isEmpty(tel)) {
            return false;
        }
        let reg = /^1[3456789]\d{9}$/;
        if (reg.test(tel)) {
            return true;
        } else {
            return false;
        }
    },
    isIdCard: function (idCard) {
        if (Util.isEmpty(idCard)) {
            return false;
        }
        let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
        if (!reg.test(idCard)) {
            return false;
        } else {
            return true;
        }
    },
    fillZero(str) {
        let realNum;
        if (str < 10) {
            realNum = '0' + str;
        } else {
            realNum = str;
        }
        return realNum;
    },
    isJSON(str) {
        try {
            JSON.parse(str);
            return true;
        } catch (e) {
            return false;
        }
    }
}
export default Util;
