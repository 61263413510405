import React from "react";
import {Modal} from "antd";

export default class CommonModal extends React.Component {
    render() {
        const {title, open, bodyStyle, onOk, onCancel, destroyOnClose, width, form, confirmLoading} = this.props;
        return (
            <div>
                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}>
                            {title}
                        </div>
                    }
                    open={open}
                    onCancel={onCancel}
                    onOk={onOk}
                    destroyOnClose={destroyOnClose}
                    width={width}
                    confirmLoading={confirmLoading}
                    style={bodyStyle}>
                    {form}
                </Modal>
            </div>
        )
    }
}