import Axios from "./axios";
import {message, Modal} from "antd";

const HttpUtil = {
    doPost(url, params, successFun, errorFun) {
        Axios({
            method: 'post',
            url: url,
            data: JSON.stringify(params)
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    if (successFun != null && successFun !== undefined) {
                        successFun(res.data);
                    }
                } else {
                    if (errorFun != null && errorFun !== undefined) {
                        errorFun();
                    }
                    message.error(res.data.msg)
                }
            }
        }).catch(err => {
            console.log(err);
            if (errorFun != null && errorFun !== undefined) {
                errorFun();
            }
        });
    },
    doPut(url, params, successFun, errorFun) {
        Axios({
            method: 'put',
            url: url,
            data: JSON.stringify(params)
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    if (successFun != null && successFun !== undefined) {
                        successFun(res.data);
                    }
                } else {
                    if (errorFun != null && errorFun !== undefined) {
                        errorFun();
                    }
                    message.error(res.data.msg)
                }
            }
        }).catch(err => {
            console.log(err);
            if (errorFun != null && errorFun !== undefined) {
                errorFun();
            }
        });
    },
    doGet(url, params, successFun, errorFun) {
        Axios({
            method: 'get',
            url: url,
            params: params
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    if (successFun != null && successFun !== undefined) {
                        successFun(res.data);
                    }
                } else {
                    if (errorFun != null && errorFun !== undefined) {
                        errorFun();
                    }
                    message.error(res.data.msg)
                }
            }
        }).catch(err => {
            console.log(err);
            if (errorFun != null && errorFun !== undefined) {
                errorFun();
            }
        });
    },
    doDeleteOnOk(url, params, successFun, errorFun) {
        Axios({
            method: 'delete',
            url: url,
            params: params
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    if (successFun != null && successFun !== undefined) {
                        successFun();
                    }
                } else {
                    if (errorFun != null && errorFun !== undefined) {
                        errorFun();
                    }
                    message.error(res.data.msg)
                }
            }
        }).catch(err => {
            console.log(err);
            if (errorFun != null && errorFun !== undefined) {
                errorFun();
            }
        });
    },
    doDelete(icon, title, content, url, params, successFun, errorFun) {
        Modal.confirm({
            title: title,
            icon: icon,
            content: content,
            onOk: () => HttpUtil.doDeleteOnOk(url, params, successFun, errorFun)
        })
    },
    doPutConfirm(icon, title, content, url, params, successFun, errorFun) {
        Modal.confirm({
            title: title,
            icon: icon,
            content: content,
            onOk: () => HttpUtil.doPut(url, params, successFun, errorFun)
        })
    },
    doPostConfirm(icon, title, content, url, params, successFun, errorFun, cancelFun) {
        Modal.confirm({
            title: title,
            icon: icon,
            content: content,
            onOk: () => HttpUtil.doPost(url, params, successFun, errorFun),
            onCancel: cancelFun
        })
    },
    doExport(url, params, fileName, successFun, errorFun) {
        Axios({
            method: 'post',
            url: url,
            data: (params === undefined || params === null) ? null : JSON.stringify(params),
            responseType: 'blob',
        }).then(res => {
            if (res.status === 200 && res.data.type !== "application/json") {
                const blob = new Blob([res.data])
                const downloadElement = document.createElement('a');
                const href = window.URL.createObjectURL(blob); //创建下载的链接
                downloadElement.href = href;
                downloadElement.download = fileName + '.xlsx'; //下载后文件名
                document.body.appendChild(downloadElement);
                downloadElement.click(); //点击下载
                document.body.removeChild(downloadElement); //下载完成移除元素
                window.URL.revokeObjectURL(href); //释放掉blob对象
                if (successFun != null && successFun !== undefined) {
                    successFun();
                }
            } else {
                if (errorFun != null && errorFun !== undefined) {
                    errorFun();
                }
            }
        }).catch(err => {
            console.log(err);
            if (errorFun != null && errorFun !== undefined) {
                errorFun();
            }
        });
    },
    doUpload(url, formData, fileName, successFun, errorFun) {
        if (formData === null && formData === undefined) {
            return;
        }
        Axios({
            method: 'post',
            url: url,
            data: formData,
            headers: {'Content-Type': 'multipart/form-data'}
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 200) {
                    if (successFun != null && successFun !== undefined) {
                        successFun(res.data);
                    }
                } else {
                    if (errorFun != null && errorFun !== undefined) {
                        errorFun();
                    }
                    message.error(res.data.msg)
                }
            }
        }).catch(err => {
            console.log(err);
            if (errorFun != null && errorFun !== undefined) {
                errorFun();
            }
        });
    }
}
export default HttpUtil;