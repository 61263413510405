import styles from "./menu.module.css"
import {Menu} from 'antd';
import {useEffect, useState} from 'react';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
} from '@ant-design/icons'
import {listMenu} from './api';
import IconUtil from '../../util/iconUtil';
import Util from "../../util/params";
import {Link, useNavigate} from 'react-router-dom';
import './menu.css'

export default function LeftMenu() {
    const [menuOpen, setMenuOpen] = useState(true);
    const [items, setItems] = useState(null);
    const [openKeys, setOpenKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [collapsed, setCollapsed] = useState(false);


    const rootSubmenuKeys = [];
    const navigate = useNavigate();

    useEffect(() => {
        init();
        initOpenMenu();
    }, []);// eslint-disable-line

    function init() {
        listMenu(0, (data) => {
            setItems(data.resData.map(item => {
                return menuItem(item);
            }))
        }, () => {
            setItems(null)
        });
    }

    function initOpenMenu() {
        let pathname = window.location.pathname;
        //获取当前所在的目录层级
        const rank = pathname.split('/')
        //rank = ["","policy-engine","nas-client"]
        switch (rank.length) {
            case 2:  //一级目录
                setSelectedKeys([pathname]);
                break;
            case 3: //二级目录，要展开一个subMenu
                setSelectedKeys([pathname]);
                setOpenKeys([rank.slice(0, 2).join('/')]);
                break;
            case 4: //三级目录，要展开两个subMenu
                setSelectedKeys([pathname]);
                setOpenKeys([rank.slice(0, 2).join('/'), rank.slice(0, 3).join('/')]);
                break;
            default:
                setSelectedKeys([pathname]);
                break;
        }
    }

    function initOpenMenuColor() {
        let a_array = document.getElementById("menu").getElementsByTagName('a');
        for (let i = 0; i < a_array.length; i++) {
            a_array[i].style.color = "#000000"
            if (a_array[i].pathname === window.location.pathname || window.location.pathname.indexOf(a_array[i].pathname) !== -1) {
                a_array[i].style.color = "#1890ff"
            }
        }
    }

    function menuSwitch() {
        setMenuOpen(!menuOpen);
        setCollapsed(!collapsed);
        menuOpen ? (document.getElementById('content').style.width = "calc(100% - 50px)") :
            (document.getElementById('content').style.width = "calc(100% - 200px)");
        menuOpen ? (document.getElementById('content').style.left = "50px") :
            (document.getElementById('content').style.left = "200px");
        initOpenMenu();
        setTimeout(() => {
            initOpenMenuColor();
        }, 100);

    }

    function menuItem(item) {
        if (!Util.isEmpty(item.icon)) {
            item.icon = IconUtil.createAntdIcon(item.icon);
        }
        if (item.children.length > 0) {
            rootSubmenuKeys.push(item.path);
            item.children.map(childrenItem => {
                return menuItem(childrenItem)
            });
        } else {
            item.children = null;
            item.label =
                <div className={styles.link} onClick={(e) => itemOnClick(e)}><Link to={item.path}>{item.label}</Link>
                </div>
        }
        return item;
    }

    function itemOnClick(e) {
        let a_array = document.getElementById("menu").getElementsByTagName('a');
        for (let i = 0; i < a_array.length; i++) {
            a_array[i].style.color = "#000000"
        }

        let selectedKeys = [];
        if (e.target.nodeName === "DIV") {
            e.target.childNodes[0].style.color = "#1890ff";
            selectedKeys[0] = e.target.childNodes[0].pathname
            setSelectedKeys(selectedKeys);
            navigate(selectedKeys[0]);
        } else {
            e.target.style.color = "#1890ff";
            selectedKeys[0] = e.view.location.pathname
            setSelectedKeys(selectedKeys);
        }
    }

    function onOpenChange(keys) {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };
    const menuClass = menuOpen ? styles.menu_open : styles.menu_close;
    return (
        <div id='menu' className={styles.menu + ' ' + menuClass}>
            <div className={styles.ball} onClick={() => menuSwitch()}>
                {menuOpen ? (<MenuFoldOutlined style={{'color': '#1890ff', 'fontSize': '18px'}}/>) : (
                    <MenuUnfoldOutlined style={{'color': '#1890ff', 'fontSize': '18px'}}/>)}
            </div>
            {
                items != null ? (
                    <Menu
                        mode="inline"
                        style={{marginTop: '30px', background: '#ffffff'}}
                        onOpenChange={(keys) => onOpenChange(keys)}
                        openKeys={openKeys}
                        inlineCollapsed={collapsed}
                        selectedKeys={selectedKeys}
                        items={items}/>) : null
            }
        </div>
    )
}