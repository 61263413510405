import CommonModal from "../../../../component/CommonModal";
import React from "react";
import RefundForm from "./refundForm";
import {doRefund2} from "../all/api";

export default function Refund(props) {
    const refundFormRef = React.createRef();
    if (props.orderInfo != null && props.open) {
        setTimeout(() => {
            refundFormRef.current.setFieldsValue({
                orderId: props.orderInfo.id
            });
        }, 100);
    }

    function refund() {
        refundFormRef.current.validateFields().then(values => {
            doRefund2({
                id: values.orderId,
                refundAmount: values.refundAmount,
            }, (data) => {
                clearInterval(refundFormRef.current);
                props.setOpenRefundModel(false);
                props.init();
            }, null);
        }).catch(e => {
            console.log(e)
        })

    }

    function handleCancel() {
        props.setOpenRefundModel(false);
    }

    return (
        <CommonModal
            title="退款"
            open={props.open}
            onOk={() => refund()}
            onCancel={() => handleCancel()}
            destroyOnClose={true}
            form={<RefundForm formRef={refundFormRef}
                              refundAmount={props.orderInfo != null && props.open ? props.orderInfo.waitSendAmount : 0}/>}/>
    )
}