import HttpUtil from "../../../../util/httpUtil";

/**
 * 获取包裹数据
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doList = function (params, successFun, errorFun) {
    HttpUtil.doGet(
        "/order/getPackages",
        params,
        successFun, errorFun);
}