import {Form, Input, Radio, InputNumber, Table, Select} from "antd";
import React, {useEffect, useState} from "react";
import {doListRegion} from "./api";

const {TextArea} = Input;

export default function FreightForm(props) {
    const [regionList, setRegionList] = useState(null);
    const layout = {
        labelCol: {span: 4},
        wrapperCol: {span: 20},
    };
    const columns = [
        {
            title: 'ruleId',
            dataIndex: 'ruleId',
            key: 'ruleId',
        },
        {
            title: '配送区域',
            dataIndex: 'regions',
            key: 'regions',
            width: 200
        },
        {
            title: '首件(重)',
            dataIndex: 'first',
            key: 'first',
        },
        {
            title: '运费(元)',
            dataIndex: 'firstFee',
            key: 'firstFee',
        },
        {
            title: '续件(重）',
            dataIndex: 'additional',
            key: 'additional',
        },
        {
            title: '续费(元)',
            dataIndex: 'additionalFee',
            key: 'additionalFee',
        }
    ]

    const dataSource = [
        {
            key: "1",
            regions: <Select
                placeholder="请选择配送区域"
                mode="multiple"
                showSearch
                allowClear
                onChange={(value, option) => {
                    props.setRegions(value);
                }}
                defaultValue={props.regions === null ? [] : props.regions}
                optionFilterProp="label">
                {
                    regionList != null ?
                        regionList.map(item => {
                            return (<Select.Option key={item.number}
                                                   value={item.number}
                                                   label={(item.name)}>{item.name}</Select.Option>)
                        }) : null
                }
            </Select>,
            first: <InputNumber min={1} defaultValue={props.first} onChange={(value) => {
                props.setFirst(value);
            }}/>,
            firstFee: <InputNumber min={0.00} defaultValue={props.firstFee} step={0.01} onChange={(value) => {
                props.setFirstFee(value);
            }}/>,
            additional: <InputNumber min={1} defaultValue={props.additional} onChange={(value) => {
                props.setAdditional(value);
            }}/>,
            additionalFee: <InputNumber min={0.00} defaultValue={props.additionalFee} step={0.01}
                                        onChange={(value) => {
                                            props.setAdditionalFee(value);
                                        }}/>,
        }
    ];
    columns.splice(0, 1);
    useEffect(() => {
        initRegion();
    }, []);// eslint-disable-line
    function initRegion() {
        doListRegion(null, (data) => {
            setRegionList(data.resData);
        }, () => {
            setRegionList(null);
        })
    }

    return (
        <div>
            <Form
                ref={props.formRef} {...layout} initialValues={props.freight}>
                <Form.Item
                    label="运费模版ID"
                    name="id"
                    hidden="true">
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="编码"
                    name="number">
                    <Input disabled placeholder="系统自动生成"/>
                </Form.Item>
                <Form.Item
                    label="名称"
                    name="name"
                    rules={[{required: true, message: '请输入名称'}]}>
                    <Input placeholder="请输入名称"/>
                </Form.Item>
                <Form.Item
                    label="计费模式"
                    name="type"
                    rules={[{required: true, message: '请选择计费模式'}]}>
                    <Radio.Group onChange={(e) => {
                        props.setType(e.target.value);
                    }}>
                        <Radio value={0}>按件数</Radio>
                        <Radio value={1}>按重量</Radio>
                        <Radio value={2}>包邮</Radio>
                    </Radio.Group>
                </Form.Item>
                {
                    props.type === 2 ? (<Form.Item
                        label="配送规则"
                        name="ruleId"
                        hidden="true">
                        <Input/>
                    </Form.Item>) : (<Form.Item
                        label="配送规则"
                        name="ruleId">
                        <Table
                            size="small"
                            bordered
                            dataSource={dataSource}
                            columns={columns}
                            pagination={false}
                        />
                    </Form.Item>)
                }
                <Form.Item
                    label="描述"
                    name="remarks">
                    <TextArea/>
                </Form.Item>
            </Form>
        </div>
    )
}