import React, {useEffect, useState} from 'react';
import {Tabs, Form, Input, Button, message} from "antd";
import Util from "../../util/params"
import Aes from "../../util/aes";
import type {TabsProps} from 'antd';
import Constant from "../../util/constant";
import {getUserInfo, updateUserInfo, updatePassword} from "./api"

export default function UserSetting() {
    const formRef = React.createRef();
    const [userId, setUserId] = useState(null);
    const [username, setUsername] = useState(null);
    const [realName, setRealName] = useState(null);
    const [tel, setTel] = useState(null);
    const [email, setEmail] = useState(null);
    const [oldPassword, setOldPassword] = useState(null);
    const [newPassword, setNewPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);


    const layout = {
        labelCol: {span: 3},
        wrapperCol: {span: 9},
    };
    const tailLayout = {
        wrapperCol: {offset: 3, span: 9},
    };

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: `个人信息`,
            children: (
                <Form
                    {...layout}
                    ref={formRef}
                    onFinish={() => updateUser()}>
                    <Form.Item
                        label="账户"
                        name="username">
                        <Input disabled/>
                    </Form.Item>
                    <Form.Item
                        label="姓名"
                        name="realName">
                        <Input onChange={(e) => setRealName(e.target.value)}/>
                    </Form.Item>
                    <Form.Item
                        label="电话"
                        name="tel"
                        rules={[{
                            validator: async (_, value, callback) => {
                                if (Util.isTel(value)) {
                                    return Promise.resolve()
                                } else {
                                    return Promise.reject('请输入正确的手机号码');
                                }
                            }
                        }]}>
                        <Input onChange={(e) => setTel(e.target.value)}/>
                    </Form.Item>
                    <Form.Item
                        label="邮箱"
                        name="email"
                        rules={[{
                            validator: async (_, value) => {
                                if (Util.isEmail(value)) {
                                    return Promise.resolve()
                                } else {
                                    return Promise.reject('请输入正确的邮箱地址');
                                }
                            }
                        }]}>
                        <Input onChange={(e) => setEmail(e.target.value)}/>
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">
                            修改
                        </Button>
                    </Form.Item>
                </Form>
            ),
        },
        {
            key: '2',
            label: `安全信息`,
            children: (
                <Form
                    {...layout}
                    onFinish={() => updateUserPassword()}>
                    <Form.Item
                        label="原始密码"
                        name="oldPassword"
                        rules={[
                            {required: true, message: '请输入原始密码!'},
                        ]}>
                        <Input.Password onChange={(e) => setOldPassword(e.target.value)}/>
                    </Form.Item>
                    <Form.Item
                        label="新密码"
                        name="newPassword"
                        rules={[{
                            required: true, validator: async (_, value, callback) => {
                                if (Util.checkPassword(value)) {
                                    return Promise.resolve()
                                } else {
                                    return Promise.reject('密码必须由字母，数字和特殊字符组成，且长度为8-16位');
                                }
                            }
                        }]}>
                        <Input.Password onChange={(e) => setNewPassword(e.target.value)}/>
                    </Form.Item>
                    <Form.Item
                        label="确认密码"
                        name="confirmPassword"
                        rules={[
                            {required: true, message: '请输入确认密码!'},
                        ]}>
                        <Input.Password onChange={(e) => setConfirmPassword(e.target.value)}/>
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">
                            修改
                        </Button>
                    </Form.Item>
                </Form>
            ),
        }
    ];

    useEffect(() => {
        init();
    }, []);// eslint-disable-line
    function init() {
        getUserInfo((data) => {
            setUserId(data.resData.id);
            setUsername(data.resData.username);
            setRealName(data.resData.realName);
            setTel(data.resData.tel);
            setEmail(data.resData.email);

            formRef.current.setFieldsValue({
                username: data.resData.username,
                realName: data.resData.realName,
                tel: data.resData.tel,
                email: data.resData.email,
            });
        });
    }

    function updateUser() {
        const user = {
            id: userId,
            username: username,
            realName: realName,
            tel: tel,
            email: email,
        }
        updateUserInfo(user, (data) => {
            message.success(data.msg)
        });
    }

    function updateUserPassword() {
        if (newPassword !== confirmPassword) {
            message.error("确认密码与新密码不一致！");
        } else {
            const user = {
                id: userId,
                newPassword: Aes.aesEncrypt(newPassword, Constant.AES_KEY),
                oldPassword: Aes.aesEncrypt(oldPassword, Constant.AES_KEY),
            }
            updatePassword(user, (data) => {
                message.success(data.msg)
            });
        }
    }

    return (
        <Tabs defaultActiveKey="1" items={items}></Tabs>
    )
}