import React, {useEffect, useState} from "react";
import Constant from "../../../../util/constant";
import {Button, Col, Form, Image, Input, message, Row, Select, Space, Tooltip} from "antd";
import {doList, doListStatus, doMergeOrder, doPrint} from "./api";
import CommonTable from "../../../../component/CommonTable";
import OrderProductInfo from "../all/orderProductInfo";
import Util from "../../../../util/params";
import SendMergeOrderInfo from "./sendMergeOrderInfo";

export default function MergeOrderInfo() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [merging, setMerging] = useState(false);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(Constant.CURRENT_PAGE);
    const [pageSize, setPageSize] = useState(Constant.PAGE_SIZE);

    const [orderInfo, setOrderInfo] = useState(null);
    const [orderNumber, setOrderNumber] = useState(null);
    const [username, setUsername] = useState(null);
    const [realName, setRealName] = useState(null);
    const [tel, setTel] = useState(null);
    const [openOrderProductModel, setOpenOrderProductModel] = useState(false);
    const [openSendModel, setOpenSendModel] = useState(false);
    const [orderProductInfo, setOrderProductInfo] = useState(null);
    const [status, setStatus] = useState(null);
    const [statusList, setStatusList] = useState(null);
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 50,
        },
        {
            title: '单号',
            dataIndex: 'orderNumber',
            key: 'orderNumber',
            width: 200,
        },
        {
            title: '信息',
            dataIndex: 'orderInfo',
            key: 'orderInfo',
            width: 300,
            ellipsis: true,
            render: orderInfo => {
                return (
                    <Tooltip placement="topLeft" title={orderInfo}>
                        <span>{orderInfo}</span>
                    </Tooltip>
                )
            }
        },
        {
            title: '收货信息',
            dataIndex: 'receivingInformation',
            key: 'receivingInformation',
            width: 200,
            ellipsis: true,
            render: receivingInformation => {
                return (
                    <Tooltip placement="topLeft" title={receivingInformation}>
                        <span>{receivingInformation}</span>
                    </Tooltip>
                )
            }
        },
        {
            title: '条码',
            dataIndex: 'orderNumberImage',
            key: 'orderNumberImage',
            render: orderNumberImage => {
                if (!Util.isEmpty(orderNumberImage)) {
                    return (
                        <Image
                            width={25}
                            height={20}
                            src={orderNumberImage}/>
                    )
                }
            }
        },
        {
            title: '订单时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: 170,
        },
        {
            title: '订单状态',
            dataIndex: 'statusText',
            key: 'statusText',
        },
        {
            title: '订单类型',
            dataIndex: 'typeText',
            key: 'typeText',
        },
        {
            title: '总件数',
            dataIndex: 'sumQuantity',
            key: 'sumQuantity',
        },
        {
            title: '待发件数',
            dataIndex: 'sumWaitSendQuantity',
            key: 'sumWaitSendQuantity',
        },
        {
            title: '快递公司',
            dataIndex: 'logisticsCompany',
            key: 'logisticsCompany',
        },
        {
            title: '快递编号',
            dataIndex: 'logisticsNumber',
            key: 'logisticsNumber',
            width: 200,
            ellipsis: true,
        },
        {
            title: '快递面单',
            dataIndex: 'logisticsLabelImage',
            key: 'logisticsLabelImage',
            render: logisticsLabelImage => {
                if (!Util.isEmpty(logisticsLabelImage)) {
                    let images = logisticsLabelImage.split(",");
                    return (
                        <Image.PreviewGroup
                            items={images}>
                            <Image
                                width={25}
                                height={20}
                                src={images[0]}/>
                        </Image.PreviewGroup>
                    )
                }
            }
        },
        {
            title: '操作',
            key: 'action',
            fixed: 'right',
            width: 240,
            render: (text, record) => {
                if (record.children) {
                    return (
                        <Space size="small" style={{"float": "right"}}>
                            <a onClick={() => orderProduct(record)}>商品详情</a>
                            <a onClick={() => print(record)}>打印条码</a>
                            <a onClick={() => copyAddress(record)}>复制地址</a>
                            {
                                record.status !== 1 ? (<a onClick={() => send(record)}>发货</a>) : null
                            }
                        </Space>
                    )
                }
            },
        },
    ];
    columns.splice(0, 1)
    useEffect(() => {
        init(currentPage, pageSize);
        initStatus();
    }, []);// eslint-disable-line
    function init(current, page) {
        doList({
            status: status,
            orderNumber: orderNumber,
            username: username,
            realName: realName,
            tel: tel,
            currentPage: current === undefined ? currentPage : current,
            pageSize: page === undefined ? pageSize : page
        }, (data) => {
            setData(data.resData.result);
            setLoading(false);
            setTotal(data.resData.total);
        }, () => {
            setData(null);
            setLoading(false);
        })
    }

    function initStatus() {
        doListStatus((data) => {
            setStatusList(data.resData);
        }, () => {
            setStatusList(null);
        })
    }

    function resetPage(currentPage, pageSize) {
        setCurrentPage(currentPage);
        setPageSize(pageSize);
    }

    function orderProduct(record) {
        let orderProductInfos = [];
        if (record.productVOList.length > 0) {
            for (let i = 0; i < record.productVOList.length; i++) {
                let orderProductInfo = {};
                orderProductInfo.waitSendQuantity = record.productVOList[i].waitSendQuantity;
                orderProductInfo.purchaseQuantity = record.productVOList[i].sumQuantity;
                orderProductInfo.number = record.productVOList[i].number;
                orderProductInfo.name = record.productVOList[i].name;
                orderProductInfo.image = record.productVOList[i].image;
                orderProductInfo.color = record.productVOList[i].color;
                orderProductInfo.spec = record.productVOList[i].spec;
                orderProductInfo.size = record.productVOList[i].size;
                orderProductInfo.orderNumber = record.productVOList[i].number;
                orderProductInfo.title = record.productVOList[i].name;
                orderProductInfos.push(orderProductInfo);
            }

        }
        setOrderProductInfo(orderProductInfos);
        setOpenOrderProductModel(true);
    }

    function print(record) {
        // 调用打印机打印
        let ids = [];
        ids.push(record.id);
        let barcodeDTO = {};
        barcodeDTO.ids = ids;
        doPrint(barcodeDTO, (res) => {
            if (res.code === 200) {
                message.info(res.msg)
            } else {
                message.error(res.msg)
            }
        })
    }

    async function copyAddress(record) {
        try {
            await navigator.clipboard.writeText(record.realName + "\n" + record.tel + "\n" + record.address);
            message.success("复制成功");
        } catch (error) {
            console.error('Failed to copy text: ', error);
        }
    }

    function send(record) {
        setOrderInfo(record);
        setOpenSendModel(true);
    }

    return (
        <div>
            <Form>
                <Row gutter={[16, 0]}>
                    <Col span={6}>
                        <Form.Item
                            label="订单编号"
                            name="orderNumber">
                            <Input placeholder="请输入订单编号" onChange={(e) => setOrderNumber(e.target.value)}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="收货人"
                            name="realName">
                            <Input placeholder="请输入收货人" onChange={(e) => setRealName(e.target.value)}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="联系电话"
                            name="tel">
                            <Input placeholder="请输入联系电话" onChange={(e) => setTel(e.target.value)}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="收货地址"
                            name="tel">
                            <Input placeholder="请输入收货地址" onChange={(e) => setTel(e.target.value)}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 0]}>
                    <Col span={6}>
                        <Form.Item
                            label="订单状态"
                            name="status">
                            <Select onChange={(value, option) => {
                                setStatus(option.key)
                            }}>
                                {
                                    statusList != null ?
                                        statusList.map(item => {
                                            return (<Select.Option key={item.status}
                                                                   value={item.status}>{item.statusText}</Select.Option>)
                                        }) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item>
                            <Button type="primary" onClick={() => {
                                setLoading(true);
                                init(Constant.CURRENT_PAGE, Constant.PAGE_SIZE);
                            }}>查询</Button>
                            <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                                    onClick={() => {
                                        setOrderNumber(null);
                                        setUsername(null);
                                        setRealName(null);
                                        setTel(null);
                                        setStatus(null);
                                    }}>重置</Button>
                            <Button type="primary" style={{'marginLeft': '10px'}} disabled={merging} onClick={() => {
                                setMerging(true)
                                doMergeOrder(null, () => {
                                    init(Constant.CURRENT_PAGE, Constant.PAGE_SIZE);
                                    setMerging(false);
                                }, () => {
                                    init(Constant.CURRENT_PAGE, Constant.PAGE_SIZE);
                                    setMerging(false);
                                }, () => {
                                    setMerging(false);
                                });
                            }}>合并订单</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <CommonTable
                columns={columns}
                rowKey={columns => columns.id}
                data={data}
                width={2500}
                loading={loading}
                total={total}
                currentPage={currentPage}
                pageSize={pageSize}
                initData={(currentPage, pageSize) => init(currentPage, pageSize)}
                resetPage={(currentPage, pageSize) => resetPage(currentPage, pageSize)}/>
            <OrderProductInfo open={openOrderProductModel} setOpenOrderProductModel={setOpenOrderProductModel}
                              data={orderProductInfo}
                              init={init}/>
            <SendMergeOrderInfo open={openSendModel} setOpenSendModel={setOpenSendModel}
                                orderInfo={orderInfo}
                                init={init}/>
        </div>
    )
}