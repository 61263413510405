import React, {useEffect, useState} from "react";
import Constant from "../../../../../util/constant";
import {Button, Form, Input, Select, Space} from "antd";
import {doDel, doList, disable, enable, doInitPosition, previewBarcode} from "./api";
import CommonTable from "../../../../../component/CommonTable";
import {doInitStatus} from "../../../../../common/api";
import AddTemplate from "./addTemplate";
import ViewTemplate from "./viewTemplate";
import EditTemplate from "./editTemplate";
import PreviewBarcode from "./previewBarcode";

export default function BarcodeTemplate() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(Constant.CURRENT_PAGE);
    const [pageSize, setPageSize] = useState(Constant.PAGE_SIZE);

    const [name, setName] = useState(null);
    const [status, setStatus] = useState(null);
    const [previewBarcodeImageUrl, setPreviewBarcodeImageUrl] = useState(null);
    const [statusList, setStatusList] = useState(null);
    const [positionList, setPositionList] = useState(null);
    const [barcodeTemplate, setBarcodeTemplate] = useState(null);
    const [openAddModel, setOpenAddModel] = useState(false);
    const [openEditModel, setOpenEditModel] = useState(false);
    const [openViewModel, setOpenViewModel] = useState(false);
    const [openPreviewBarcodeModel, setOpenPreviewBarcodeModel] = useState(false);

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 50,
        },
        {
            title: '编码',
            dataIndex: 'number',
            key: 'number',
        },
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '图片宽度',
            dataIndex: 'imageW',
            key: 'imageW',
        },
        {
            title: '图片高度',
            dataIndex: 'imageH',
            key: 'imageH',
        },
        {
            title: '条码宽度',
            dataIndex: 'codeW',
            key: 'codeW',
        },
        {
            title: '条码高度',
            dataIndex: 'codeH',
            key: 'codeH',
        },
        {
            title: '左边距',
            dataIndex: 'codeLeftMargin',
            key: 'codeLeftMargin',
        },
        {
            title: '上边距',
            dataIndex: 'codeTopMargin',
            key: 'codeTopMargin',
        },
        {
            title: '条码位置',
            dataIndex: 'codePositionText',
            key: 'codePositionText',
        },
        {
            title: '条码文字位置',
            dataIndex: 'codeCharactersPositionText',
            key: 'codeCharactersPositionText',
        },
        {
            title: '条码文字字号',
            dataIndex: 'codeCharactersSize',
            key: 'codeCharactersSize',
        },
        {
            title: '文字字号',
            dataIndex: 'charactersSize',
            key: 'charactersSize',
        },
        // {
        //     title: '显示商品供应商',
        //     dataIndex: 'showSupplierText',
        //     key: 'showSupplierText',
        // },
        // {
        //     title: '显示商品品牌',
        //     dataIndex: 'showBrandText',
        //     key: 'showBrandText',
        // },
        {
            title: '显示SKU码',
            dataIndex: 'showCodeText',
            key: 'showCodeText',
        },
        {
            title: '显示商品名称',
            dataIndex: 'showCommodityText',
            key: 'showCommodityText',
        },
        // {
        //     title: '显示商品类别',
        //     dataIndex: 'showCategoryText',
        //     key: 'showCategoryText',
        // },
        {
            title: '显示商品颜色',
            dataIndex: 'showColorText',
            key: 'showColorText',
        },
        {
            title: '显示商品大小',
            dataIndex: 'showSizeText',
            key: 'showSizeText',
        },
        {
            title: '显示商品规格',
            dataIndex: 'showSpecText',
            key: 'showSpecText',
        },
        // {
        //     title: '显示商品重量',
        //     dataIndex: 'showWeightText',
        //     key: 'showWeightText',
        // },
        // {
        //     title: '显示商品单位',
        //     dataIndex: 'showUnitText',
        //     key: 'showUnitText',
        // },
        {
            title: '状态',
            dataIndex: 'statusText',
            key: 'statusText',
        },
        {
            title: '描述',
            dataIndex: 'remarks',
            key: 'remarks',
        },
        {
            title: '创建时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: 170,
        },
        {
            title: '创建人',
            dataIndex: 'creator',
            key: 'creator',
        },
        {
            title: '最近修改时间',
            dataIndex: 'gmtUpdate',
            key: 'gmtUpdate',
            width: 170,
        },
        {
            title: '最近修改人',
            dataIndex: 'modifier',
            key: 'modifier',
        },
        {
            title: '操作',
            key: 'action',
            fixed: 'right',
            width: 230,
            render: (text, record) => (
                <Space size="small">
                    <a onClick={() => view(record)}>查看</a>
                    <a onClick={() => edit(record)}>编辑</a>
                    <a onClick={() => {
                        if (record.status === 0) {
                            disable(record, () => init())
                        } else {
                            enable(record, () => init())
                        }
                    }} style={{
                        'color': '#eea236'
                    }}>{record.status === 0 ? "禁用" : "启用"}</a>
                    <a style={{'color': '#d43f3a'}} onClick={() => doDel(record, () => {
                        init()
                    })}>删除</a>
                    <a onClick={() => preview(record)}>预览条形码</a>
                </Space>
            ),
        },
    ];
    columns.splice(0, 1)
    useEffect(() => {
        init(currentPage, pageSize);
        initStatus();
        initPosition();
    }, []);// eslint-disable-line
    function init(current, page) {
        doList({
            name: name,
            status: status,
            currentPage: current === undefined ? currentPage : current,
            pageSize: page === undefined ? pageSize : page
        }, (data) => {
            setData(data.resData.result);
            setLoading(false);
            setTotal(data.resData.total);
        }, () => {
            setData(null);
            setLoading(false);
        })
    }

    function initStatus() {
        doInitStatus((data) => {
            setStatusList(data.resData);
        }, () => {
            setStatusList(null);
        })
    }

    function initPosition() {
        doInitPosition((data) => {
            setPositionList(data.resData);
        }, () => {
            setPositionList(null);
        })
    }

    function add() {
        setOpenAddModel(true);
    }

    function edit(record) {
        setBarcodeTemplate(record);
        setOpenEditModel(true);
    }

    function view(record) {
        setBarcodeTemplate(record);
        setOpenViewModel(true);
    }

    function preview(record) {
        previewBarcode(record, (data) => {
            let blob = new Blob([data], {type: "image/png"});
            let url = window.URL.createObjectURL(blob);
            setPreviewBarcodeImageUrl(url);
            setOpenPreviewBarcodeModel(true);
        })
    }

    function resetPage(currentPage, pageSize) {
        setCurrentPage(currentPage);
        setPageSize(pageSize);
    }

    return (
        <div>
            <Form layout='inline'>
                <Form.Item
                    label="名称"
                    name="name">
                    <Input placeholder="请输入模版名称" onChange={(e) => setName(e.target.value)}/>
                </Form.Item>
                <Form.Item
                    label="状态"
                    name="status"
                    style={{width: 120}}>
                    <Select onChange={(value, option) => {
                        setStatus(option.key)
                    }}>
                        {
                            statusList != null ?
                                statusList.map(item => {
                                    return (<Select.Option key={item.status}
                                                           value={item.status}>{item.statusText}</Select.Option>)
                                }) : null
                        }
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" onClick={() => {
                        setLoading(true);
                        init(Constant.CURRENT_PAGE, Constant.PAGE_SIZE);
                    }}>查询</Button>
                    <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                            onClick={() => {
                                setName(null);
                                setStatus(null);
                            }}>重置</Button>
                    <Button type="primary" style={{'marginLeft': '10px'}} onClick={() => add()}>新增模版</Button>
                </Form.Item>
            </Form>
            <CommonTable
                columns={columns}
                rowKey={columns => columns.id}
                data={data}
                width={4500}
                loading={loading}
                total={total}
                currentPage={currentPage}
                pageSize={pageSize}
                initData={(currentPage, pageSize) => init(currentPage, pageSize)}
                resetPage={(currentPage, pageSize) => resetPage(currentPage, pageSize)}/>
            <AddTemplate open={openAddModel}
                         setOpenAddModel={setOpenAddModel}
                         positionList={positionList}
                         init={init}/>
            <EditTemplate open={openEditModel}
                          positionList={positionList}
                          setOpenEditModel={setOpenEditModel}
                          barcodeTemplate={barcodeTemplate}
                          init={init}/>
            <ViewTemplate open={openViewModel}
                          setOpenViewModel={setOpenViewModel}
                          barcodeTemplate={barcodeTemplate}
                          positionList={positionList}
                          init={init}/>
            <PreviewBarcode open={openPreviewBarcodeModel}
                            setOpenPreviewBarcodeModel={setOpenPreviewBarcodeModel}
                            previewBarcodeImageUrl={previewBarcodeImageUrl}/>
        </div>
    )
}