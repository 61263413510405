import {ExclamationCircleOutlined} from '@ant-design/icons';
import React from "react";
import HttpUtil from "../../../../../util/httpUtil";

/**
 * 获取上架状态
 * @param successFun
 * @param errorFun
 */
export const doInitStatus = function (successFun, errorFun) {
    HttpUtil.doGet(
        "/commodity/sold/listStatus",
        null,
        successFun, errorFun);
}
/**
 * 上架商品信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doAddSaleProduct = function (params, successFun, errorFun) {
    HttpUtil.doPost("/commodity/sold/addSaleProduct",
        params, successFun, errorFun);
}
/**
 * 修改上架商品信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doUpdateSaleProduct = function (params, successFun, errorFun) {
    HttpUtil.doPost("/commodity/sold/updateSaleProduct",
        params, successFun, errorFun);
}
/**
 * 获取商品信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const getSaleProductInfo = function (params, successFun, errorFun) {
    HttpUtil.doPost("/commodity/sold/getSaleProductInfo",
        params, successFun, errorFun);
}
/**
 * 获取上架商品信息列表
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doList = function (params, successFun, errorFun) {
    HttpUtil.doGet(
        "/commodity/sold/listSaleProduct",
        params,
        successFun, errorFun);
}
/**
 * 删除上架商品信息
 * @param record
 * @param successFun
 * @param errorFun
 */
export const doDel = function (record, successFun, errorFun) {
    HttpUtil.doDelete(<ExclamationCircleOutlined/>, "删除商品",
        "确认删除" + record.title + "信息？",
        "/commodity/sold/delete/" + record.id, null, successFun, errorFun);
}
/**
 * 上架商品信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doAdd = function (params, successFun, errorFun) {
    HttpUtil.doPost("/commodity/sold/add",
        params, successFun, errorFun);
}
/**
 * 更新上架商品信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doUpdate = function (params, successFun, errorFun) {
    HttpUtil.doPut("/commodity/sold/update",
        params, successFun, errorFun);
}
/**
 * 下架商品
 * @param record
 * @param successFun
 * @param errorFun
 */
export const disable = function (record, successFun, errorFun) {
    HttpUtil.doPutConfirm(<ExclamationCircleOutlined/>, "下架商品", "确认下架" + record.title + "？",
        "/commodity/sold/disable/" + record.id, null, successFun, errorFun);
}
/**
 * 上架
 * @param record
 * @param successFun
 * @param errorFun
 */
export const enable = function (record, successFun, errorFun) {
    HttpUtil.doPutConfirm(<ExclamationCircleOutlined/>, "上架商品", "确认上架" + record.title + "？",
        "/commodity/sold/enable/" + record.id, null, successFun, errorFun);
}

