import {ExclamationCircleOutlined} from '@ant-design/icons';
import React from "react";
import HttpUtil from "../../../../../util/httpUtil";

/**
 * 获取供应商信息列表
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doList = function (params, successFun, errorFun) {
    HttpUtil.doGet(
        "/commodity/supplier/list",
        params,
        successFun, errorFun);
}
/**
 * 删除供应商信息
 * @param record
 * @param successFun
 * @param errorFun
 */
export const doDel = function (record, successFun, errorFun) {
    HttpUtil.doDelete(<ExclamationCircleOutlined/>, "删除供应商",
        "确认删除" + record.name + "信息？",
        "/commodity/supplier/delete/" + record.id, null, successFun, errorFun);
}
/**
 * 新增供应商信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doAdd = function (params, successFun, errorFun) {
    HttpUtil.doPost("/commodity/supplier/add",
        params, successFun, errorFun);
}
/**
 * 更新供应商信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doUpdate = function (params, successFun, errorFun) {
    HttpUtil.doPut("/commodity/supplier/update",
        params, successFun, errorFun);
}
/**
 * 禁用供应商
 * @param record
 * @param successFun
 * @param errorFun
 */
export const disable = function (record, successFun, errorFun) {
    HttpUtil.doPutConfirm(<ExclamationCircleOutlined/>, "禁用供应商", "确认禁用" + record.name + "？",
        "/commodity/supplier/disable/" + record.id, null, successFun, errorFun);
}
/**
 * 启用供应商
 * @param record
 * @param successFun
 * @param errorFun
 */
export const enable = function (record, successFun, errorFun) {
    HttpUtil.doPutConfirm(<ExclamationCircleOutlined/>, "启用供应商", "确认启用" + record.name + "？",
        "/commodity/supplier/enable/" + record.id, null, successFun, errorFun);
}

