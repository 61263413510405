import {Form, Input, Select, Spin} from "antd";
import React, {useEffect, useState} from "react";
import Content from "../../../../../util/constant";
import BraftEditor from "braft-editor";
import {doTypeList} from "./api";

const {TextArea} = Input;

export default function AgreementForm(props) {
    const [typeList, setTypeList] = useState(null);
    const [detailsContent, setDetailsContent] = useState(BraftEditor.createEditorState(null));
    useEffect(() => {
        init();
        initType();
    }, []);// eslint-disable-line
    const layout = {
        labelCol: {span: 4},
        wrapperCol: {span: 20},
    };

    function init() {
        if (props.agreement !== undefined && props.agreement.content !== undefined && props.agreement.content !== null) {
            setDetailsContent(BraftEditor.createEditorState(props.agreement.content));
        }
    }

    function fileUpload(param) {

        const serverURL = Content.BASE_URL + "/uploadFile";
        const xhr = new XMLHttpRequest();
        const fd = new FormData();

        const successFn = (response) => {
            // 假设服务端直接返回文件上传后的地址
            // 上传成功后调用param.success并传入上传后的文件地址
            param.success({
                url: xhr.responseText,
                meta: {
                    loop: true, // 指定音视频是否循环播放
                    autoPlay: true, // 指定音视频是否自动播放
                    controls: true, // 指定音视频是否显示控制栏
                }
            })
        }

        const progressFn = (event) => {
            // 上传进度发生变化时调用param.progress
            param.progress(event.loaded / event.total * 100)
        }

        const errorFn = (response) => {
            // 上传发生错误时调用param.error
            param.error({
                msg: 'unable to upload.'
            })
        }
        xhr.withCredentials = true;
        xhr.upload.addEventListener("progress", progressFn, false)
        xhr.addEventListener("load", successFn, false)
        xhr.addEventListener("error", errorFn, false)
        xhr.addEventListener("abort", errorFn, false)

        fd.append('file', param.file)
        xhr.open('POST', serverURL, true)
        xhr.send(fd)

    }

    function fileValidate(file) {
        return file.size < 1024 * 1024 * 20;
    }

    function handleEditorChange(v) {
        setDetailsContent(v);
        if (props.formRef.current != null) {
            props.formRef.current.setFieldsValue({
                content: v.toHTML()
            });
        }
    }

    function initType() {
        doTypeList(null, (data) => {
            setTypeList(data.resData);
        }, () => {
            setTypeList(null);
        })
    }

    return (
        <div>
            <Form
                ref={props.formRef} {...layout} initialValues={props.agreement}>
                <Form.Item
                    label="协议ID"
                    name="id"
                    hidden="true">
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="标题"
                    name="title"
                    rules={[{required: true, message: '请输入标题'}]}>
                    <Input placeholder="请输入标题"/>
                </Form.Item>
                <Form.Item
                    label="类型"
                    name="type"
                    optionFilterProp="label">
                    <Select>
                        {
                            typeList != null ?
                                typeList.map(item => {
                                    return (<Select.Option key={item.type}
                                                           value={item.type}
                                                           label={item.typeText}>{item.typeText}</Select.Option>)
                                }) : null
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    label="内容"
                    name="content"
                    rules={[{required: true, message: '请输入内容'}]}>
                    <div className="editor-wrapper" style={{
                        "borderStyle": "solid",
                        "borderWidth": "1px",
                        "borderColor": "#dddddd",
                        "borderRadius": "5px",
                        "margin": "0px"
                    }}>
                        <Spin spinning={false}>
                            <BraftEditor
                                media={{uploadFn: fileUpload, validateFn: fileValidate}}
                                value={detailsContent}
                                placeholder="请输入内容"
                                onChange={(v) => handleEditorChange(v)}/>
                        </Spin>
                    </div>
                </Form.Item>
                <Form.Item
                    label="描述"
                    name="remarks">
                    <TextArea/>
                </Form.Item>
            </Form>
        </div>
    )
}