import HttpUtil from "../../../../util/httpUtil";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import React from "react";

/**
 * 获取直播视频信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doList = function (params, successFun, errorFun) {
    HttpUtil.doGet(
        "/video/list",
        params,
        successFun, errorFun);
}
/**
 * 新增视频直播信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doAdd = function (params, successFun, errorFun) {
    HttpUtil.doPost("/video/add",
        params, successFun, errorFun);
}
/**
 * 获取直播视频类型
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doListType = function (successFun, errorFun) {
    HttpUtil.doGet(
        "/video/listType",
        null,
        successFun, errorFun);
}
/**
 * 删除直播视频信息
 * @param record
 * @param successFun
 * @param errorFun
 */
export const doDel = function (record, successFun, errorFun) {
    HttpUtil.doDelete(<ExclamationCircleOutlined/>, "删除视频",
        "确认删除" + record.title + "视频信息？",
        "/video/delete/" + record.id, null, successFun, errorFun);
}