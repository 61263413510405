import {Upload, Form, Input, Radio, message} from "antd";
import React, {useState} from "react";
import Content from "../../../../../util/constant";
import {PlusOutlined} from "@ant-design/icons";
import {doDeleteFile} from "../../../../../common/api";

const {TextArea} = Input;

export default function PaymentQrCodeForm(props) {
    const [imageFileList, setImageFileList] = useState([]);
    const layout = {
        labelCol: {span: 4},
        wrapperCol: {span: 20},
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };
    return (
        <div>
            <Form
                ref={props.formRef} {...layout}>
                <Form.Item
                    label="收款码ID"
                    name="id"
                    hidden="true">
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="收款类别"
                    name="type"
                    rules={[{required: true, message: '请输入收款类别'}]}>
                    <Input placeholder="请输入收款类别"/>
                </Form.Item>
                <Form.Item
                    label="默认收款类别"
                    name="isDefault"
                    initialValue={0}
                    rules={[{required: true, message: '请选择默认收款类别'}]}>
                    <Radio.Group>
                        <Radio value={0}>否</Radio>
                        <Radio value={1}>是</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label="收款二维码"
                    name="imageFiles"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    rules={[{required: true, message: '请上传商品图片'}]}>
                    <Upload
                        action={Content.BASE_URL + "/uploadFile"}
                        name='file'
                        withCredentials={true}
                        accept='.png,.jpg,.jpeg,.tiff'
                        listType="picture-card"
                        className="avatar-uploader"
                        fileList={imageFileList}
                        maxCount={1}
                        multiple={true}
                        beforeUpload={(file) => {
                            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
                                || file.type === 'image/jpg' || file.type === 'image/tiff';
                            const isLt2M = file.size / 1024 / 1024 < 20;
                            return new Promise((resolve, reject) => {
                                if (!isJpgOrPng) {
                                    message.error('文件格式不正常！');
                                    reject();
                                } else if (!isLt2M) {
                                    message.error('文件大小最大20M！');
                                    reject();
                                } else {
                                    resolve(file);
                                }
                            });
                        }}
                        onChange={(options) => {
                            const {fileList} = options;
                            let imageList = [];
                            for (let i = 0; i < fileList.length; i++) {
                                if (fileList[i].response !== undefined) {
                                    imageList[i] = fileList[i].response;
                                } else if (fileList[i].response === undefined && fileList[i].url !== undefined) {
                                    imageList[i] = fileList[i].url;
                                }

                            }
                            if (imageList.length > 0) {
                                setImageFileList(imageList);
                            }
                        }}
                        onRemove={(file) => {
                            if (file.response !== undefined) {
                                doDeleteFile({
                                    url: file.response
                                }, () => {
                                    let imageFiles = imageFileList;
                                    imageFiles = imageFiles.filter(item => item !== file.response);
                                    setImageFileList(imageFiles);
                                }, null);
                            }
                        }}>
                        <div>
                            <PlusOutlined style={{"fontSize": "32px", "color": "#cccccc"}}/>
                            <div style={{marginTop: 8}}>上传图片</div>
                        </div>
                    </Upload>
                </Form.Item>
                <Form.Item
                    label="描述"
                    name="remarks">
                    <TextArea/>
                </Form.Item>
            </Form>
        </div>
    )
}