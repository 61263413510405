import {ExclamationCircleOutlined} from '@ant-design/icons';
import React from "react";
import HttpUtil from "../../../../../util/httpUtil";

/**
 * 获取产品信息列表
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doList = function (params, successFun, errorFun) {
    HttpUtil.doGet(
        "/commodity/product/list",
        params,
        successFun, errorFun);
}

/**
 * 获取没有条码的产品信息列表
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doNoBarcodeList = function (params, successFun, errorFun) {
    HttpUtil.doGet(
        "/commodity/product/listNoBarcode",
        params,
        successFun, errorFun);
}
/**
 * 删除产品信息
 * @param record
 * @param successFun
 * @param errorFun
 */
export const doDel = function (record, successFun, errorFun) {
    HttpUtil.doDelete(<ExclamationCircleOutlined/>, "删除产品",
        "确认删除" + record.name + "信息？",
        "/commodity/product/delete/" + record.id, null, successFun, errorFun);
}
/**
 * 新增产品信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doAdd = function (params, successFun, errorFun) {
    HttpUtil.doPost("/commodity/product/add",
        params, successFun, errorFun);
}
/**
 * 更新产品信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doUpdate = function (params, successFun, errorFun) {
    HttpUtil.doPut("/commodity/product/update",
        params, successFun, errorFun);
}
/**
 * 禁用产品
 * @param record
 * @param successFun
 * @param errorFun
 */
export const disable = function (record, successFun, errorFun) {
    HttpUtil.doPutConfirm(<ExclamationCircleOutlined/>, "禁用产品", "确认禁用" + record.name + "？",
        "/commodity/product/disable/" + record.id, null, successFun, errorFun);
}
/**
 * 启用产品
 * @param record
 * @param successFun
 * @param errorFun
 */
export const enable = function (record, successFun, errorFun) {
    HttpUtil.doPutConfirm(<ExclamationCircleOutlined/>, "启用产品", "确认启用" + record.name + "？",
        "/commodity/product/enable/" + record.id, null, successFun, errorFun);
}

