import React from "react";
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {ConfigProvider} from "antd";
import zhCN from 'antd/es/locale/zh_CN';
import NavigateComponent from "../common/NavigateComponent";
import Login from "../app/login/login";
import Home from "./home/home";
import UserSetting from "./head/userSetting"
import Admin from "./head/admin/admin";
import WarehouseTab from "./home/warehouse/information/warehouseTab";
import InformationTab from "./home/commodity/information/informationTab";
import ManageTab from "./home/commodity/manage/manageTab";
import BarcodeTab from "./home/barcode/setting/barcodeTab";
import WarehousingTab from "./home/warehouse/warehousing/warehousingTab";
import InventoryTab from "./home/commodity/inventory/inventoryTab";
import ShopBaseTab from "./home/shop/base/baseTab";
import ShopLogisticsTab from "./home/shop/logistics/logisticsTab";
import CustomerTab from "./home/custom/customerTab";
import OrderTab from "./home/order/orderTab";
import VideoTab from "./home/video/videoTab";
import PageTab from "./home/page/pageTab";

export default function App() {
    return (
        <ConfigProvider locale={zhCN}>
            <BrowserRouter>
                <Routes>
                    <Route exact path="/" element={<NavigateComponent element={Home}/>}></Route>
                    <Route exact path="/home" element={<NavigateComponent element={Home}/>}>
                        <Route exact path="/home/data"
                               element={<NavigateComponent element={PageTab}/>}></Route>
                        <Route exact path="/home/userSetting"
                               element={<NavigateComponent element={UserSetting}/>}></Route>
                        <Route exact path="/home/admin"
                               element={<NavigateComponent element={Admin}/>}></Route>
                        <Route exact path="/home/warehouse/information"
                               element={<NavigateComponent element={WarehouseTab}/>}></Route>
                        <Route exact path="/home/warehouse/warehousing"
                               element={<NavigateComponent element={WarehousingTab}/>}></Route>
                        <Route exact path="/home/commodity/information"
                               element={<NavigateComponent element={InformationTab}/>}></Route>
                        <Route exact path="/home/commodity/inventory"
                               element={<NavigateComponent element={InventoryTab}/>}></Route>
                        <Route exact path="/home/commodity/manage"
                               element={<NavigateComponent element={ManageTab}/>}></Route>
                        <Route exact path="/home/barcode/setting"
                               element={<NavigateComponent element={BarcodeTab}/>}></Route>
                        <Route exact path="/home/shop/base"
                               element={<NavigateComponent element={ShopBaseTab}/>}></Route>
                        <Route exact path="/home/shop/logistics"
                               element={<NavigateComponent element={ShopLogisticsTab}/>}></Route>
                        <Route exact path="/home/customer"
                               element={<NavigateComponent element={CustomerTab}/>}></Route>
                        <Route exact path="/home/order"
                               element={<NavigateComponent element={OrderTab}/>}></Route>
                        <Route exact path="/home/video"
                               element={<NavigateComponent element={VideoTab}/>}></Route>
                    </Route>
                    <Route path="/login" element={<NavigateComponent element={Login}/>}></Route>
                </Routes>
            </BrowserRouter>
        </ConfigProvider>
    )
}
