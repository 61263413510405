import {Form, Input, Select} from "antd";
import React from "react";

const {TextArea} = Input;
export default function UserForm(props) {
    const layout = {
        labelCol: {span: 4},
        wrapperCol: {span: 20},
    };
    return (
        <div>
            <Form
                ref={props.formRef} {...layout}>
                <Form.Item
                    label="账户ID"
                    name="id"
                    hidden="true">
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="账户名称"
                    name="username"
                    rules={[{required: true, message: '请输入账户名称'}]}>
                    <Input placeholder="请输入账户名称"
                           disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                </Form.Item>
                <Form.Item
                    label="用户姓名"
                    name="realName"
                    rules={[{required: true, message: '请输入用户姓名'}]}>
                    <Input placeholder="请输入用户姓名"/>
                </Form.Item>
                <Form.Item
                    label="账户类别"
                    name="type"
                    rules={[{required: true, message: '请选择账户类别'}]}>
                    <Select
                        placeholder="请选择账户类别"
                        showSearch
                        allowClear
                        optionFilterProp="label">
                        {
                            props.userTypeList != null ?
                                props.userTypeList.map(item => {
                                    return (<Select.Option key={item.type}
                                                           value={item.type}
                                                           label={item.typeText}>{item.typeText}</Select.Option>)
                                }) : null
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    label="账户备注"
                    name="remarks">
                    <TextArea/>
                </Form.Item>
            </Form>
        </div>
    )
}