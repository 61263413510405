import React from "react";
import CommonTable from "../../../../component/CommonTable";
import CommonModal from "../../../../component/CommonModal";
import Util from "../../../../util/params";
import {Image} from "antd";

export default function OrderProductInfo(props) {
    const columns = [
        {
            title: '编号',
            dataIndex: 'number',
            key: 'number',
        },
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '规格',
            dataIndex: 'spec',
            key: 'spec',
        },
        {
            title: '颜色',
            dataIndex: 'color',
            key: 'color',
        },
        {
            title: '大小',
            dataIndex: 'size',
            key: 'size',
        },
        {
            title: '图片',
            dataIndex: 'image',
            key: 'image',
            render: image => {
                if (!Util.isEmpty(image)) {
                    return (
                        <Image
                            width={25}
                            height={20}
                            src={image}/>
                    )
                }
            }
        },
        {
            title: '总数',
            dataIndex: 'purchaseQuantity',
            key: 'purchaseQuantity',
        },
        {
            title: '待发货',
            dataIndex: 'waitSendQuantity',
            key: 'waitSendQuantity',
        }
    ];

    function handleCancel() {
        props.setOpenOrderProductModel(false);
    }

    return (
        <div>
            <CommonModal
                title="订单商品信息"
                open={props.open}
                onOk={() => handleCancel()}
                onCancel={() => handleCancel()}
                destroyOnClose={true}
                width={700}
                form={
                    <div>
                        {
                            props.data != null ? (
                                "编号：" + props.data[0].orderNumber + "\t标题：" + props.data[0].title
                            ) : null
                        }
                        <CommonTable
                            columns={columns}
                            rowKey={columns => columns.number}
                            data={props.data}
                            closePagination={true}/>
                    </div>}/>
        </div>
    )
}