import HttpUtil from "../../../../../util/httpUtil";

/**
 * 获取商品库存信息列表
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doList = function (params, successFun, errorFun) {
    HttpUtil.doGet(
        "/commodity/inventory/list",
        params,
        successFun, errorFun);
}
/**
 * 更新库存信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doUpdate = function (params, successFun, errorFun) {
    HttpUtil.doPut("/commodity/inventory/update",
        params, successFun, errorFun);
}

