import React, {useState} from "react";
import CommonModal from "../../../../component/CommonModal";
import UserForm from "./userForm";
import {addUserInfo} from "./api";

export default function AddUser(props) {
    const addFormRef = React.createRef();
    const [addSubmitLoading, setAddSubmitLoading] = useState(false);


    function add() {
        if (!addSubmitLoading) {
            addFormRef.current.validateFields().then(values => {
                setAddSubmitLoading(true)
                addUserInfo({
                    username: values.username,
                    realName: values.realName,
                    type: values.type,
                    remarks: values.remarks
                }, (data) => {
                    setAddSubmitLoading(false);
                    clearInterval(addFormRef.current);
                    props.setOpenAddModel(false);
                    props.init();
                }, () => {
                    setAddSubmitLoading(false);
                })
            }).catch(e => {
                console.log(e);
            })
        }
    }

    function handleCancel() {
        props.setOpenAddModel(false);
    }

    return (
        <CommonModal
            title="新增账户"
            open={props.open}
            onOk={() => add()}
            onCancel={() => handleCancel()}
            destroyOnClose={true}
            confirmLoading={addSubmitLoading}
            form={<UserForm formRef={addFormRef} userTypeList={props.userTypeList}/>}/>
    )
}