import {Tabs} from "antd";
import React from "react";
import type {TabsProps} from "antd";
import User from "./user/user";
import Role from "./role/role";

export default function Admin() {
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: `账户管理`,
            children: <User/>,
        },
        {
            key: '2',
            label: `角色管理`,
            children: <Role/>,
        }
    ];

    return (
        <div>
            <Tabs defaultActiveKey="1" items={items}></Tabs>
        </div>
    )
}