import CommonModal from "../../../../../component/CommonModal";
import React, {useState} from "react";
import {message} from "antd";
import PaymentQrCodeForm from "./paymentQrCodeForm";
import {doAdd} from "./api";

export default function AddPaymentQrCode(props) {
    const addFormRef = React.createRef();
    const [addSubmitLoading, setAddSubmitLoading] = useState(false);

    function add() {
        if (!addSubmitLoading) {
            addFormRef.current.validateFields().then(values => {
                setAddSubmitLoading(true)
                doAdd({
                    type: values.type,
                    isDefault: values.isDefault,
                    qrCodeImage: values.imageFiles.length > 0 ? values.imageFiles[0].response : null,
                    remarks: values.remarks
                }, (data) => {
                    setAddSubmitLoading(false);
                    clearInterval(addFormRef.current);
                    props.setOpenAddModel(false);
                    props.init();
                }, () => {
                    setAddSubmitLoading(false);
                })
            }).catch(e => {
                console.log(e);
                message.error("必填项未填！")
                setAddSubmitLoading(false);
            })
        }
    }

    function handleCancel() {
        props.setOpenAddModel(false);
    }

    return (
        <CommonModal
            title="新增收款二维码"
            open={props.open}
            onOk={() => add()}
            onCancel={() => handleCancel()}
            destroyOnClose={true}
            width={700}
            confirmLoading={addSubmitLoading}
            form={<PaymentQrCodeForm formRef={addFormRef}/>}/>
    )
}