import {useEffect, useState} from "react";
import {Tree} from "antd";
import {listPermission, listRolePermission} from "./api"

export default function RoleEmpowerForm(props) {
    const [rolePermission, setRolePermission] = useState(null);
    const [treeData, setTreeData] = useState(null);

    useEffect(() => {
        init();
        initRolePermission();
    }, []);// eslint-disable-line
    function init() {
        listPermission((data) => {
            setTreeData(data.resData);
        }, () => {
            setTreeData(null);
        })
    }

    function initRolePermission() {
        listRolePermission(props.roleInfo.id, (data) => {
            setRolePermission(data.resData);
            props.selectedRolePermission(data.resData)
        }, () => {
            setRolePermission(null);
        })
    }

    function onCheck(checkedKeys) {
        setRolePermission(checkedKeys.checked);
        props.selectedRolePermission(checkedKeys.checked)
    }

    return (
        <Tree
            checkable
            checkStrictly
            checkedKeys={rolePermission}
            onCheck={(checkedKeys) => onCheck(checkedKeys)}
            treeData={treeData}
        />
    )
}
