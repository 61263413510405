import {Form, Input, InputNumber,Select} from "antd";
import React, {useEffect, useState} from "react";
import {doLogisticsCompanyList} from "../all/api";

const {TextArea} = Input;
export default function SendMergeOrderInfoForm(props) {
    const [logisticsCompanyList, setLogisticsCompanyList] = useState(null);
    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };
    useEffect(() => {
        initLogisticsCompany();
    }, []);// eslint-disable-line
    function initLogisticsCompany() {
        doLogisticsCompanyList({
            status: 0,
        }, (data) => {
            setLogisticsCompanyList(data.resData.result);
        }, () => {
            setLogisticsCompanyList(null);
        })
    }

    return (
        <div>
            <Form
                ref={props.formRef} {...layout}>
                <Form.Item
                    label="订单ID"
                    name="orderId"
                    hidden="true">
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="订单编号"
                    name="orderNumber"
                    hidden="true">
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="快递公司"
                    name="logisticsCompanyId"
                    rules={[{required: true, message: '请选择快递公司'}]}>
                    <Select placeholder="请选择快递公司"
                            optionFilterProp="label"
                            showSearch
                            allowClear>
                        {
                            logisticsCompanyList != null ?
                                logisticsCompanyList.map(item => {
                                    return (<Select.Option key={item.id}
                                                           value={item.id}
                                                           label={item.name}>{item.name}</Select.Option>)
                                }) : null
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    label="包裹数量"
                    name="packageNum"
                    rules={[{required: true, message: '请输入包裹数量'}]}>
                    <InputNumber placeholder="" min={1}
                                 style={{"width": 353}}
                                 step={1}/>
                </Form.Item>
                <Form.Item
                    label="出库编码"
                    name="warehousingCodeNumbers"
                    tooltip="多个编码用英文逗号隔开"
                    rules={[{required: true, message: '请输入出库编码，多个编码用英文逗号隔开'}]}>
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="备注"
                    name="remarks">
                    <TextArea/>
                </Form.Item>
            </Form>
        </div>
    )
}