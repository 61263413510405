import {Row, Col, Form, Input, Select, InputNumber, TreeSelect} from "antd";
import React from "react";
import Util from "../../../../../util/params";
import ProductColorForm from "./productColorFrom";

const {TextArea} = Input;

export default function ProductForm(props) {
    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };
    return (
        <div>
            <Form
                ref={props.formRef} {...layout} initialValues={props.productInfo}>
                <Form.Item
                    label="商品ID"
                    name="id"
                    hidden="true">
                    <Input/>
                </Form.Item>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="编码"
                            name="number">
                            <Input disabled placeholder="系统自动生成"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="类别"
                            name="category"
                            rules={[{required: true, message: '请选择类别'}]}>
                            <TreeSelect
                                showSearch
                                treeNodeFilterProp="title"
                                onChange={(value) => {
                                    if (value === undefined || value === null) {
                                        props.setCategoryId(0);
                                    } else {
                                        props.setCategoryId(value);
                                    }
                                }}
                                style={{width: '100%'}}
                                dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                allowClear
                                treeData={props.categoryList}
                                disabled={(props.view === undefined || props.view === null) ? false : props.view}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="名称"
                            name="name"
                            rules={[{required: true, message: '请输入商品名称'}]}>
                            <Input placeholder="请输入商品名称"
                                   disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="规格"
                            name="spec"
                            rules={[{required: true, message: '请输入商品规格'}]}>
                            <Input placeholder="请输入商品规格"
                                   disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="供应商"
                            name="supplierId">
                            <Select
                                placeholder="请选择供应商"
                                showSearch
                                allowClear
                                optionFilterProp="label"
                                disabled={(props.view === undefined || props.view === null) ? false : props.view}>
                                {
                                    props.supplierList != null ?
                                        props.supplierList.map(item => {
                                            return (<Select.Option key={item.id}
                                                                   value={item.id}
                                                                   label={item.name}>{item.name}</Select.Option>)
                                        }) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="品牌"
                            name="brandId">
                            <Select
                                placeholder="请选择品牌"
                                showSearch
                                allowClear
                                optionFilterProp="label"
                                disabled={(props.view === undefined || props.view === null) ? false : props.view}>
                                {
                                    props.brandList != null ?
                                        props.brandList.map(item => {
                                            return (<Select.Option key={item.id}
                                                                   value={item.id}
                                                                   label={item.name}>{item.name}</Select.Option>)
                                        }) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="重量(kg)"
                            name="weight">
                            <InputNumber placeholder="请输入商品重量(kg)" min={0} style={{"width": 257}}
                                         disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="单位"
                            name="unit">
                            <Input placeholder="请输入商品单位"
                                   disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="采购价/元"
                            name="procurePrice"
                            rules={[{
                                required: true, validator: async (_, value, callback) => {
                                    if (Util.isPrice(value)) {
                                        return Promise.resolve()
                                    } else {
                                        return Promise.reject('请输入正确的价格,小数点最多3位');
                                    }
                                }
                            }]}>
                            <Input placeholder="请输入商品采购价(元)"
                                   disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="销售价/元"
                            name="salePrice"
                            rules={[{
                                required: true, validator: async (_, value, callback) => {
                                    if (Util.isPrice(value)) {
                                        return Promise.resolve()
                                    } else {
                                        return Promise.reject('请输入正确的价格,小数点最多3位');
                                    }
                                }
                            }]}>
                            <Input placeholder="请输入商品销售价(元)"
                                   disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="划线价/元"
                            name="retailPrice"
                            rules={[{
                                required: true, validator: async (_, value, callback) => {
                                    if (Util.isPrice(value)) {
                                        return Promise.resolve()
                                    } else {
                                        return Promise.reject('请输入正确的价格,小数点最多3位');
                                    }
                                }
                            }]}>
                            <Input placeholder="请输入商品零售价(元)"
                                   disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="描述"
                            name="remarks">
                            <TextArea
                                disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                        </Form.Item>
                    </Col>
                </Row>
                {
                    props.productColorList != null ?
                        props.productColorList.map((item, index) => {
                            return (<ProductColorForm key={item.key}
                                                      item={item}
                                                      index={index}
                                                      view={props.view}
                                                      layout={layout}
                                                      productInfo={props.productInfo}
                                                      productColorList={props.productColorList}
                                                      setProductColorList={props.setProductColorList}/>)
                        }) : null
                }
            </Form>
        </div>
    )
}