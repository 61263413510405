import {Button, Col, Form, Input, InputNumber, message, Row, Select, Space, Table} from "antd";
import React, {useEffect, useState} from "react";
import {PlusOutlined} from '@ant-design/icons';
import InboundLine from "./inboundLine";
import {doList as doWarehouseList} from "../../information/warehouse/api";
import {doList as doPositionList} from "../../information/position/api"

const {TextArea} = Input;

export default function InboundForm(props) {
    const [openAddModel, setOpenAddModel] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [count, setCount] = useState(0);
    const [warehouseList, setWarehouseList] = useState(null);
    const [positionList, setPositionList] = useState(null);
    const columns = [
        {
            title: '商品ID',
            dataIndex: 'productId',
            key: 'productId',
            width: 50,
        },
        {
            title: '商品条码',
            dataIndex: 'number',
            key: 'number',
            width: 150,
        },
        {
            title: '商品名称',
            dataIndex: 'productName',
            key: 'productName',
            width: 150,
        },
        {
            title: '编码',
            dataIndex: 'productNumber',
            key: 'productNumber',
            width: 100,
        },
        {
            title: '规格',
            dataIndex: 'productSpec',
            key: 'productSpec',
        },
        {
            title: '颜色',
            dataIndex: 'productColor',
            key: 'productColor',
        },
        {
            title: '大小',
            dataIndex: 'productSize',
            key: 'productSize',
        },
        {
            title: '单位',
            dataIndex: 'productUnit',
            key: 'productUnit',
        },
        {
            title: '重量',
            dataIndex: 'productWeight',
            key: 'productWeight',
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: '每笔件数',
            dataIndex: 'eachQuantity',
            key: 'eachQuantity',
        },
        {
            title: '操作',
            key: 'action',
            fixed: 'right',
            width: 45,
            render: (text, record) => dataSource.length > 1 ? (
                <Space size="small">
                    <a style={{'color': '#d43f3a'}} onClick={() => handleDelete(record)}>删除</a>
                </Space>
            ) : null,
        },
    ]
    columns.splice(0, 1)
    useEffect(() => {
        setDataSource([]);
        props.setDataSource([]);
        initWarehouse();
    }, []);// eslint-disable-line
    function initWarehouse() {
        doWarehouseList({
            status: 0
        }, (data) => {
            setWarehouseList(data.resData.result)
        }, () => {
            setWarehouseList(null);
        })
    }

    function initPosition(warehouseId) {
        doPositionList({
            status: 0,
            warehouseId: warehouseId,
        }, (data) => {
            setPositionList(data.resData.result)
        }, () => {
            setPositionList(null);
        })
    }

    function handleDelete(record) {
        const newData = dataSource.filter((item) => item.key !== record.key);
        setDataSource(newData);
        props.setDataSource(newData);

        const quantity = props.quantity;
        quantity.delete(record.number.props.value);
        props.setQuantity(quantity);

        const eachQuantity = props.eachQuantity;
        eachQuantity.delete(record.number.props.value);
        props.setEachQuantity(eachQuantity);
    }

    function addInbound() {
        setOpenAddModel(true);
    }

    function handleAdd(productInfo) {
        if (props.quantity.get(productInfo.number) === undefined && props.eachQuantity.get(productInfo.number) === undefined) {
            const quantity = props.quantity;
            quantity.set(productInfo.number, productInfo.quantity);

            const eachQuantity = props.eachQuantity;
            eachQuantity.set(productInfo.number, productInfo.eachQuantity);
            let newData = {
                key: count,
                productId: <Input value={productInfo.productId} style={{"borderStyle": "dashed"}} hidden={true}/>,
                number: <Input value={productInfo.number} style={{"borderStyle": "dashed"}} readOnly={true}/>,
                productName: <Input value={productInfo.productName} style={{"borderStyle": "dashed"}} readOnly={true}/>,
                productNumber: <Input value={productInfo.productNumber} style={{"borderStyle": "dashed"}}
                                      readOnly={true}/>,
                productCategory: <Input value={productInfo.productCategory} style={{"borderStyle": "dashed"}}
                                        readOnly={true}/>,
                productSupplier: <Input value={productInfo.productSupplier} style={{"borderStyle": "dashed"}}
                                        readOnly={true}/>,
                productBrand: <Input value={productInfo.productBrand} style={{"borderStyle": "dashed"}}
                                     readOnly={true}/>,
                productSpec: <Input value={productInfo.productSpec} style={{"borderStyle": "dashed"}} readOnly={true}/>,
                productColor: <Input value={productInfo.productColor} style={{"borderStyle": "dashed"}}
                                     readOnly={true}/>,
                productSize: <Input value={productInfo.productSize} style={{"borderStyle": "dashed"}} readOnly={true}/>,
                productUnit: <Input value={productInfo.productUnit} style={{"borderStyle": "dashed"}} readOnly={true}/>,
                productWeight: <Input value={productInfo.productWeight} style={{"borderStyle": "dashed"}}
                                      readOnly={true}/>,
                quantity: <InputNumber min={1} defaultValue={productInfo.quantity} onChange={(e) => {
                    quantity.set(productInfo.number, e);
                    props.setQuantity(quantity);
                }}/>,
                eachQuantity: <InputNumber min={1} defaultValue={productInfo.eachQuantity} onChange={(e) => {
                    eachQuantity.set(productInfo.number, e);
                    props.setEachQuantity(eachQuantity);
                }}/>,
            };
            props.setDataSource([...dataSource, newData]);
            setDataSource([...dataSource, newData]);
            setCount(count + 1);
        } else {
            message.error("不要插入重复的数据！");
        }
    }

    return (
        <div>
            <Form ref={props.formRef}>
                <Row gutter={[16, 0]}>
                    <Col span={12}>
                        <Form.Item
                            label="仓库"
                            name="warehouseId"
                            rules={[{required: true, message: '请选择仓库'}]}>
                            <Select showSearch
                                    allowClear
                                    placeholder="请选择仓库"
                                    optionFilterProp="label"
                                    onChange={(value, option) => {
                                        if (option !== undefined) {
                                            initPosition(option.key);
                                        }
                                        props.formRef.current.setFieldsValue({
                                            positionId: null
                                        });
                                    }}>
                                {
                                    warehouseList != null ?
                                        warehouseList.map(item => {
                                            return (<Select.Option key={item.id}
                                                                   value={item.id}
                                                                   label={item.name}>{item.name}</Select.Option>)
                                        }) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="仓位"
                            name="positionId"
                            rules={[{required: true, message: '请选择仓位'}]}>
                            <Select showSearch
                                    allowClear
                                    placeholder="请选择仓位"
                                    optionFilterProp="label">
                                {
                                    positionList != null ?
                                        positionList.map(item => {
                                            return (<Select.Option key={item.id}
                                                                   value={item.id}
                                                                   label={item.name}>{item.name}</Select.Option>)
                                        }) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Form.Item>
                        <Button type="primary" icon={<PlusOutlined/>} onClick={() => addInbound()}>插入行</Button>
                    </Form.Item>
                </Row>
                <Table
                    size="small"
                    bordered
                    rowKey={columns => columns.key}
                    dataSource={dataSource}
                    columns={columns}
                    pagination={false}
                />
                <Row style={{"marginTop": "15px"}}>
                    <Col span={24}>
                        <Form.Item
                            name="remarks">
                            <TextArea placeholder="请输入备注"/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <InboundLine open={openAddModel}
                         setOpenAddModel={setOpenAddModel}
                         handleAdd={handleAdd}/>
        </div>
    )
}