import HttpUtil from "../../../../../util/httpUtil";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import React from "react";

/**
 * 获取类目信息列表
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doList = function (params, successFun, errorFun) {
    HttpUtil.doGet(
        "/shop/base/category/list",
        params,
        successFun, errorFun);
}
/**
 * 获取类目Tree信息列表
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doTreeList = function (params, successFun, errorFun) {
    HttpUtil.doGet(
        "/shop/base/category/listTree",
        params,
        successFun, errorFun);
}
/**
 * 删除类目信息
 * @param record
 * @param successFun
 * @param errorFun
 */
export const doDel = function (record, successFun, errorFun) {
    HttpUtil.doDelete(<ExclamationCircleOutlined/>, "删除类目",
        "确认删除" + record.name + "信息？",
        "/shop/base/category/delete/" + record.id, null, successFun, errorFun);
}
/**
 * 新增类目信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doAdd = function (params, successFun, errorFun) {
    HttpUtil.doPost("/shop/base/category/add",
        params, successFun, errorFun);
}
/**
 * 更新类目信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doUpdate = function (params, successFun, errorFun) {
    HttpUtil.doPut("/shop/base/category/update",
        params, successFun, errorFun);
}
/**
 * 禁用类目
 * @param record
 * @param successFun
 * @param errorFun
 */
export const disable = function (record, successFun, errorFun) {
    HttpUtil.doPutConfirm(<ExclamationCircleOutlined/>, "禁用类目", "确认禁用" + record.name + "类目？将禁用所有对应的子类目！",
        "/shop/base/category/disable/" + record.id, null, successFun, errorFun);
}
/**
 * 启用类目
 * @param record
 * @param successFun
 * @param errorFun
 */
export const enable = function (record, successFun, errorFun) {
    HttpUtil.doPutConfirm(<ExclamationCircleOutlined/>, "启用类目", "确认启用" + record.name + "类目？将启用所有对应的子类目",
        "/shop/base/category/enable/" + record.id, null, successFun, errorFun);
}
