import type {TabsProps} from "antd";
import {Tabs} from "antd";
import React from "react";
import Category from "./category/category";
import Supplier from "./supplier/supplier";
import Brand from "./brand/brand";

export default function InformationTab() {
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: '商品分类',
            children: <Category/>,
        },
        {
            key: '2',
            label: '供应商',
            children: <Supplier/>,
        },
        {
            key: '3',
            label: '品牌',
            children: <Brand/>,
        }
    ];

    return (
        <Tabs defaultActiveKey="1" items={items}/>
    )
}