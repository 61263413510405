import HttpUtil from "../../../../util/httpUtil";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import React from "react";

/**
 * 获取合并订单信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doList = function (params, successFun, errorFun) {
    HttpUtil.doGet(
        "/order/merge/list",
        params,
        successFun, errorFun);
}
/**
 * 获取合并订单状态
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doListStatus = function (successFun, errorFun) {
    HttpUtil.doGet(
        "/order/merge/listStatus",
        null,
        successFun, errorFun);
}
/**
 * 合并订单
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doMergeOrder = function (params, successFun, errorFun, cancelFun) {
    HttpUtil.doPostConfirm(<ExclamationCircleOutlined/>, "合并订单",
        "确认合并订单？",
        "/order/mergeOrder", null, successFun, errorFun, cancelFun);
}
/**
 * 打印合并订单条形码信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doPrint = function (params, successFun, errorFun) {
    HttpUtil.doPost("/order/merge/barcode/print",
        params, successFun, errorFun);
}
/**
 * 合并订单发货
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doDeliveryMergeOrder = function (params, successFun, errorFun) {
    HttpUtil.doPost("/order/merge/delivery",
        params, successFun, errorFun);
}
