import {Col, Form, Image, Input, InputNumber, Row, Select, Table} from "antd";
import React, {useEffect, useState} from "react";
import {doList as doProductList} from "../product/api";
import {getSaleProductInfo} from "./api";

export default function SpecForm(props) {
    const [productList, setProductList] = useState(null);
    const [dataSource, setDataSource] = useState([]);
    const [salePriceMap, setSalePriceMap] = useState(new Map());
    const [procurePriceMap, setProcurePriceMap] = useState(new Map());
    const [underlinedPriceMap, setUnderlinedPriceMap] = useState(new Map());
    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };
    const columns = [
        {
            title: 'SKU编码',
            dataIndex: 'skuNumber',
            key: 'skuNumber',
        },
        {
            title: '图片',
            dataIndex: 'imageUrl',
            key: 'imageUrl',
            render: imageUrl => {
                console.log(typeof imageUrl)
                if(typeof imageUrl==="string"){
                    return (
                        <Image
                            width={25}
                            height={20}
                            src={imageUrl}/>
                    )
                }else{
                    return imageUrl;
                }
            }
        },
        {
            title: '颜色',
            dataIndex: 'color',
            key: 'color',
        },
        {
            title: '大小',
            dataIndex: 'size',
            key: 'size',
        },
        {
            title: '规格',
            dataIndex: 'spec',
            key: 'spec',
        },
        {
            title: '销售价格',
            dataIndex: 'salePrice',
            key: 'salePrice',
        },
        {
            title: '采购价格',
            dataIndex: 'procurePrice',
            key: 'procurePrice',
        },
        {
            title: '划线价格',
            dataIndex: 'underlinedPrice',
            key: 'underlinedPrice',
        },
        {
            title: '库存',
            dataIndex: 'quantity',
            key: 'quantity',
        }
    ];

    useEffect(() => {
        init();
        initProductList();
    }, []);// eslint-disable-line
    function init() {
        if (props.skuInfo !== null && props.skuInfo !== undefined) {
            setDataSource(props.skuInfo);
            let salePriceMap = new Map();
            let procurePriceMap = new Map();
            let underlinedPriceMap = new Map();
            for (let i = 0; i < props.skuInfo.length; i++) {
                salePriceMap.set(props.skuInfo[i].skuNumber, props.skuInfo[i].salePrice);
                procurePriceMap.set(props.skuInfo[i].skuNumber, props.skuInfo[i].procurePrice);
                underlinedPriceMap.set(props.skuInfo[i].skuNumber, props.skuInfo[i].underlinedPrice);
            }
            setSalePriceMap(salePriceMap);
            setProcurePriceMap(procurePriceMap)
            setUnderlinedPriceMap(underlinedPriceMap);
            props.formRef.current.setFieldsValue({
                salePriceMap: salePriceMap,
                procurePriceMap: procurePriceMap,
                underlinedPriceMap: underlinedPriceMap
            });
        }
    }

    function initProductList() {
        doProductList({status: 0}, (data) => {
            setProductList(data.resData.result);
        }, () => {
            setProductList(null);
        });
    }

    function productSpecChange(value) {
        console.log(salePriceMap + procurePriceMap + underlinedPriceMap);
        getSaleProductInfo({
            productNumberList: Array.from(value)
        }, (data) => {
            if (data.resData !== undefined && data.resData.length > 0) {
                let dataSource = [];
                let salePriceMap = new Map();
                let procurePriceMap = new Map();
                let underlinedPriceMap = new Map();
                for (let i = 0; i < data.resData.length; i++) {
                    let newData = {
                        key: data.resData[i].skuNumber,
                        skuNumber: <Input value={data.resData[i].skuNumber}
                                          style={{"borderStyle": "dashed", "width": " 110px"}}
                                          readOnly={true}/>,
                        imageUrl: <Image width={25} height={20} src={data.resData[i].imageUrl}/>,
                        color: <Input value={data.resData[i].color} style={{"borderStyle": "dashed", "width": " 70px"}}
                                      readOnly={true}/>,
                        size: <Input value={data.resData[i].size} style={{"borderStyle": "dashed", "width": " 120px"}}
                                     readOnly={true}/>,
                        spec: <Input value={data.resData[i].spec} style={{"borderStyle": "dashed", "width": " 70px"}}
                                     readOnly={true}/>,
                        salePrice: <InputNumber min={1} step={0.01} defaultValue={data.resData[i].salePrice}
                                                style={{"width": " 80px"}}
                                                onChange={(e) => {
                                                    salePriceMap.set(data.resData[i].skuNumber, e);
                                                    setSalePriceMap(salePriceMap);
                                                }} readOnly={true}/>,
                        procurePrice: <InputNumber min={1} step={0.01} defaultValue={data.resData[i].procurePrice}
                                                   style={{"width": " 80px"}}
                                                   onChange={(e) => {
                                                       procurePriceMap.set(data.resData[i].skuNumber, e);
                                                       setProcurePriceMap(procurePriceMap);
                                                   }} readOnly={true}/>,
                        underlinedPrice: <InputNumber min={1} step={0.01} defaultValue={data.resData[i].underlinedPrice}
                                                      style={{"width": " 80px"}}
                                                      onChange={(e) => {
                                                          underlinedPriceMap.set(data.resData[i].skuNumber, e);
                                                          setUnderlinedPriceMap(underlinedPriceMap);
                                                      }} readOnly={true}/>,
                        quantity: <Input value={data.resData[i].quantity}
                                         style={{"borderStyle": "dashed", "width": " 70px"}}
                                         readOnly={true}/>,
                    };
                    dataSource.push(newData);
                    salePriceMap.set(data.resData[i].skuNumber, data.resData[i].salePrice);
                    procurePriceMap.set(data.resData[i].skuNumber, data.resData[i].procurePrice);
                    underlinedPriceMap.set(data.resData[i].skuNumber, data.resData[i].underlinedPrice);
                }
                setDataSource(dataSource);
                setSalePriceMap(salePriceMap);
                setProcurePriceMap(procurePriceMap);
                setUnderlinedPriceMap(underlinedPriceMap);

                props.formRef.current.setFieldsValue({
                    salePriceMap: salePriceMap,
                    procurePriceMap: procurePriceMap,
                    underlinedPriceMap: underlinedPriceMap
                });
            }
        }, (e) => {
            console.log(e)
        })
    }

    return (
        <div>
            <Form
                ref={props.formRef} {...layout}>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="商品规格"
                            name="productNumberList"
                            rules={[{required: true, message: '请选择商品规格'}]}>
                            <Select style={{"width": "400px"}}
                                    showSearch={true}
                                    mode="multiple"
                                    maxTagCount="responsive"
                                    optionFilterProp="label"
                                    disabled={(props.view === undefined || props.view === null) ? false : props.view}
                                    onChange={(value, option) => {
                                        productSpecChange(value)
                                    }}>
                                {
                                    productList != null ?
                                        productList.map(item => {
                                            return (<Select.Option key={item.number}
                                                                   value={item.number}
                                                                   label={item.number}>{item.number + "-" + item.name + "-" + item.color + "-" + item.size + "-" + item.spec}</Select.Option>)
                                        }) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="SKU列表"
                            name="skuInfo">
                            <div style={{"width": "770px"}}>
                                <Table
                                    size="small"
                                    bordered
                                    disabled={(props.view === undefined || props.view === null) ? false : props.view}
                                    rowKey={columns => columns.key}
                                    dataSource={dataSource}
                                    columns={columns}
                                    pagination={false}
                                />
                            </div>
                        </Form.Item>
                    </Col>
                    <Form.Item
                        hidden="true"
                        label="salePriceMap"
                        name="salePriceMap">
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        hidden="true"
                        label="procurePriceMap"
                        name="procurePriceMap">
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        hidden="true"
                        label="underlinedPriceMap"
                        name="underlinedPriceMap">
                        <Input/>
                    </Form.Item>
                </Row>
            </Form>
        </div>
    )
}