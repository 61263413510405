import {Tooltip, Button, Col, Form, Input, message, Modal, Row, Upload} from "antd";
import React, {useState} from "react";
import Content from "../../../../../util/constant";
import {doDeleteFile} from "../../../../../common/api";
import {MinusOutlined, PlusOutlined} from "@ant-design/icons";

export default function ProductColorForm(props) {
    const [imageFileList, setImageFileList] = useState([]);
    const [previewImage, setPreviewImage] = useState(null);
    const [previewImageVisible, setPreviewImageVisible] = useState(null);
    const [previewImageTitle, setPreviewImageTitle] = useState(null);
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    function handlePreviewImageCancel() {
        setPreviewImageVisible(false);
    }

    return (
        <div style={{
            'borderTopWidth': '2px',
            'borderLeftWidth': '0px',
            'borderRightWidth': '0px',
            'borderBottomWidth': '0px',
            'borderStyle': 'dashed',
            'borderColor': '#CCCCCC',
            'padding': '10px 0px'
        }}>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        label="颜色"
                        name={"color" + props.item.key}
                        rules={[{required: true, message: '请输入商品颜色'}]}>
                        <Input placeholder="请输入商品颜色"
                               disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="大小"
                        name={"size" + props.item.key}
                        rules={[{required: true, message: '请输入商品大小'}]}>
                        <Input placeholder="请输入商品大小"
                               disabled={(props.view === undefined || props.view === null) ? false : props.view}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        label="图片"
                        name={"imageFiles" + props.item.key}
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        rules={[{required: true, message: '请上传图片'}]}>
                        <Upload
                            disabled={(props.view === undefined || props.view === null) ? false : props.view}
                            action={Content.BASE_URL + "/uploadFile"}
                            name='file'
                            withCredentials={true}
                            accept='.png,.jpg,.jpeg,.tiff'
                            listType="picture-card"
                            className="avatar-uploader"
                            fileList={imageFileList}
                            maxCount={1}
                            beforeUpload={(file) => {
                                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
                                    || file.type === 'image/jpg' || file.type === 'image/tiff';
                                const isLt2M = file.size / 1024 / 1024 < 20;
                                return new Promise((resolve, reject) => {
                                    if (!isJpgOrPng) {
                                        message.error('文件格式不正常！');
                                        reject();
                                    } else if (!isLt2M) {
                                        message.error('文件大小最大20M！');
                                        reject();
                                    } else {
                                        resolve(file);
                                    }
                                });
                            }}
                            onChange={(options) => {
                                const {fileList} = options;
                                let imageList = [];
                                for (let i = 0; i < fileList.length; i++) {
                                    if (fileList[i].response !== undefined) {
                                        imageList[i] = fileList[i].response;
                                    } else if (fileList[i].response === undefined && fileList[i].url !== undefined) {
                                        imageList[i] = fileList[i].url;
                                    }

                                }
                                if (imageList.length > 0) {
                                    setImageFileList(imageList);
                                }
                            }}
                            onPreview={async (file) => {
                                if (!file.url && !file.preview) {
                                    file.preview = await getBase64(file.originFileObj);
                                }
                                setPreviewImage(file.url || file.preview);
                                setPreviewImageVisible(true);
                                setPreviewImageTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
                            }}
                            onRemove={(file) => {
                                if (file.response !== undefined) {
                                    doDeleteFile({
                                        url: file.response
                                    }, () => {
                                        let imageFiles = imageFileList;
                                        imageFiles = imageFiles.filter(item => item !== file.response);
                                        setImageFileList(imageFiles);
                                    }, null);
                                }
                            }}>
                            <div>
                                <PlusOutlined style={{"fontSize": "32px", "color": "#cccccc"}}/>
                                <div style={{marginTop: 8}}>上传图片</div>
                            </div>
                        </Upload>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <div style={{"marginLeft": "80px", "marginTop": "40px"}}>
                        <Tooltip title="新增颜色和大小">
                            <Button type="default" shape="circle" icon={<PlusOutlined/>} onClick={() => {
                                console.log(props.productColorList[props.productColorList.length - 1].key)
                                props.setProductColorList([...props.productColorList,
                                    {key: props.productColorList[props.productColorList.length - 1].key + 1}])
                            }}/>
                        </Tooltip>
                        {
                            (props.productColorList != null && props.productColorList.length > 1) ? (
                                <Tooltip title="删除颜色和大小">
                                    <Button type="default" shape="circle" style={{"marginLeft": "10px"}}
                                            icon={<MinusOutlined/>}
                                            onClick={() => {
                                                const productColorList = [...props.productColorList];
                                                const index = productColorList.indexOf(props.item);
                                                if (index !== -1) {
                                                    productColorList.splice(index, 1);
                                                }
                                                props.setProductColorList(productColorList);
                                            }}/>
                                </Tooltip>
                            ) : null
                        }
                    </div>
                </Col>
            </Row>
            <Modal
                open={previewImageVisible}
                title={previewImageTitle}
                footer={null}
                onCancel={() => handlePreviewImageCancel()}>
                <img alt="example" style={{width: '100%'}} src={previewImage}/>
            </Modal>
        </div>
    )
}