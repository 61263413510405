import axios from 'axios';
import {message} from "antd";
import Content from "./constant";
import Constant from "./constant";

const Axios = axios.create({
    baseURL: Content.BASE_URL, // 设置请求的base url
    timeout: 10 * 1000, // 设置超时时长
    withCredentials: true,// 是否跨站点访问控制请求
    headers: {
        get: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        post: {
            'Content-Type': 'application/json;charset=UTF-8'
        },
        put: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    }
})
/**
 * 请求拦截器
 */
Axios.interceptors.request.use(
    config => {
        //从auth中获取后端传给前端的sessionId，以后调用任何接口就可以携带sessionId了
        let auth = localStorage.getItem(Constant.AUTHORIZATION);
        if (auth != null) {
            config.headers['AUTHORIZATION'] = auth;
        }
        return config;
    },
    error => {
        Promise.error(error);
    }
)
/**
 * 响应拦截器
 */
Axios.interceptors.response.use(
    response => {
        if (response.data != null && response.data.code === 401) {
            localStorage.clear();
            window.location.href = "/login";
        }
        if (response.status === 200) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    error => {
        if (error.toString() === "Error: Network Error") {
            message.error("网络错误！");
            return Promise.reject(error);
        } else if (error.response.status) {
            switch (error.response.status) {
                case 401:
                    localStorage.clear();
                    window.location.href = "/login";
                    break;
                case 404:
                    message.info("找不到对应路径");
                    break;
                default:
                    return Promise.reject(error.response);
            }
        } else {
            return Promise.reject(error);
        }
    }
)
export default Axios;

