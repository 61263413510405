import {ExclamationCircleOutlined} from '@ant-design/icons';
import React from "react";
import HttpUtil from "../../../../../util/httpUtil";

/**
 * 获取产品类别信息列表
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doList = function (params, successFun, errorFun) {
    HttpUtil.doGet(
        "/commodity/category/list",
        params,
        successFun, errorFun);
}
/**
 * 获取类别Tree信息列表
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doTreeList = function (params, successFun, errorFun) {
    HttpUtil.doGet(
        "/commodity/category/listTree",
        params,
        successFun, errorFun);
}
/**
 * 删除产品类别信息
 * @param record
 * @param successFun
 * @param errorFun
 */
export const doDel = function (record, successFun, errorFun) {
    HttpUtil.doDelete(<ExclamationCircleOutlined/>, "删除商品类别",
        "确认删除" + record.name + "信息？",
        "/commodity/category/delete/" + record.id, null, successFun, errorFun);
}
/**
 * 新增产品类别信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doAdd = function (params, successFun, errorFun) {
    HttpUtil.doPost("/commodity/category/add",
        params, successFun, errorFun);
}
/**
 * 更新产品类别信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doUpdate = function (params, successFun, errorFun) {
    HttpUtil.doPut("/commodity/category/update",
        params, successFun, errorFun);
}
/**
 * 禁用类别
 * @param record
 * @param successFun
 * @param errorFun
 */
export const disable = function (record, successFun, errorFun) {
    HttpUtil.doPutConfirm(<ExclamationCircleOutlined/>, "禁用类别", "确认禁用" + record.name + "类别？将禁用所有对应的子类别！",
        "/commodity/category/disable/" + record.id, null, successFun, errorFun);
}
/**
 * 启用类别
 * @param record
 * @param successFun
 * @param errorFun
 */
export const enable = function (record, successFun, errorFun) {
    HttpUtil.doPutConfirm(<ExclamationCircleOutlined/>, "启用类别", "确认启用" + record.name + "类别？将启用所有对应的子类别",
        "/commodity/category/enable/" + record.id, null, successFun, errorFun);
}

