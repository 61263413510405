import {Button, Form, Input, Space} from "antd";
import {useEffect, useState} from "react";
import Constant from "../../../../util/constant";
import CommonTable from "../../../../component/CommonTable";
import AddRole from "./addRole";
import EditRole from "./editRole";
import {listRoleInfo, deleteRoleInfo} from "./api";
import RoleUserInfoModel from "./roleUserInfoModel";
import RoleEmpowerModel from "./roleEmpowerModel";

export default function Role() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(Constant.CURRENT_PAGE);
    const [pageSize, setPageSize] = useState(Constant.PAGE_SIZE);

    const [roleName, setRoleName] = useState(null);
    const [openAddModel, setOpenAddModel] = useState(false);
    const [openEditModel, setOpenEditModel] = useState(false);
    const [openUserInfoModel, setOpenUserInfoModel] = useState(false);
    const [openRoleEmpowerModel, setOpenRoleEmpowerModel] = useState(false);
    const [roleInfo, setRoleInfo] = useState(null);
    const columns = [
        {
            title: '角色ID',
            dataIndex: 'id',
            key: 'id',
            width: 70,
        },
        {
            title: '角色名称',
            dataIndex: 'roleName',
            key: 'roleName',
        },
        {
            title: '成员数量',
            dataIndex: 'roleUserNum',
            key: 'roleUserNum',
        },
        {
            title: '创建时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: 170,
        },
        {
            title: '创建人',
            dataIndex: 'creator',
            key: 'creator',
        },
        {
            title: '最近修改时间',
            dataIndex: 'gmtUpdate',
            key: 'gmtUpdate',
            width: 170,
        },
        {
            title: '最近修改人',
            dataIndex: 'modifier',
            key: 'modifier',
        },
        {
            title: '角色说明',
            dataIndex: 'remarks',
            key: 'remarks',
            width: 300,
            ellipsis: true,
        },
        {
            title: '操作',
            key: 'action',
            fixed: 'right',
            width: 180,
            render: (text, record) => (
                <Space size="small">
                    <a onClick={() => showRoleUserInfoModel(record)}>成员管理</a>
                    <a onClick={() => showRoleEmpowerModel(record)}>授权</a>
                    <a onClick={() => editRole(record)}>编辑</a>
                    <a style={{'color': '#d43f3a'}} onClick={() => deleteRoleInfo(record, () => {
                        init()
                    })}>删除</a>
                </Space>
            ),
        },
    ];
    useEffect(() => {
        init();
    }, []);// eslint-disable-line
    function init() {
        listRoleInfo({
            roleName: roleName,
            currentPage: currentPage,
            pageSize: pageSize
        }, (data) => {
            setData(data.resData.result);
            setLoading(false);
            setTotal(data.resData.total);
        }, () => {
            setData(null);
            setLoading(false);
        })
    }

    function resetPage(currentPage, pageSize) {
        setCurrentPage(currentPage);
        setPageSize(pageSize);
    }

    function addRole() {
        setOpenAddModel(true);
    }

    function editRole(record) {
        setRoleInfo(record);
        setOpenEditModel(true);
    }

    function showRoleUserInfoModel(record) {
        setRoleInfo(record);
        setOpenUserInfoModel(true);
    }

    function showRoleEmpowerModel(record) {
        setRoleInfo(record);
        setOpenRoleEmpowerModel(true);
    }

    return (
        <div>
            <Form layout='inline'>
                <Form.Item
                    label="角色名称"
                    name="roleName"
                    style={{width: 250}}>
                    <Input placeholder="请输入角色名称" onChange={(e) => setRoleName(e.target.value)}/>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" onClick={() => {
                        setLoading(true);
                        init();
                    }}>查询</Button>
                    <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                            onClick={() => setRoleName(null)}>重置</Button>
                    <Button type="primary" style={{'marginLeft': '10px'}} onClick={() => addRole()}>新增角色</Button>
                </Form.Item>
            </Form>
            <CommonTable
                columns={columns}
                rowKey={columns => columns.id}
                data={data}
                width={1700}
                loading={loading}
                total={total}
                currentPage={currentPage}
                pageSize={pageSize}
                initData={() => init()}
                resetPage={(currentPage, pageSize) => resetPage(currentPage, pageSize)}/>
            <AddRole open={openAddModel} setOpenAddModel={setOpenAddModel} init={init}/>
            <EditRole open={openEditModel} setOpenEditModel={setOpenEditModel} roleInfo={roleInfo} init={init}/>
            <RoleUserInfoModel open={openUserInfoModel} setOpenUserInfoModel={setOpenUserInfoModel} roleInfo={roleInfo}
                               init={init}/>
            <RoleEmpowerModel open={openRoleEmpowerModel} setOpenRoleEmpowerModel={setOpenRoleEmpowerModel}
                              roleInfo={roleInfo}
                              init={init}/>
        </div>
    )
}