import React, {useEffect, useState} from "react";
import Constant from "../../../../../util/constant";
import {Button, Col, Form, Image, Input, message, Row, Space} from "antd";
import Util from "../../../../../util/params";
import CommonTable from "../../../../../component/CommonTable";
import {doList, doPrint} from "./api";

export default function CodeInfo() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(Constant.CURRENT_PAGE);
    const [pageSize, setPageSize] = useState(Constant.PAGE_SIZE);

    const [number, setNumber] = useState(null);
    const [codeNumber, setCodeNumber] = useState(null);
    const [productName, setProductName] = useState(null);
    const [productSku, setProductSku] = useState(null);
    const [warehouseName, setWarehouseName] = useState(null);
    const [positionName, setPositionName] = useState(null);
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 50,
        },
        {
            title: '入库编码',
            dataIndex: 'number',
            key: 'number',
        },
        {
            title: '条码编号',
            dataIndex: 'codeNumber',
            key: 'codeNumber',
        },
        {
            title: 'SKU码',
            dataIndex: 'productSku',
            key: 'productSku',
        },
        {
            title: '商品名称',
            dataIndex: 'productName',
            key: 'productName',
        },
        {
            title: '商品颜色',
            dataIndex: 'productColor',
            key: 'productColor',
        },
        {
            title: '商品大小',
            dataIndex: 'productSize',
            key: 'productSize',
        },
        {
            title: '件数',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: '条码',
            dataIndex: 'inCode',
            key: 'inCode',
            render: inCode => {
                if (!Util.isEmpty(inCode)) {
                    return (
                        <Image
                            width={25}
                            height={20}
                            src={inCode}/>
                    )
                }
            }
        },
        {
            title: '仓库名称',
            dataIndex: 'warehouseName',
            key: 'warehouseName',
        },
        {
            title: '仓位名称',
            dataIndex: 'positionName',
            key: 'positionName',
        },
        {
            title: '描述',
            dataIndex: 'remarks',
            key: 'remarks',
        },
        {
            title: '创建时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: 170,
        },
        {
            title: '创建人',
            dataIndex: 'creator',
            key: 'creator',
        },
        {
            title: '最近修改时间',
            dataIndex: 'gmtUpdate',
            key: 'gmtUpdate',
            width: 170,
        },
        {
            title: '最近修改人',
            dataIndex: 'modifier',
            key: 'modifier',
        },
        {
            title: '操作',
            key: 'action',
            fixed: 'right',
            width: 45,
            render: (text, record) => (
                <Space size="small">
                    <a onClick={() => print(record)}>打印</a>
                </Space>
            ),
        },
    ];
    columns.splice(0, 1);
    useEffect(() => {
        init(currentPage, pageSize);
    }, []);// eslint-disable-line
    function init(current, page) {
        doList({
            number: number,
            codeNumber: codeNumber,
            productName: productName,
            productSku: productSku,
            warehouseName: warehouseName,
            positionName: positionName,
            currentPage: current === undefined ? currentPage : current,
            pageSize: page === undefined ? pageSize : page
        }, (data) => {
            setData(data.resData.result);
            setLoading(false);
            setTotal(data.resData.total);
        }, () => {
            setData(null);
            setLoading(false);
        })
    }

    function resetPage(currentPage, pageSize) {
        setCurrentPage(currentPage);
        setPageSize(pageSize);
    }
    function print(record) {
        // 调用打印机打印
        let ids = [];
        ids.push(record.id);
        let warehousingCodeDTO = {};
        warehousingCodeDTO.ids = ids;
        doPrint(warehousingCodeDTO, (res) => {
            if (res.code === 200) {
                message.info(res.msg)
            } else {
                message.error(res.msg)
            }
        })
    }
    return (
        <div>
            <Form>
                <Row gutter={[16, 0]}>
                    <Col span={6}>
                        <Form.Item
                            label="入库条码"
                            name="number">
                            <Input placeholder="请输入入库条码" onChange={(e) => setNumber(e.target.value)}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="商品名称"
                            name="productName">
                            <Input placeholder="请输入商品名称" onChange={(e) => setProductName(e.target.value)}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="商品SKU"
                            name="productSku">
                            <Input placeholder="请输入商品SKU" onChange={(e) => setProductSku(e.target.value)}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="仓库名称"
                            name="warehouseName">
                            <Input placeholder="请输入仓库名称" onChange={(e) => setWarehouseName(e.target.value)}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 0]}>
                    <Col span={6}>
                        <Form.Item
                            label="仓位名称"
                            name="positionName">
                            <Input placeholder="请输入仓位名称" onChange={(e) => setPositionName(e.target.value)}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item>
                            <Button type="primary" onClick={() => {
                                setLoading(true);
                                init(Constant.CURRENT_PAGE, Constant.PAGE_SIZE);
                            }}>查询</Button>
                            <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                                    onClick={() => {
                                        setNumber(null);
                                        setCodeNumber(null);
                                        setProductName(null);
                                        setProductSku(null);
                                        setWarehouseName(null);
                                        setPositionName(null);
                                    }}>重置</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <CommonTable
                columns={columns}
                rowKey={columns => columns.id}
                data={data}
                width={2000}
                loading={loading}
                total={total}
                currentPage={currentPage}
                pageSize={pageSize}
                initData={(currentPage, pageSize) => init(currentPage, pageSize)}
                resetPage={(currentPage, pageSize) => resetPage(currentPage, pageSize)}/>
        </div>
    )
}