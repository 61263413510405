import React from "react";
import EditInventoryForm from "./editInventoryForm";
import {doUpdate} from "./api";
import CommonModal from "../../../../../component/CommonModal";

export default function EditInventoryInfo(props){
    const editFormRef = React.createRef();
    if (props.inventory != null && props.open) {
        setTimeout(() => {
            editFormRef.current.setFieldsValue(props.inventory);
        }, 100);
    }
    function update() {
        editFormRef.current.validateFields().then(values => {
            doUpdate({
                id: values.id,
                quantity: values.quantity
            }, (data) => {
                clearInterval(editFormRef.current);
                props.setOpenEditModel(false);
                props.init();
            })
        }).catch(e => {
            console.log(e)
        })

    }
    function handleCancel() {
        props.setOpenEditModel(false);
    }

    return (
        <CommonModal
            title="编辑库存信息"
            open={props.open}
            onOk={() => update()}
            onCancel={() => handleCancel()}
            destroyOnClose={true}
            form={<EditInventoryForm formRef={editFormRef}/>}/>
    )
}