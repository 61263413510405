import React from "react";
import type {TabsProps} from 'antd';
import {Tabs} from "antd";
import WarehouseInfo from "./warehouse/warehouseInfo";
import PositionInfo from "./position/positionInfo";

export default function WarehouseTab() {
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: '仓库信息',
            children: <WarehouseInfo/>,
        },
        {
            key: '2',
            label: '仓位信息',
            children: <PositionInfo/>,
        },
        // {
        //     key: '3',
        //     label: '货盘信息',
        //     children: <PalletInfo/>,
        // }
    ];

    return (
        <Tabs defaultActiveKey="1" items={items}/>
    )
}