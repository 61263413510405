import React, {useEffect, useState} from "react";
import Constant from "../../../../util/constant";
import {Button, Form, Input, Select, Space} from "antd";
import {doDel, doListType, doList} from "./api";
import CommonTable from "../../../../component/CommonTable";
import AddVideo from "./addVideo";

export default function AllVideo() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(Constant.CURRENT_PAGE);
    const [pageSize, setPageSize] = useState(Constant.PAGE_SIZE);

    const [title, setTitle] = useState(null);
    const [type, setType] = useState(null);
    const [typeList, setTypeList] = useState(null);
    const [openAddModel, setOpenAddModel] = useState(false);

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 50,
        },
        {
            title: '直播标题',
            dataIndex: 'title',
            key: 'title',
            width: 300,
            ellipsis: true,
        },
        {
            title: '视频类型',
            dataIndex: 'typeText',
            key: 'typeText',
            width: 75,
        },
        {
            title: '直播时间',
            dataIndex: 'time',
            key: 'time',
            width: 310,
        },
        {
            title: '视频地址',
            dataIndex: 'videoUrl',
            key: 'videoUrl',
            width: 400,
            ellipsis: true,
        },
        {
            title: '备注',
            dataIndex: 'remarks',
            key: 'remarks',
        },
        {
            title: '创建时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: 170,
        },
        {
            title: '操作',
            key: 'action',
            fixed: 'right',
            width: 60,
            render: (text, record) => (
                <Space size="small" style={{"float": "right"}}>
                    <a style={{
                        'color': '#d43f3a'
                    }} onClick={() => doDel(record, () => {
                        init()
                    })}>删除</a>
                </Space>
            ),
        },
    ];
    columns.splice(0, 1)
    useEffect(() => {
        init(currentPage, pageSize);
        initType();
    }, []);// eslint-disable-line
    function initType() {
        doListType((data) => {
            console.log(data)
            setTypeList(data.resData);
        }, () => {
            setTypeList(null);
        })
    }

    function resetPage(currentPage, pageSize) {
        setCurrentPage(currentPage);
        setPageSize(pageSize);
    }

    function init(current, page) {
        doList({
            title: title,
            type: type,
            currentPage: current === undefined ? currentPage : current,
            pageSize: page === undefined ? pageSize : page
        }, (data) => {
            setData(data.resData.result);
            setLoading(false);
            setTotal(data.resData.total);
        }, () => {
            setData(null);
            setLoading(false);
        })
    }

    function add() {
        setOpenAddModel(true);
    }

    return (<div>
            <Form layout='inline'>
                <Form.Item
                    label="视频标题"
                    name="title">
                    <Input placeholder="请输入视频标题" onChange={(e) => setTitle(e.target.value)}/>
                </Form.Item>
                <Form.Item
                    label="视频类型"
                    name="type"
                    style={{"width": "200px"}}>
                    <Select onChange={(value, option) => {
                        setType(option.key)
                    }}>
                        {
                            typeList != null ?
                                typeList.map(item => {
                                    return (<Select.Option key={item.type}
                                                           value={item.type}>{item.typeText}</Select.Option>)
                                }) : null
                        }
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" onClick={() => {
                        setLoading(true);
                        init(Constant.CURRENT_PAGE, Constant.PAGE_SIZE);
                    }}>查询</Button>
                    <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                            onClick={() => {
                                setTitle(null);
                                setType(null);
                            }}>重置</Button>
                    <Button type="primary" style={{'marginLeft': '10px'}} onClick={() => add()}>新增</Button>
                </Form.Item>
            </Form>
            <CommonTable
                columns={columns}
                rowKey={columns => columns.id}
                data={data}
                width={1500}
                loading={loading}
                total={total}
                currentPage={currentPage}
                pageSize={pageSize}
                initData={(currentPage, pageSize) => init(currentPage, pageSize)}
                resetPage={(currentPage, pageSize) => resetPage(currentPage, pageSize)}/>
            <AddVideo open={openAddModel}
                      setOpenAddModel={setOpenAddModel}
                      init={init}
                      typeList={typeList}/>
        </div>
    )
}