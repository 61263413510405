import {ExclamationCircleOutlined} from '@ant-design/icons';
import React from "react";
import HttpUtil from "../../../../../util/httpUtil";

/**
 * 获取收款码列表
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doList = function (params, successFun, errorFun) {
    HttpUtil.doGet(
        "/shop/base/code/list",
        params,
        successFun, errorFun);
}
/**
 * 删除收款码
 * @param record
 * @param successFun
 * @param errorFun
 */
export const doDel = function (record, successFun, errorFun) {
    HttpUtil.doDelete(<ExclamationCircleOutlined/>, "删除收款码",
        "确认删除" + record.type + "收款码信息？",
        "/shop/base/code/delete/" + record.id, null, successFun, errorFun);
}
/**
 * 新增收款码
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doAdd = function (params, successFun, errorFun) {
    HttpUtil.doPost("/shop/base/code/add",
        params, successFun, errorFun);
}
