import React, {useState} from "react";
import {doAdd} from "./api";
import CommonModal from "../../../../../component/CommonModal";
import BrandForm from "./brandForm";

export default function AddBrand(props) {
    const addFormRef = React.createRef();
    const [addSubmitLoading, setAddSubmitLoading] = useState(false);


    function add() {
        if (!addSubmitLoading) {
            addFormRef.current.validateFields().then(values => {
                setAddSubmitLoading(true)
                doAdd({
                    name: values.name,
                    remarks: values.remarks
                }, (data) => {
                    setAddSubmitLoading(false);
                    clearInterval(addFormRef.current);
                    props.setOpenAddModel(false);
                    props.init();
                }, () => {
                    setAddSubmitLoading(false);
                })
            }).catch(e => {
                console.log(e);
            })
        }
    }

    function handleCancel() {
        props.setOpenAddModel(false);
    }

    return (
        <CommonModal
            title="新增品牌"
            open={props.open}
            onOk={() => add()}
            onCancel={() => handleCancel()}
            destroyOnClose={true}
            confirmLoading={addSubmitLoading}
            form={<BrandForm formRef={addFormRef}/>}/>
    )
}