import CommonModal from "../../../../component/CommonModal";
import React, {useState} from "react";
import {message} from "antd";
import RoleEmpowerForm from "./roleEmpowerForm";
import {addRolePermission} from "./api"

export default function RoleEmpowerModel(props) {
    const [addRolePermissionLoading, setAddRolePermissionLoading] = useState(false);
    const [rolePermission, setRolePermission] = useState(null);

    function selectedRolePermission(rolePermission) {
        setRolePermission(rolePermission);
    }

    function onOk() {
        let sysRolePermission = {};
        sysRolePermission.roleId = props.roleInfo.id;
        sysRolePermission.permissionIds = rolePermission;
        if (!addRolePermissionLoading) {
            setAddRolePermissionLoading(true);
            addRolePermission(sysRolePermission, (data) => {
                message.success(data.msg);
            })
            props.setOpenRoleEmpowerModel(false);
            setAddRolePermissionLoading(false);
        }
    }

    function handleCancel() {
        props.setOpenRoleEmpowerModel(false);
    }

    return (
        <CommonModal
            title="角色授权"
            width={500}
            open={props.open}
            onOk={() => onOk()}
            onCancel={() => handleCancel()}
            destroyOnClose={true}
            form={<RoleEmpowerForm roleInfo={props.roleInfo} selectedRolePermission={selectedRolePermission}/>}/>
    )
}