import {useEffect, useState} from "react";
import Constant from "../../../../util/constant";
import CommonTable from "../../../../component/CommonTable"
import {Form, Input, Space, Button, Select, message} from "antd"
import {listUserInfo, listUserType, listUserStatus, resetPassword, disable, enable} from "./api";
import AddUser from "./addUser";
import EditUser from "./editUser";

export default function User() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(Constant.CURRENT_PAGE);
    const [pageSize, setPageSize] = useState(Constant.PAGE_SIZE);

    const [username, setUsername] = useState(null);
    const [realName, setRealName] = useState(null);
    const [status, setStatus] = useState(null);
    const [type, setType] = useState(null);
    const [typeList, setTypeList] = useState(null);
    const [statusList, setStatusList] = useState(null);
    const [openAddModel, setOpenAddModel] = useState(false);
    const [openEditModel, setOpenEditModel] = useState(false);
    const [userInfo, setUserInfo] = useState(false);
    const columns: ColumnsType<DataType> = [
        {
            title: '账户',
            dataIndex: 'username',
            key: 'username',
            fixed: 'left',
        },
        {
            title: '姓名',
            dataIndex: 'realName',
            key: 'realName',
        },
        {
            title: '用户状态',
            dataIndex: 'statusText',
            key: 'statusText',
        },
        {
            title: '用户类别',
            dataIndex: 'typeText',
            key: 'typeText',
        },
        {
            title: '所属角色',
            dataIndex: 'roleName',
            key: 'roleName',
        },
        {
            title: '邮箱',
            dataIndex: 'email',
            key: 'email',
            ellipsis: true,
        },
        {
            title: '电话',
            dataIndex: 'tel',
            key: 'tel',
        },
        {
            title: '创建时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
        },
        {
            title: '创建人',
            dataIndex: 'creator',
            key: 'creator',
        },
        {
            title: '最近修改时间',
            dataIndex: 'gmtUpdate',
            key: 'gmt_update',
        },
        {
            title: '最近修改人',
            dataIndex: 'modifier',
            key: 'modifier',
        },
        {
            title: '备注',
            dataIndex: 'remarks',
            key: 'remarks',
            width: 300,
            ellipsis: true,
        }, {
            title: '操作',
            key: 'action',
            fixed: 'right',
            width: 145,
            render: (text, record) => (
                <Space size="small">
                    <a onClick={() => edit(record)}>编辑</a>
                    <a onClick={() => resetPassword(record, (data) => {
                        message.success(data.msg);
                    })} style={{
                        'color': '#eea236'
                    }}>重置密码</a>
                    <a onClick={() => {
                        if (record.status === 0) {
                            disable(record, () => init())
                        } else {
                            enable(record, () => init())
                        }
                    }} style={{
                        'color': '#eea236'
                    }}>{record.status === 0 ? "禁用" : "启用"}</a>
                </Space>
            )
        }
    ];
    useEffect(() => {
        init(currentPage, pageSize);
        initUserType();
        initUserStatus();
    }, []);// eslint-disable-line
    function init() {
        listUserInfo({
            username: username,
            realName: realName,
            status: status,
            type: type,
            currentPage: currentPage,
            pageSize: pageSize
        }, (data) => {
            setData(data.resData.result);
            setLoading(false);
            setTotal(data.resData.total);

        }, () => {
            setData(null);
            setLoading(false);
        })
    }

    function resetPage(currentPage, pageSize) {
        setCurrentPage(currentPage);
        setPageSize(pageSize);
    }

    function initUserType() {
        listUserType((data) => {
            setTypeList(data.resData);
        }, () => {
            setTypeList(null);
        })
    }

    function initUserStatus() {
        listUserStatus((data) => {
            setStatusList(data.resData);
        }, () => {
            setStatusList(null);
        })
    }

    function add() {
        setOpenAddModel(true);
    }

    function edit(record) {
        setUserInfo(record);
        setOpenEditModel(true);
    }

    return (
        <div>
            <Form layout='inline'>
                <Form.Item
                    label="账户"
                    name="username">
                    <Input placeholder="请输入账户" onChange={(e) => setUsername(e.target.value)}/>
                </Form.Item>
                <Form.Item
                    label="姓名"
                    name="realName">
                    <Input placeholder="请输入姓名" onChange={(e) => setRealName(e.target.value)}/>
                </Form.Item>
                <Form.Item
                    label="状态"
                    name="status"
                    style={{width: 180}}>
                    <Select onChange={(value, option) => {
                        setStatus(option.key)
                    }}>
                        {
                            statusList != null ?
                                statusList.map(item => {
                                    return (<Select.Option key={item.status}
                                                           value={item.status}>{item.statusText}</Select.Option>)
                                }) : null
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    label="类别"
                    name="type"
                    style={{width: 180}}>
                    <Select onChange={(value, option) => {
                        setType(option.key)
                    }}>
                        {
                            typeList != null ?
                                typeList.map(item => {
                                    return (<Select.Option key={item.type}
                                                           value={item.type}>{item.typeText}</Select.Option>)
                                }) : null
                        }
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" onClick={() => {
                        setLoading(true);
                        init(Constant.CURRENT_PAGE, Constant.PAGE_SIZE);
                    }}>查询</Button>
                    <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                            onClick={() => {
                                setUsername(null);
                                setRealName(null);
                                setStatus(null);
                                setType(null);
                            }}>重置</Button>
                    <Button type="primary" style={{'marginLeft': '10px'}} onClick={() => add()}>新增用户</Button>
                </Form.Item>
            </Form>
            <CommonTable
                columns={columns}
                rowKey={columns => columns.id}
                data={data}
                width={2500}
                loading={loading}
                total={total}
                currentPage={currentPage}
                pageSize={pageSize}
                initData={(currentPage, pageSize) => init(currentPage, pageSize)}
                resetPage={(currentPage, pageSize) => resetPage(currentPage, pageSize)}/>
            <AddUser open={openAddModel}
                     setOpenAddModel={setOpenAddModel}
                     init={init}
                     userTypeList={typeList}/>
            <EditUser open={openEditModel}
                      setOpenEditModel={setOpenEditModel}
                      userInfo={userInfo}
                      userTypeList={typeList}
                      init={init}/>

        </div>
    )
}