import {useEffect, useState} from "react";
import Constant from "../../../../util/constant";
import {Button, Form, Input, Select, Space, message} from "antd";
import CommonTable from "../../../../component/CommonTable";
import {listRoleUserInfo, listNotCurrentRoleUserInfo, addUserRole, deleteUserRole} from "./api"

export default function RoleUserInfoForm(props) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(Constant.CURRENT_PAGE);
    const [pageSize, setPageSize] = useState(Constant.PAGE_SIZE);

    const [addSubmitLoading, setAddSubmitLoading] = useState(false);
    const [username, setUsername] = useState(null);
    const [realName, setRealName] = useState(null);
    const [notCurrentRoleUserList, setNotCurrentRoleUserList] = useState(null);
    const [addCurrentRoleUserList, setAddCurrentRoleUserList] = useState(null);

    const columns = [
        {
            title: '账户',
            dataIndex: 'username',
            key: 'username',
            fixed: 'left',
        },
        {
            title: '姓名',
            dataIndex: 'realName',
            key: 'realName',
        },
        {
            title: '电话',
            dataIndex: 'tel',
            key: 'tel',
        },
        {
            title: '邮箱',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: '用户状态',
            dataIndex: 'statusText',
            key: 'statusText',
            width: 75,
        },
        {
            title: '授权人',
            dataIndex: 'authorizer',
            key: 'authorizer',
        },
        {
            title: '授权时间',
            dataIndex: 'authorizerTime',
            key: 'authorizerTime',
        },
        {
            title: '创建时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: 165,
        },
        {
            title: '创建人',
            dataIndex: 'creator',
            key: 'creator',
        },
        {
            title: '最近修改时间',
            dataIndex: 'gmtUpdate',
            key: 'gmt_update',
            width: 165,
        },
        {
            title: '最近修改人',
            dataIndex: 'modifier',
            key: 'modifier',
        },
        {
            title: '操作',
            key: 'action',
            fixed: 'right',
            width: 50,
            render: (text, record) => (
                <Space size="small">
                    <a style={{'color': '#d43f3a'}}
                       onClick={() => deleteUserRole({
                           username: record.username,
                           roleId: props.roleInfo.id,
                           id: record.id
                       }, () => {
                           init();
                           initNotCurrentRoleUser();
                       })}>删除</a>
                </Space>
            ),
        },
    ];

    useEffect(() => {
        init();
        initNotCurrentRoleUser();
    }, []);// eslint-disable-line

    function init() {
        listRoleUserInfo({
            username: username,
            realName: realName,
            roleId: props.roleInfo.id,
            currentPage: currentPage,
            pageSize: pageSize
        }, (data) => {
            setData(data.resData.result);
            setLoading(false);
            setTotal(data.resData.total);
        }, () => {
            setData(null);
            setLoading(false);
        });
    }

    function add() {
        if (!addSubmitLoading) {
            if (addCurrentRoleUserList != null && addCurrentRoleUserList.length > 0) {
                let systemUserList = [];
                for (let i = 0; i < addCurrentRoleUserList.length; i++) {
                    let systemUser = {};
                    systemUser.roleId = props.roleInfo.id;
                    systemUser.id = addCurrentRoleUserList[i];
                    systemUserList[i] = systemUser;
                }
                setAddSubmitLoading(true);
                addUserRole(systemUserList, () => {
                    setAddSubmitLoading(false);
                    init();
                    initNotCurrentRoleUser();
                }, () => {
                    setAddSubmitLoading(false);
                });
            } else {
                message.error("请选择账户进行授权！")
            }
        }
    }


    function resetPage(currentPage, pageSize) {
        setCurrentPage(currentPage);
        setPageSize(pageSize);
    }

    function initNotCurrentRoleUser() {
        listNotCurrentRoleUserInfo({
            roleId: props.roleInfo.id
        }, (data) => {
            setNotCurrentRoleUserList(data.resData.result);
        })
    }

    function onSelectChange(value, option) {
        if (option.length > 0) {
            let userIdList = [];
            for (let i = 0; i < option.length; i++) {
                userIdList[i] = option[i].key
            }
            setAddCurrentRoleUserList(userIdList);
        }

    }

    return (
        <div>
            <Form layout='inline'>
                <Form.Item
                    label="账户"
                    name="username"
                    style={{width: 200}}>
                    <Input placeholder="请输入账户" onChange={(e) => setUsername(e.target.value)}/>
                </Form.Item>
                <Form.Item
                    label="姓名"
                    name="realName"
                    style={{width: 200}}>
                    <Input placeholder="请输入姓名" onChange={(e) => setRealName(e.target.value)}/>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" onClick={() => {
                        setLoading(true);
                        init();
                    }}>查询</Button>
                    <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}} onClick={() => {
                        setUsername(null);
                        setRealName(null);
                    }}
                    >重置</Button>
                </Form.Item>
                <Form.Item
                    name="searchUsername"
                    style={{width: 200, marginLeft: 86}}
                    rules={[{required: true, message: '请选择用户'}]}>
                    <Select
                        placeholder="选择用户进行角色授权"
                        showSearch
                        mode="multiple"
                        allowClear
                        onChange={(value, option) => onSelectChange(value, option)}>
                        {
                            notCurrentRoleUserList != null ?
                                notCurrentRoleUserList.map(item => {
                                    return (<Select.Option key={item.id}
                                                           value={item.username}>{item.username}</Select.Option>)
                                }) : null
                        }
                    </Select>
                </Form.Item>
                <Form.Item style={{marginRight: 0}}>
                    <Button type="primary" onClick={() => add()}>添加</Button>
                </Form.Item>
            </Form>
            <CommonTable
                columns={columns}
                rowKey={columns => columns.id}
                data={data}
                width={2000}
                loading={loading}
                total={total}
                currentPage={currentPage}
                pageSize={pageSize}
                initData={init}
                resetPage={resetPage}/>
        </div>
    )
}