import HttpUtil from "../../../../util/httpUtil";
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {message} from "antd"

/**
 * 获取角色信息列表
 * @param params
 * @param successFun
 * @param errorFun
 */
export const listRoleInfo = function (params, successFun, errorFun) {
    HttpUtil.doGet(
        "/user/role/list",
        params,
        successFun, errorFun);
}
/**
 * 删除角色信息
 * @param record
 * @param successFun
 * @param errorFun
 */
export const deleteRoleInfo = function (record, successFun, errorFun) {
    if (record.roleUserNum > 0) {
        message.error("该角色下存在成员，不能删除！");
    } else {
        HttpUtil.doDelete(<ExclamationCircleOutlined/>, "删除角色",
            "确认删除" + record.roleName + "信息？",
            "/user/role/delete/" + record.id, null, successFun, errorFun);
    }
}
/**
 * 新增角色信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const addRoleInfo = function (params, successFun, errorFun) {
    HttpUtil.doPost("/user/role/add",
        params, successFun, errorFun);
}
/**
 * 更新角色信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const updateRoleInfo = function (params, successFun, errorFun) {
    HttpUtil.doPut("/user/role/update",
        params, successFun, errorFun);
}
/**
 * 获取角色用户信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const listRoleUserInfo = function (params, successFun, errorFun) {
    HttpUtil.doGet("/user/role/listRoleUser",
        params, successFun, errorFun);
}
/**
 * 获取不是当前角色的用户信息
 * @param params
 * @param successFun
 * @param errorFun
 */
export const listNotCurrentRoleUserInfo = function (params, successFun, errorFun) {
    HttpUtil.doGet("/user/role/listNotCurrentRoleUserInfo",
        params, successFun, errorFun);
}
/**
 * 新增用户角色
 * @param params
 * @param successFun
 * @param errorFun
 */
export const addUserRole = function (params, successFun, errorFun) {
    HttpUtil.doPost("/user/role/addUserRole",
        params, successFun, errorFun);
}
/**
 * 删除用户角色
 * @param params
 * @param successFun
 * @param errorFun
 */
export const deleteUserRole = function (params, successFun, errorFun) {
    HttpUtil.doDelete(<ExclamationCircleOutlined/>, "删除成员",
        "确认删除" + params.username + "信息，删除后用户将没有对应角色权限？",
        "/user/role/deleteUserRole/" + params.roleId + "/" + params.id, null, successFun, errorFun);
}
/**
 * 获取权限信息
 * @param successFun
 * @param errorFun
 */
export const listPermission = function (successFun, errorFun) {
    HttpUtil.doGet("/user/menu/listTreeMenu",
        null, successFun, errorFun);
}
/**
 * 获取角色权限
 * @param roleId
 * @param successFun
 * @param errorFun
 */
export const listRolePermission = function (roleId, successFun, errorFun) {
    HttpUtil.doGet("/user/role/permission/list/" + roleId,
        null, successFun, errorFun);
}
/**
 * 角色授权
 * @param params
 * @param successFun
 * @param errorFun
 */
export const addRolePermission = function (params, successFun, errorFun) {
    HttpUtil.doPost("/user/role/permission/add/",
        params, successFun, errorFun);
}