import React, {useEffect, useState} from "react";
import Constant from "../../../../util/constant";
import {Row, Button, Form, Input, Select, Space, Col, Image, message, Tooltip, DatePicker} from "antd";
import CommonTable from "../../../../component/CommonTable";
import {
    doList,
    doListStatus,
    doListType,
    doListWay,
    doDel,
    doCancel,
    doPrint,
    doPrintLabel,
    doExportOrderInfo
} from "./api";
import EditOrderInfo from "./editOrderInfo";
import EditSendOrderInfo from "./editSendOrderInfo";
import OrderProductInfo from "./orderProductInfo";
import Util from "../../../../util/params";
import locale from "antd/es/date-picker/locale/zh_CN";

const {RangePicker} = DatePicker;
export default function AllOrderInfo() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(Constant.CURRENT_PAGE);
    const [pageSize, setPageSize] = useState(Constant.PAGE_SIZE);

    const [orderInfo, setOrderInfo] = useState(null);
    const [title, setTitle] = useState(null);
    const [orderNumber, setOrderNumber] = useState(null);
    const [username, setUsername] = useState(null);
    const [realName, setRealName] = useState(null);
    const [tel, setTel] = useState(null);
    const [type, setType] = useState(null);
    const [typeList, setTypeList] = useState(null);
    const [way, setWay] = useState(null);
    const [wayList, setWayList] = useState(null);
    const [statusList, setStatusList] = useState(null);
    const [searchStatusList, setSearchStatusList] = useState(null);
    const [openEditModel, setOpenEditModel] = useState(false);
    const [openSendModel, setOpenSendModel] = useState(false);
    const [openOrderProductModel, setOpenOrderProductModel] = useState(false);
    const [orderProductInfo, setOrderProductInfo] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isExporting, setIsExporting] = useState(false);
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 50,
        },
        {
            title: '快递公司ID',
            dataIndex: 'logisticsCompanyId',
            key: 'logisticsCompanyId',
            width: 50,
        },
        {
            title: '订单编号',
            dataIndex: 'orderNumber',
            key: 'orderNumber',
            width: 150,
        },
        {
            title: '订单信息',
            dataIndex: 'orderInfo',
            key: 'orderInfo',
            width: 300,
            ellipsis: true,
            render: (text, record) => {
                return (
                    <Tooltip placement="topLeft"
                             title={"编号：" + record.orderNumber + "，标题：" + record.title + "，信息：" + record.orderCommodityInfo}>
                        <span>{"编号：" + record.orderNumber + "，标题：" + record.title + "，信息：" + record.orderCommodityInfo}</span>
                    </Tooltip>
                )
            }
        },
        {
            title: '收货信息',
            dataIndex: 'receivingInformation',
            key: 'receivingInformation',
            width: 200,
            ellipsis: true,
            render: receivingInformation => {
                return (
                    <Tooltip placement="topLeft" title={receivingInformation}>
                        <span>{receivingInformation}</span>
                    </Tooltip>
                )
            }
        },
        {
            title: '条码',
            dataIndex: 'orderNumberImage',
            key: 'orderNumberImage',
            render: orderNumberImage => {
                if (!Util.isEmpty(orderNumberImage)) {
                    return (
                        <Image
                            width={25}
                            height={20}
                            src={orderNumberImage}/>
                    )
                }
            }
        },
        {
            title: '订单状态',
            dataIndex: 'statusText',
            key: 'statusText',
        },
        {
            title: '快递面单',
            dataIndex: 'labelImage',
            key: 'labelImage',
            render: labelImage => {
                if (!Util.isEmpty(labelImage)) {
                    let images = labelImage.split(",");
                    return (
                        <Image.PreviewGroup
                            items={images}>
                            <Image
                                width={25}
                                height={20}
                                src={images[0]}/>
                        </Image.PreviewGroup>
                    )
                }
            }
        },
        {
            title: '订单总额',
            dataIndex: 'payAmount',
            key: 'payAmount',
        },
        {
            title: '未发货件数',
            dataIndex: 'waitSendQuantity',
            key: 'waitSendQuantity',
        },
        {
            title: '未发货金额',
            dataIndex: 'waitSendAmount',
            key: 'waitSendAmount',
        },
        {
            title: '快递公司',
            dataIndex: 'logisticsCompany',
            key: 'logisticsCompany',
        },
        {
            title: '快递编号',
            dataIndex: 'logisticsNumber',
            key: 'logisticsNumber',
            width: 200,
            ellipsis: true,
        },
        {
            title: '快递备注',
            dataIndex: 'logisticsRemarks',
            key: 'logisticsRemarks',
            width: 200,
            ellipsis: true,
        },
        {
            title: '订单类型',
            dataIndex: 'typeText',
            key: 'typeText',
        },
        {
            title: '支付方式',
            dataIndex: 'wayText',
            key: 'wayText',
        },
        {
            title: '备注',
            dataIndex: 'remarks',
            key: 'remarks',
        },
        {
            title: '创建时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: 170,
        },
        {
            title: '支付时间',
            dataIndex: 'gmtPay',
            key: 'gmtPay',
            width: 170,
        },
        {
            title: '发货时间',
            dataIndex: 'gmtDelivery',
            key: 'gmtDelivery',
            width: 170,
        },
        {
            title: '成交时间',
            dataIndex: 'gmtComplete',
            key: 'gmtComplete',
            width: 170,
        },
        {
            title: '取消时间',
            dataIndex: 'gmtCancel',
            key: 'gmtCancel',
            width: 170,
        },
        {
            title: '退货时间',
            dataIndex: 'gmtBack',
            key: 'gmtBack',
            width: 170,
        },
        {
            title: '退款时间',
            dataIndex: 'gmtRefund',
            key: 'gmtRefund',
            width: 170,
        },
        {
            title: '操作',
            key: 'action',
            fixed: 'right',
            width: 400,
            render: (text, record) => (
                <Space size="small" style={{"float": "right"}}>
                    <a onClick={() => orderProduct(record)}>商品详情</a>
                    {
                        record.status === 2 ? (<a style={{
                            'color': '#eea236'
                        }} onClick={() => updateSend(record)}>修改物流信息</a>) : null
                    }
                    {
                        record.status === 0 ? (<div>
                            <a style={{
                                'color': '#eea236'
                            }} onClick={() => edit(record)}>修改金额</a>
                            <a style={{
                                'paddingLeft': '10px',
                                'color': '#eea236'
                            }} onClick={() => doCancel(record, () => {
                                init()
                            })}>取消</a>
                        </div>) : null
                    }
                    {
                        (record.status === 0 || record.status === 4) ? (<a style={{
                            'color': '#d43f3a'
                        }} onClick={() => doDel(record, () => {
                            init()
                        })}>删除</a>) : null
                    }
                    <a onClick={() => print(record)}>打印条码</a>
                    {
                        record.labelImage !== null && record.labelImage !== '' ? (
                            <a onClick={() => printLabel(record)}>打印面单</a>
                        ) : null
                    }
                    <a onClick={() => copyAddress(record)}>复制地址</a>
                </Space>
            ),
        },
    ];
    columns.splice(0, 2)
    useEffect(() => {
        init(currentPage, pageSize);
        initStatus();
        initType();
        initWay();
    }, []);// eslint-disable-line
    function init(current, page) {
        doList({
            title: title,
            orderNumber: orderNumber,
            username: username,
            realName: realName,
            tel: tel,
            type: type,
            way: way,
            statusList: searchStatusList == null ? null : searchStatusList.join(','),
            startDate: startDate,
            endDate: endDate,
            currentPage: current === undefined ? currentPage : current,
            pageSize: page === undefined ? pageSize : page
        }, (data) => {
            setData(data.resData.result);
            setLoading(false);
            setTotal(data.resData.total);
        }, () => {
            setData(null);
            setLoading(false);
        })
    }

    function initStatus() {
        doListStatus((data) => {
            setStatusList(data.resData);
        }, () => {
            setStatusList(null);
        })
    }

    function initType() {
        doListType((data) => {
            setTypeList(data.resData);
        }, () => {
            setTypeList(null);
        })
    }

    function initWay() {
        doListWay((data) => {
            setWayList(data.resData);
        }, () => {
            setWayList(null);
        })
    }

    function edit(record) {
        setOrderInfo(record);
        setOpenEditModel(true);
    }

    function resetPage(currentPage, pageSize) {
        setCurrentPage(currentPage);
        setPageSize(pageSize);
    }

    function updateSend(record) {
        setOrderInfo(record);
        setOpenSendModel(true);
    }

    function orderProduct(record) {
        let orderProductInfos = [];
        if (record.commodityVOList.length > 0) {
            for (let i = 0; i < record.commodityVOList.length; i++) {
                let orderProductInfo = {};
                orderProductInfo.waitSendQuantity = record.commodityVOList[i].productVO.waitSendQuantity;
                orderProductInfo.purchaseQuantity = record.commodityVOList[i].purchaseQuantity;
                orderProductInfo.number = record.commodityVOList[i].productVO.number;
                orderProductInfo.name = record.commodityVOList[i].productVO.name;
                orderProductInfo.image = record.commodityVOList[i].productImageUrl;
                orderProductInfo.color = record.commodityVOList[i].productVO.color;
                orderProductInfo.spec = record.commodityVOList[i].productVO.spec;
                orderProductInfo.size = record.commodityVOList[i].productVO.size;
                orderProductInfo.orderNumber = record.orderNumber;
                orderProductInfo.title = record.title;
                orderProductInfos.push(orderProductInfo);
            }

        }
        setOrderProductInfo(orderProductInfos);
        setOpenOrderProductModel(true);
    }

    function print(record) {
        // 调用打印机打印
        let ids = [];
        ids.push(record.id);
        let barcodeDTO = {};
        barcodeDTO.ids = ids;
        doPrint(barcodeDTO, (res) => {
            if (res.code === 200) {
                message.info(res.msg)
            } else {
                message.error(res.msg)
            }
        })
    }

    function printLabel(record) {
        // 调用打印机打印
        let ids = [];
        ids.push(record.id);
        let barcodeDTO = {};
        barcodeDTO.ids = ids;
        doPrintLabel(barcodeDTO, (res) => {
            if (res.code === 200) {
                message.info(res.msg)
            } else {
                message.error(res.msg)
            }
        })
    }

    async function copyAddress(record) {
        try {
            await navigator.clipboard.writeText(record.realName + "\n" + record.tel + "\n" + record.address);
            message.success("复制成功");
        } catch (error) {
            console.error('Failed to copy text: ', error);
        }
    }

    return (
        <div>
            <Form>
                <Row gutter={[16, 0]}>
                    <Col span={6}>
                        <Form.Item
                            label="商品标题"
                            name="title">
                            <Input placeholder="请输入商品标题" onChange={(e) => setTitle(e.target.value)}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="订单编号"
                            name="orderNumber">
                            <Input placeholder="请输入订单编号" onChange={(e) => setOrderNumber(e.target.value)}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="下单账户"
                            name="username">
                            <Input placeholder="请输入下单账户" onChange={(e) => setUsername(e.target.value)}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="收货人"
                            name="realName">
                            <Input placeholder="请输入收货人" onChange={(e) => setRealName(e.target.value)}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 0]}>
                    <Col span={6}>
                        <Form.Item
                            label="联系电话"
                            name="tel">
                            <Input placeholder="请输入联系电话" onChange={(e) => setTel(e.target.value)}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="支付方式"
                            name="way">
                            <Select onChange={(value, option) => {
                                setWay(option.key)
                            }}>
                                {
                                    wayList != null ?
                                        wayList.map(item => {
                                            return (<Select.Option key={item.way}
                                                                   value={item.way}>{item.wayText}</Select.Option>)
                                        }) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="订单类型"
                            name="type">
                            <Select onChange={(value, option) => {
                                setType(option.key)
                            }}>
                                {
                                    typeList != null ?
                                        typeList.map(item => {
                                            return (<Select.Option key={item.type}
                                                                   value={item.type}>{item.typeText}</Select.Option>)
                                        }) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="订单状态"
                            name="status">
                            <Select
                                showSearch
                                mode="multiple"
                                allowClear
                                onChange={(value, option) => {
                                    console.log(value)
                                    setSearchStatusList(value)
                                }}>
                                {
                                    statusList != null ?
                                        statusList.map(item => {
                                            return (<Select.Option key={item.status}
                                                                   value={item.status}>{item.statusText}</Select.Option>)
                                        }) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 0]}>
                    <Col span={12}>
                        <Form.Item
                            label="订单日期"
                            name="date">
                            <RangePicker
                                format="YYYY-MM-DD HH:mm:ss"
                                showTime locale={locale} style={{"width": "508px"}} onChange={(date, dateString) => {
                                setStartDate(dateString[0]);
                                setEndDate(dateString[1]);
                            }}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item>
                            <Button type="primary" onClick={() => {
                                setLoading(true);
                                init(Constant.CURRENT_PAGE, Constant.PAGE_SIZE);
                            }}>查询</Button>
                            <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                                    onClick={() => {
                                        setTitle(null);
                                        setOrderNumber(null);
                                        setUsername(null);
                                        setRealName(null);
                                        setTel(null);
                                        setWay(null);
                                        setType(null);
                                        setSearchStatusList(null);
                                    }}>重置</Button>
                            <Button type="primary" style={{'marginLeft': '10px'}} onClick={() => {
                                setIsExporting(true);
                                doExportOrderInfo({
                                    title: title,
                                    orderNumber: orderNumber,
                                    username: username,
                                    realName: realName,
                                    tel: tel,
                                    type: type,
                                    way: way,
                                    statusList: searchStatusList,
                                    startDate: startDate,
                                    endDate: endDate,
                                }, () => {
                                    message.success("导出成功！");
                                    setIsExporting(false);
                                }, () => {
                                    message.error("导出失败！")
                                    setIsExporting(false);
                                })
                            }} disabled={isExporting}>导出订单</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <CommonTable
                columns={columns}
                rowKey={columns => columns.id}
                data={data}
                width={3500}
                loading={loading}
                total={total}
                currentPage={currentPage}
                pageSize={pageSize}
                initData={(currentPage, pageSize) => init(currentPage, pageSize)}
                resetPage={(currentPage, pageSize) => resetPage(currentPage, pageSize)}/>
            <EditOrderInfo open={openEditModel} setOpenEditModel={setOpenEditModel}
                           orderInfo={orderInfo}
                           init={init}/>
            <EditSendOrderInfo open={openSendModel} setOpenSendModel={setOpenSendModel}
                               orderInfo={orderInfo}
                               init={init}/>
            <OrderProductInfo open={openOrderProductModel} setOpenOrderProductModel={setOpenOrderProductModel}
                              data={orderProductInfo}
                              init={init}/>
        </div>
    )
}