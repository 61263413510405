import React, {useEffect, useState} from "react";
import Constant from "../../../../../util/constant";
import {Button, Col, Form, Image, Input, Row, Select, Space} from "antd";
import {disable, doDel, doList, enable} from "./api";
import {doTreeList} from "../../information/category/api"
import {doList as doSupplierList} from "../../information/supplier/api"
import {doList as doBrandList} from "../../information/brand/api"
import CommonTable from "../../../../../component/CommonTable";
import AddProduct from "./addProduct";
import EditProduct from "./editProduct";
import ViewProduct from "./viewProduct";
import {doInitStatus} from "../../../../../common/api";
import Util from "../../../../../util/params";

export default function ProductInfo() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(Constant.CURRENT_PAGE);
    const [pageSize, setPageSize] = useState(Constant.PAGE_SIZE);

    const [number, setNumber] = useState(null);
    const [name, setName] = useState(null);
    const [category, setCategory] = useState(null);
    const [categoryList, setCategoryList] = useState(null);
    const [supplier, setSupplier] = useState(null);
    const [supplierList, setSupplierList] = useState(null);
    const [brand, setBrand] = useState(null);
    const [brandList, setBrandList] = useState(null);
    const [status, setStatus] = useState(null);
    const [color, setColor] = useState(null);
    const [size, setSize] = useState(null);
    const [spec, setSpec] = useState(null);
    const [productInfo, setProductInfo] = useState(null);
    const [openAddModel, setOpenAddModel] = useState(false);
    const [openViewModel, setOpenViewModel] = useState(false);
    const [openEditModel, setOpenEditModel] = useState(false);
    const [statusList, setStatusList] = useState(null);

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 50,
        },
        {
            title: 'SKU码',
            dataIndex: 'number',
            key: 'number',
        },
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '大小',
            dataIndex: 'size',
            key: 'size',
        },
        {
            title: '颜色',
            dataIndex: 'color',
            key: 'color',
        },
        {
            title: '规格',
            dataIndex: 'spec',
            key: 'spec',
        },
        {
            title: '图片',
            dataIndex: 'image',
            key: 'image',
            render: image => {
                if (!Util.isEmpty(image)) {
                    return (
                        <Image
                            width={25}
                            height={20}
                            src={image}/>
                    )
                }
            }
        },
        {
            title: '类别',
            dataIndex: 'category',
            key: 'category',
        },
        {
            title: '供应商',
            dataIndex: 'supplier',
            key: 'supplier',
        },
        {
            title: '品牌',
            dataIndex: 'brand',
            key: 'brand',
        },
        {
            title: '重量(kg)',
            dataIndex: 'weight',
            key: 'weight',
        },
        {
            title: '单位',
            dataIndex: 'unit',
            key: 'unit',
        },
        {
            title: '采购价/元',
            dataIndex: 'procurePrice',
            key: 'procurePrice',
        },
        {
            title: '销售价/元',
            dataIndex: 'salePrice',
            key: 'salePrice',
        },
        {
            title: '零售价/元',
            dataIndex: 'retailPrice',
            key: 'retailPrice',
        },
        {
            title: '状态',
            dataIndex: 'statusText',
            key: 'statusText',
        },
        {
            title: '描述',
            dataIndex: 'remarks',
            key: 'remarks',
        },
        {
            title: '创建时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: 170,
        },
        {
            title: '创建人',
            dataIndex: 'creator',
            key: 'creator',
        },
        {
            title: '最近修改时间',
            dataIndex: 'gmtUpdate',
            key: 'gmtUpdate',
            width: 170,
        },
        {
            title: '最近修改人',
            dataIndex: 'modifier',
            key: 'modifier',
        },
        {
            title: '操作',
            key: 'action',
            fixed: 'right',
            width: 155,
            render: (text, record) => (
                <Space size="small">
                    <a onClick={() => view(record)}>查看</a>
                    <a onClick={() => edit(record)}>编辑</a>
                    <a onClick={() => {
                        if (record.status === 0) {
                            disable(record, () => init())
                        } else {
                            enable(record, () => init())
                        }
                    }} style={{
                        'color': '#eea236'
                    }}>{record.status === 0 ? "禁用" : "启用"}</a>
                    <a style={{'color': '#d43f3a'}} onClick={() => doDel(record, () => {
                        init()
                    })}>删除</a>
                </Space>
            ),
        },
    ];
    columns.splice(0, 1)
    useEffect(() => {
        init(currentPage, pageSize);
        initStatus();
        initCategory();
        initSupplier();
        initBrand();
    }, []);// eslint-disable-line
    function init(current, page) {
        doList({
            number: number,
            name: name,
            status: status,
            color: color,
            size: size,
            spec: spec,
            category: category,
            supplier: supplier,
            brand: brand,
            currentPage: current === undefined ? currentPage : current,
            pageSize: page === undefined ? pageSize : page
        }, (data) => {
            setData(data.resData.result);
            setLoading(false);
            setTotal(data.resData.total);
        }, () => {
            setData(null);
            setLoading(false);
        })
    }

    function initStatus() {
        doInitStatus((data) => {
            setStatusList(data.resData);
        }, () => {
            setStatusList(null);
        })
    }

    function add() {
        setOpenAddModel(true);
    }

    function view(record) {
        setProductInfo(record);
        setOpenViewModel(true);
    }

    function edit(record) {
        setProductInfo(record);
        setOpenEditModel(true);
    }

    function resetPage(currentPage, pageSize) {
        setCurrentPage(currentPage);
        setPageSize(pageSize);
    }

    function initCategory() {
        doTreeList({status: 0}, (data) => {
            setCategoryList(data.resData);
        }, () => {
            setCategoryList(null);
        });
    }

    function initSupplier() {
        doSupplierList({status: 0}, (data) => {
            setSupplierList(data.resData.result);
        }, () => {
            setSupplierList(null);
        });
    }

    function initBrand() {
        doBrandList({status: 0}, (data) => {
            setBrandList(data.resData.result);
        }, () => {
            setBrandList(null);
        });
    }

    return (
        <div>
            <Form>
                <Row gutter={[16, 0]}>
                    <Col span={6}>
                        <Form.Item
                            label="SKU码"
                            name="number">
                            <Input placeholder="请输入SKU码" onChange={(e) => setNumber(e.target.value)}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="名称"
                            name="name">
                            <Input placeholder="请输入产品名称" onChange={(e) => setName(e.target.value)}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="类别"
                            name="category">
                            <Input placeholder="请输入产品类别" onChange={(e) => setCategory(e.target.value)}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="品牌"
                            name="brand">
                            <Input placeholder="请输入产品品牌" onChange={(e) => setBrand(e.target.value)}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 0]}>
                    <Col span={6}>
                        <Form.Item
                            label="颜色"
                            name="color">
                            <Input placeholder="请输入产品颜色" onChange={(e) => setColor(e.target.value)}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="大小"
                            name="size">
                            <Input placeholder="请输入产品大小" onChange={(e) => setSize(e.target.value)}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="规格"
                            name="spec">
                            <Input placeholder="请输入产品规格" onChange={(e) => setSpec(e.target.value)}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="状态"
                            name="status">
                            <Select onChange={(value, option) => {
                                setStatus(option.key)
                            }}>
                                {
                                    statusList != null ?
                                        statusList.map(item => {
                                            return (<Select.Option key={item.status}
                                                                   value={item.status}>{item.statusText}</Select.Option>)
                                        }) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 0]}>
                    <Col span={6}>
                        <Form.Item>
                            <Button type="primary" onClick={() => {
                                setLoading(true);
                                init(Constant.CURRENT_PAGE, Constant.PAGE_SIZE);
                            }}>查询</Button>
                            <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                                    onClick={() => {
                                        setNumber(null);
                                        setName(null);
                                        setStatus(null);
                                        setCategory(null);
                                        setBrand(null);
                                        setSupplier(null);
                                        setColor(null);
                                        setSize(null);
                                        setSpec(null);
                                    }}>重置</Button>
                            <Button type="primary" style={{'marginLeft': '10px'}} onClick={() => add()}>新增SKU</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <CommonTable
                columns={columns}
                rowKey={columns => columns.id}
                data={data}
                width={3500}
                loading={loading}
                total={total}
                currentPage={currentPage}
                pageSize={pageSize}
                initData={(currentPage, pageSize) => init(currentPage, pageSize)}
                resetPage={(currentPage, pageSize) => resetPage(currentPage, pageSize)}/>
            <AddProduct open={openAddModel}
                        setOpenAddModel={setOpenAddModel}
                        categoryList={categoryList}
                        supplierList={supplierList}
                        brandList={brandList}
                        init={init}/>
            <ViewProduct open={openViewModel}
                         setOpenViewModel={setOpenViewModel}
                         productInfo={productInfo}/>
            <EditProduct open={openEditModel}
                         setOpenEditModel={setOpenEditModel}
                         categoryList={categoryList}
                         supplierList={supplierList}
                         brandList={brandList}
                         productInfo={productInfo}
                         init={init}/>
        </div>
    )
}