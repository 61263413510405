import React from "react";
import CommonModal from "../../../../../component/CommonModal";
import SoldForm from "./soldForm";
import {message} from "antd";
import {doUpdateSaleProduct} from "./api";

export default function EditSold(props) {
    const baseFormRef = React.createRef();
    const specFormRef = React.createRef();
    const detailFormRef = React.createRef();
    const moreFormRef = React.createRef();
    if (props.saleCommodity != null && props.open) {
        setTimeout(() => {
            baseFormRef.current.setFieldsValue(props.saleCommodity);
            baseFormRef.current.setFieldsValue({
                shopCategoryIds: props.saleCommodity.shopCategoryIds === null ? [] : props.saleCommodity.shopCategoryIds.split(','),
            });
            specFormRef.current.setFieldsValue(props.saleCommodity);
            moreFormRef.current.setFieldsValue({
                type: props.saleCommodity.type,
                oneCount: props.saleCommodity.oneCount,
                sellingPoint: props.saleCommodity.sellingPoint,
                label: (props.saleCommodity.labelList === [] || props.saleCommodity.labelList === null) ? [] : props.saleCommodity.labelList,
                commitments: (props.saleCommodity.commitmentList === [] || props.saleCommodity.commitmentList === null) ? [] : props.saleCommodity.commitmentList,
            });
        }, 100);
    }

    function update() {
        baseFormRef.current.validateFields().then(baseValues => {
            specFormRef.current.validateFields().then(specValues => {
                detailFormRef.current.validateFields().then(detailValues => {
                    moreFormRef.current.validateFields().then(moreValues => {
                        let commoditySkuDTOList = [];
                        if (specValues.productNumberList !== undefined) {
                            for (let i = 0; i < specValues.productNumberList.length; i++) {
                                let commoditySku = {};
                                commoditySku.skuNumber = specValues.productNumberList[i];
                                commoditySku.salePrice = specValues.salePriceMap.get(specValues.productNumberList[i]);
                                commoditySku.procurePrice = specValues.procurePriceMap.get(specValues.productNumberList[i]);
                                commoditySku.underlinedPrice = specValues.underlinedPriceMap.get(specValues.productNumberList[i]);
                                commoditySkuDTOList.push(commoditySku);
                            }
                        }
                        let shopCategoryIds = [];
                        if (baseValues.shopCategoryIds !== undefined && baseValues.shopCategoryIds.length > 0) {
                            if (baseValues.shopCategoryIds[0]["value"] === undefined) {
                                shopCategoryIds = baseValues.shopCategoryIds
                            } else {
                                for (let i = 0; i < baseValues.shopCategoryIds.length; i++) {
                                    shopCategoryIds.push(baseValues.shopCategoryIds[i]["value"]);
                                }
                            }
                        }
                        let imagesUrl = []
                        if (baseValues.imagesUrl !== undefined) {
                            if (Array.isArray(baseValues.imagesUrl)) {
                                imagesUrl = baseValues.imagesUrl;
                            } else {
                                imagesUrl = baseValues.imagesUrl.split(",")
                            }
                        }
                        let videoUrl = []
                        if (baseValues.videoUrl !== undefined && baseValues.videoUrl !== null) {
                            if (Array.isArray(baseValues.videoUrl)) {
                                videoUrl = baseValues.videoUrl;
                            } else {
                                videoUrl = baseValues.videoUrl.split(",")
                            }
                        }
                        doUpdateSaleProduct({
                            id: props.saleCommodity.id,
                            number: props.saleCommodity.number,
                            title: baseValues.title,
                            shopCategoryIds: shopCategoryIds !== [] ? shopCategoryIds : null,
                            freightTemplateId: baseValues.freightTemplateId !== undefined ? baseValues.freightTemplateId : null,
                            status: baseValues.status !== undefined ? baseValues.status : null,
                            orderNumber: baseValues.orderNumber !== undefined ? baseValues.orderNumber : null,
                            imagesUrl: imagesUrl !== [] ? imagesUrl : null,
                            videoUrl: videoUrl !== [] ? videoUrl : null,
                            productNumberList: specValues.productNumberList !== undefined ? specValues.productNumberList : null,
                            commoditySkuDTOList: commoditySkuDTOList,
                            details: detailValues.details !== undefined ? detailValues.details : null,
                            type: moreValues.type !== undefined ? moreValues.type : null,
                            oneCount: moreValues.oneCount !== undefined ? moreValues.oneCount : null,
                            sellingPoint: moreValues.sellingPoint !== undefined ? moreValues.sellingPoint : null,
                            commitments: moreValues.commitments !== undefined ? moreValues.commitments : null,
                            label: moreValues.label !== undefined ? moreValues.label : null,
                        }, (data) => {
                            props.setOpenEditModel(false);
                            props.init();
                        }, () => {
                        })
                    }).catch(e => {
                        console.log(e);
                        message.error("必填项未填！")
                    })
                }).catch(e => {
                    console.log(e);
                    message.error("必填项未填！")
                })
            }).catch(e => {
                console.log(e);
                message.error("必填项未填！")
            })
        }).catch(e => {
            console.log(e);
            message.error("必填项未填！")
        })
    }

    function handleCancel() {
        props.setOpenEditModel(false);
    }

    return (
        <CommonModal
            title="编辑上架商品"
            open={props.open}
            onOk={() => update()}
            onCancel={() => handleCancel()}
            destroyOnClose={true}
            width={1050}
            form={<SoldForm baseFormRef={baseFormRef}
                            specFormRef={specFormRef}
                            detailFormRef={detailFormRef}
                            moreFormRef={moreFormRef}
                            categoryList={props.categoryList}
                            shopCategoryIds={(props.saleCommodity === null || props.saleCommodity === undefined || props.saleCommodity.shopCategoryIds === null
                                || props.saleCommodity.shopCategoryIds === undefined) ? [] : props.saleCommodity.shopCategoryIds.split(',')}
                            skuInfo={(props.saleCommodity === null || props.saleCommodity === undefined
                                || props.saleCommodity.skuInfo === undefined || props.saleCommodity.skuInfo === null) ? null : props.saleCommodity.skuInfo}
                            details={(props.saleCommodity === null || props.saleCommodity === undefined
                                || props.saleCommodity.details === undefined || props.saleCommodity.details === null) ? null : props.saleCommodity.details}
            />}/>
    )
}