import {Form, Input} from "antd";

const {TextArea} = Input;
export default function RoleForm(props) {
    const layout = {
        labelCol: {span: 4},
        wrapperCol: {span: 20},
    };
    return (
        <div>
            <Form
                ref={props.formRef} {...layout}>
                <Form.Item
                    label="角色ID"
                    name="id"
                    hidden="true">
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="角色名称"
                    name="roleName"
                    rules={[{required: true, message: '请输入角色名称'}]}>
                    <Input placeholder="请输入角色名称"/>
                </Form.Item>
                <Form.Item
                    label="角色备注"
                    name="remarks">
                    <TextArea/>
                </Form.Item>
            </Form>
        </div>
    )
}