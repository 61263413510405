import type {TabsProps} from "antd";
import {Tabs} from "antd";
import React from "react";
import AllOrderInfo from "./all/allOrderInfo";
import WaitSendOrderInfo from "./delivery/waitSendOrderInfo";
import AfterSalesOrderInfo from "./after/afterSalesOrderInfo";
import OfflineOrderInfo from "./offline/offlineOrderInfo";
import MergeOrderInfo from "./merge/mergeOrderInfo";

export default function OrderTab() {
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: '全部订单',
            children: <AllOrderInfo/>,
        },
        {
            key: '2',
            label: '线下支付订单',
            children: <OfflineOrderInfo/>,
        },
        {
            key: '3',
            label: '待发货订单',
            children: <WaitSendOrderInfo/>,
        },
        {
            key: '4',
            label: '售后订单',
            children: <AfterSalesOrderInfo/>,
        },
        {
            key: '5',
            label: '合并订单',
            children: <MergeOrderInfo/>,
        }
    ];

    return (
        <Tabs defaultActiveKey="1" items={items}/>
    )
}