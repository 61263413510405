import type {TabsProps} from "antd";
import {Tabs} from "antd";
import React from "react";
import Company from "./company/company";
import Freight from "./freight/freight";

export default function ShopLogisticsTab() {
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: '物流公司',
            children: <Company/>,
        },
        {
            key: '2',
            label: '运费模版',
            children: <Freight/>,
        }
    ];

    return (
        <Tabs defaultActiveKey="1" items={items}/>
    )
}