import {ExclamationCircleOutlined} from '@ant-design/icons';
import React from "react";
import HttpUtil from "../../../../../util/httpUtil";

/**
 * 获取协议列表
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doList = function (params, successFun, errorFun) {
    HttpUtil.doGet(
        "/shop/base/agreement/list",
        params,
        successFun, errorFun);
}
/**
 * 获取协议类别
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doTypeList = function (params, successFun, errorFun) {
    HttpUtil.doGet(
        "/shop/base/agreement/listType",
        params,
        successFun, errorFun);
}
/**
 * 删除协议
 * @param record
 * @param successFun
 * @param errorFun
 */
export const doDel = function (record, successFun, errorFun) {
    HttpUtil.doDelete(<ExclamationCircleOutlined/>, "删除协议",
        "确认删除" + record.title + "信息？",
        "/shop/base/agreement/delete/" + record.id, null, successFun, errorFun);
}
/**
 * 新增协议
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doAdd = function (params, successFun, errorFun) {
    HttpUtil.doPost("/shop/base/agreement/add",
        params, successFun, errorFun);
}
/**
 * 更新协议
 * @param params
 * @param successFun
 * @param errorFun
 */
export const doUpdate = function (params, successFun, errorFun) {
    HttpUtil.doPut("/shop/base/agreement/update",
        params, successFun, errorFun);
}
