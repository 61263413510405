import React, {useEffect, useState} from "react";
import Constant from "../../../../../util/constant";
import {Button, Form, Input, Space} from "antd";
import {doDel, doList} from "./api";
import CommonTable from "../../../../../component/CommonTable";
import AddAgreement from "./addAgreement";
import EditAgreement from "./editAgreement";

export default function Agreement() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(Constant.CURRENT_PAGE);
    const [pageSize, setPageSize] = useState(Constant.PAGE_SIZE);

    const [title, setTitle] = useState(null);
    const [agreement, setAgreement] = useState(null);
    const [openAddModel, setOpenAddModel] = useState(false);
    const [openEditModel, setOpenEditModel] = useState(false);
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 50,
        },
        {
            title: '标题',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: '类别',
            dataIndex: 'typeText',
            key: 'typeText',
        },
        {
            title: '状态',
            dataIndex: 'statusText',
            key: 'statusText',
        },
        {
            title: '内容',
            dataIndex: 'content',
            key: 'content',
            width: 300,
            ellipsis: true,
        },
        {
            title: '描述',
            dataIndex: 'remarks',
            key: 'remarks',
        },
        {
            title: '创建时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: 170,
        },
        {
            title: '创建人',
            dataIndex: 'creator',
            key: 'creator',
        },
        {
            title: '操作',
            key: 'action',
            fixed: 'right',
            width: 80,
            render: (text, record) => (
                <Space size="small">
                    <a onClick={() => edit(record)}>编辑</a>
                    <a style={{'color': '#d43f3a'}} onClick={() => doDel(record, () => {
                        init()
                    })}>删除</a>
                </Space>
            ),
        },
    ];
    columns.splice(0, 1)
    useEffect(() => {
        init(currentPage, pageSize);
    }, []);// eslint-disable-line
    function init(current, page) {
        doList({
            title: title,
            currentPage: current === undefined ? currentPage : current,
            pageSize: page === undefined ? pageSize : page
        }, (data) => {
            setData(data.resData.result);
            setLoading(false);
            setTotal(data.resData.total);
        }, () => {
            setData(null);
            setLoading(false);
        })
    }

    function add() {
        setOpenAddModel(true);
    }

    function resetPage(currentPage, pageSize) {
        setCurrentPage(currentPage);
        setPageSize(pageSize);
    }

    function edit(record) {
        setAgreement(record);
        setOpenEditModel(true);
    }

    return (
        <div>
            <Form layout='inline'>
                <Form.Item
                    label="标题"
                    name="title">
                    <Input placeholder="请输入标题" onChange={(e) => setTitle(e.target.value)}/>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" onClick={() => {
                        setLoading(true);
                        init(Constant.CURRENT_PAGE, Constant.PAGE_SIZE);
                    }}>查询</Button>
                    <Button type="primary" htmlType="reset" style={{'marginLeft': '10px'}}
                            onClick={() => {
                                setTitle(null);
                            }}>重置</Button>
                    <Button type="primary" style={{'marginLeft': '10px'}} onClick={() => add()}>新增协议</Button>
                </Form.Item>
            </Form>
            <CommonTable
                columns={columns}
                rowKey={columns => columns.id}
                data={data}
                loading={loading}
                total={total}
                currentPage={currentPage}
                pageSize={pageSize}
                initData={(currentPage, pageSize) => init(currentPage, pageSize)}
                resetPage={(currentPage, pageSize) => resetPage(currentPage, pageSize)}/>
            <AddAgreement open={openAddModel} setOpenAddModel={setOpenAddModel}
                          init={init}/>
            <EditAgreement open={openEditModel} setOpenEditModel={setOpenEditModel}
                           init={init} agreement={agreement}/>
        </div>
    )
}