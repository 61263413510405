import {Form, Input,TreeSelect} from "antd";
import React from "react";

const {TextArea} = Input;

export default function CategoryForm(props) {
    const layout = {
        labelCol: {span: 4},
        wrapperCol: {span: 20},
    };
    return (
        <div>
            <Form
                ref={props.formRef} {...layout} initialValues={props.category}>
                <Form.Item
                    label="类别ID"
                    name="id"
                    hidden="true">
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="编码"
                    name="number">
                    <Input disabled placeholder="系统自动生成"/>
                </Form.Item>
                <Form.Item
                    label="父类别"
                    name="parentName">
                    <TreeSelect
                        showSearch
                        treeNodeFilterProp="title"
                        onChange={(value) => {
                            if (value === undefined || value === null) {
                                props.setParentId(0);
                            } else {
                                props.setParentId(value);
                            }
                        }}
                        style={{width: '100%'}}
                        dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                        allowClear
                        treeData={props.categoryList}
                    />
                </Form.Item>
                <Form.Item
                    label="名称"
                    name="name"
                    rules={[{required: true, message: '请输入类别名称'}]}>
                    <Input placeholder="请输入类别名称"/>
                </Form.Item>
                <Form.Item
                    label="描述"
                    name="remarks">
                    <TextArea/>
                </Form.Item>
            </Form>
        </div>
    )
}